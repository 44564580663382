import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Tooltip, Divider, Badge, Grid, TextField, Typography} from '@material-ui/core'
import IconeFeedback from '@material-ui/icons/Feedback'
import CloseIcon from '@material-ui/icons/Close'
import Api from '../../providers/Api/Api';
import Swal from 'sweetalert2';
import GeradorLog from '../../providers/GeradorLog';

export default function DialogFeedback(props) {
  
  const [open, setOpen] = React.useState(false)
  const [emailContato, setEmailContato]= React.useState('') 
  const [mensagem, setMensagem]= React.useState('') 

  useEffect(()=>{
    if(open){
      GeradorLog(props.dadosEmpresa, '[MODAL FEEDBACK]')
      setEmailContato('')
      setMensagem('')
    }
  },[open])
 

  const handleClose = () =>{
    setOpen(false)
    
  }
  return (
    <div>
     <Tooltip title='Informe aqui sobre suas experiências no site, sugestões, problemas....'>
                  <div className={props.botaoAtivo != 'ajuda' ? 'div-button-nav' : 'div-button-nav-ativa'}  onClick={()=>setOpen(true)}>
                    <div>
                      <div><Badge badgeContent='Novo' color='secondary' style={{marginLeft: '5px!important'}}><IconeFeedback/></Badge></div>
                      <span className='rotulo-button-nav'>Feedback</span>
                    </div>
                  </div>
              </Tooltip>
              <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullWidth
        hideBackdrop
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">Feedback sobre o novo Portal</DialogTitle>
        <Divider/>
        <DialogContent>
         
            <Grid container spacing={2}>
              <Grid item xs = {4}>
                <Typography variant='subtitle1'>Email Contato</Typography>
              </Grid>
              <Grid item xs = {8}>
                <TextField
                  variant='outlined'
                  fullWidth
                  size='small'
                  placeholder='Informe seu email'
                  value={emailContato}
                  onChange={(e)=>setEmailContato(e.target.value)}
                  InputLabelProps={{shrink:true}}/>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs = {4}>
                <Typography variant='subtitle1'>Mensagem (até 5000 caracteres)</Typography>
              </Grid>
              <Grid item xs = {8}>
                <TextField
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={mensagem}
                  onChange={(e)=>setMensagem(e.target.value)}
                  placeholder='Descreva aqui sua sugestão, reclamação e outras informações que nos ajudem a aperfeiçoar este portal'
                  multiline
                  rows={5}
                  rowsMax={5}
                  inputProps={{
                    maxlength :'5000'
                  }}
                  InputLabelProps={{shrink:true}}/>
              </Grid>
            </Grid>
                
          <Typography variant='caption' style={{fontSize :'10px'}}>*Informe aqui problemas encontrados, sugestões de melhoria e outras informações</Typography>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={handleClose}>Cancelar</Button>
          <Button variant='contained' color='primary' onClick={()=>{
            Swal.fire({
              title : 'Corporate Web', 
              text : 'Deseja postar esta sugestão agora?', 
              icon : 'question', showConfirmButton:true, 
              showCancelButton:true, 
              confirmButtonText : 'Sim', 
              showCancelButton:true,
              cancelButtonText : 'Não'
            }).then(resp=>{
               if(resp.isConfirmed){
                Api.post('empresaController/gerarFeedbackSite', {
                  codigoCliente :props.codigoCliente, 
                  emailContato :emailContato, 
                  mensagem : mensagem
                }).then(response=>{
                  if(response.status == 200 && response.data.status =='OK'){
                    Swal.fire({
                      title : 'Corporate Web', 
                      text : 'Feedback enviado com sucesso!', 
                      icon : 'success', 
                      showConfirmButton:true, 
                      showCancelButton:false, 
                      confirmButtonText : 'Sim', 
                      cancelButtonText : 'Não', 
                      timer : 2000
                    })
                  }else{
                    Swal.fire({
                      title : 'Corporate Web', 
                      text : 'Não foi possível postar', 
                      icon : 'warning', 
                      showConfirmButton:true, 
                      showCancelButton:false, 
                      confirmButtonText : 'Sim', 
                      cancelButtonText : 'Não', 
                      timer : 2000
                    })
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Corporate Web', 
                    text : 'Não foi possível postar', 
                    icon : 'warning', 
                    showConfirmButton:true, 
                    showCancelButton:false, 
                    confirmButtonText : 'Sim', 
                    cancelButtonText : 'Não', 
                    timer : 2000
                  })
                })
               }
               setOpen(false)
            })
          }}>gravar</Button>
         
        </DialogActions>
       
        
      </Dialog>
    </div>
  );
}
