import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search'
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider, TextField , Grid, Typography, Table, TableHead, TableCell, TableRow, TableBody} from '@material-ui/core';
import Conexao from '../../providers/Api/Api'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2'

export default function ModalIncluiPermissaoEquipamento(props) {
  const [open, setOpen] = React.useState(false)

  const [equipamentos, setEquipamentos] = React.useState([])
  const [loading, setLoading] = React.useState(false);


  const refTipoFiltro = React.useRef();
  const refFiltro = React.useRef();
  
  const getData = () =>{
    setLoading(true)
    Conexao.post('equipamentosController/getEquipamentosPermitidos', {
      codigoCliente : props.codigoCliente, 
      userName : props.userName,
      naoPermitidos : 'not', 
      tipoFiltro : refTipoFiltro.current ? refTipoFiltro.current.value : 'placa', 
      filtro : refFiltro.current ? refFiltro.current.value : '', 
    }).then(response=>{
      if(response.status == 200 && response.data.status =='OK'){
        setEquipamentos(response.data.payload)
      }else{
        setEquipamentos([])
      }
      setLoading(false)
    }).catch(error=>{
      setEquipamentos([])
      setLoading(false)
    })
  }

  React.useEffect(()=>{
    if(!open){
      if(props.getData){
        props.getData();
      }
    }
  }, [open])

  return (
    <div>
     <Button color='primary' variant='contained' style= {{marginTop : 10}} onClick={()=>setOpen(true)}>Novo Equipamento</Button>
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop

      >
        <DialogTitle id="alert-dialog-title">Corporate - WEB</DialogTitle>
        <Divider/>
        <DialogContent style = {{height : '500px', overflow:'scroll', overflowX:'hidden'}}>
          <Grid container spacing={1}>
            <Grid item xs = {4}>
              <TextField 
                variant='outlined'
                InputLabelProps={{shrink:true}}
                fullWidth
                select
                label='Filtrar por?'
                inputRef={refTipoFiltro}
                SelectProps={{native:true}}
                size='small'>
                    <option value='patrimonio'>PAT</option>
                    <option value='numeroSerie'>Número de Série</option>
                    <option value='modelo'>Modelo do Equipamento</option>
                    <option value='contrato'>Número do Contrato</option>
                    <option value='site'>Descrição do Site</option>
                    <option value='departamento'>Departamento</option>
                </TextField>
            </Grid>
            <Grid item xs = {6}>
              <TextField 
                variant='outlined'
                label='Filtro'
                inputRef={refFiltro}
                InputLabelProps={{shrink:true}}
                fullWidth
                size='small'/>
            </Grid>
            <Grid item xs = {2}>
              <Button variant='outlined' color='primary' onClick={()=>getData()}><SearchIcon /> Pesquisar</Button>
            </Grid>
          </Grid>
          <Table className='table table-striped table-hover table-sm table-small' style= {{marginTop : 10}}>
              <TableHead>
                <TableCell align='center' style ={{width : '5%'}}><Typography variant='caption'>Patrimônio</Typography></TableCell>
                <TableCell align='center' style ={{width : '10%'}}><Typography variant='caption'>Número de Série</Typography></TableCell>
                <TableCell style ={{width : '25%'}}><Typography variant='caption'>Modelo</Typography></TableCell>
                <TableCell align='center' style ={{width : '10%'}}><Typography variant='caption'>Contrato</Typography></TableCell>
                <TableCell style ={{width : '20%'}}><Typography variant='caption'>Site</Typography></TableCell>
                <TableCell style ={{width : '20%'}}><Typography variant='caption'>Departamento</Typography></TableCell>
                <TableCell align='center' style ={{width : '15%'}}><Typography variant='caption'>Selecionar</Typography></TableCell>
              </TableHead>

              <TableBody>
                {
                  equipamentos && equipamentos.map(function(equipamento, i){
                    return <TableRow key = {i}>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02112_PAT}</Typography></TableCell>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02112_NUMSERIE}</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB01010_NOME}</Typography></TableCell>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02111_CODIGO}</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02176_NOME }</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02177_NOME }</Typography></TableCell>
                    <TableCell align='center'><Button variant='outlined' color='primary' onClick={()=>{
                      Swal.fire({
                        title : 'Corporate Web', 
                        text : 'Deseja incluir este equipamento na lista de permissão para este usuário?', 
                        icon:'question', 
                        showConfirmButton: true, 
                        showCancelButton: true, 
                        confirmButtonText:'Sim', 
                        cancelButtonText:'Não'
                      }).then(resp=>{
                        if(resp.isConfirmed){
                          
                          Conexao.post('equipamentosController/insertNumeroSerieUsuario', {
                            codigoCliente : equipamento.TB02111_CODCLI, 
                            codigoContrato : equipamento.TB02111_CODIGO,
                            numeroSerie : equipamento.TB02112_NUMSERIE, 
                            userName : props.userName 
                          }).then(response=>{
                            if(response.status == 200 && response.data.status =='OK'){
                              const temp = equipamentos.filter(item => (item.TB02112_NUMSERIE != equipamento.TB02112_NUMSERIE))
                              setEquipamentos(temp)
                            }else{
                            
                            }
                            
                          }).catch(error=>{
                            
                          })
                          
                        }
                      })
                    }}><AddIcon /></Button></TableCell>
                  </TableRow >
                  })
                }
              </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color="secondary" onClick={()=>setOpen(false)}>
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
