import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/SearchTwoTone'
import { Divider, Tooltip } from '@material-ui/core';

export default function ModalDefeitoChamado(props) {
  const [showModal, setShowModal] = React.useState(false)


  const closeModal = () =>{
    setShowModal(false)
  }

  const openModal = () =>{
    setShowModal(true)
  }
  return (
    <div>
     <Tooltip title='Click para ver informações sobre o defeito informado'>
        <Button variant='outlined' color='secondary' onClick={openModal}>{!props.tituloBotao ? <SearchIcon/> : <span style={{fontSize :'11px'}}>{props.tituloBotao}</span>}</Button>
     </Tooltip>
      <Dialog
        open={showModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{props.titulo}: {props.numeroChamado}</DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.texto}
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
