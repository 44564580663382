import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/AccountTree';
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography , Fab, Badge, InputAdornment, Checkbox} from '@material-ui/core';
import IconeNFe from '@material-ui/icons/Assignment'
import Swal from 'sweetalert2';
import IconeCarrinhoCheio from '@material-ui/icons/ShoppingCart'
import AddCart from '@material-ui/icons/ArrowDownward'
import RevemoIcon from '@material-ui/icons/Close'
import {Tabs, Tab} from 'react-bootstrap'
import IconeLocalizar from '@material-ui/icons/Search'
import IconeLike from '@material-ui/icons/ThumbUpAlt'
import IconeNotLike from '@material-ui/icons/ThumbDownAlt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Delete from '@material-ui/icons/DeleteForever'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconeFinanceiro from '@material-ui/icons/MonetizationOn'
import IconeAprovar from '@material-ui/icons/Done'
import IconeCancelar from '@material-ui/icons/RemoveShoppingCart'
import IconeEstoque from '@material-ui/icons/EventAvailable'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Brush'

export default function ModalNovoContatoEmail(props) {
  const [open, setOpen] = React.useState(false);


  const [login, setLogin] = React.useState('');
  const [nomeCompleto, setNomeCompleto] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [contratos, setContratos] = React.useState([])
  const [sites, setSites] = React.useState([])
  const [departamentos, setDepartamentos] = React.useState([])
  const [codigoContrato, setCodigoContrato]= React.useState(null);
  const [codigoSite, setCodigoSite]= React.useState(null);
  const [codigoDepartamento, setCodigoDepartamento]= React.useState(null);
  const [toner, setToner]= React.useState('f');
  const [chamado, setChamado]= React.useState('f');

  const handleClose = () => {
    setOpen(false);
   
  };
  React.useEffect(()=>{
    if(!open){
      setLogin('')
      setNomeCompleto('')
      setCodigoContrato('')
      setCodigoDepartamento('')
      setCodigoSite('')
      setEmail('')
      setSenha('')
    }else{
      console.log(props.codigoEmpresa)
      getListaContratosEmpresa();
    }
  },[open])


  React.useEffect(()=>{
    setSites([])
  }, [contratos])

  React.useEffect(()=>{
    setDepartamentos([])
  }, [sites])

  

 
  

  const getListaContratosEmpresa = () =>{

    props.Conexao.post('contratosController/getListaContratosEmpresa', {
      codigoCliente : props.cliente , 
      codigoEmpresa : props.empresa
    }).then(response=>{
      if(response.status == 200 && response.data.status =='OK'){
        setContratos(response.data.payload)
      }else{
        setContratos([])
      }
    }).catch(error=>{
      setContratos([])
    })
  }

  const insertContatoNotificacao = () =>{
    props.Conexao.post('clientesController/insertContatoNotificacao', {
      codigoEmpresa : props.empresa, 
      codigoCliente : props.cliente, 
      contrato : codigoContrato , 
      codigoSite : codigoSite , 
      codigoDepartamento : codigoDepartamento , 
      nomeCompleto : nomeCompleto, 
      email : email,
      notificarChamado : chamado, 
      notiticarToner : toner
    }).then(response=>{
      if(response.status == 200 && response.data.status == 'OK'){
        Swal.fire({
          title : 'Corporate Web', 
          text : 'Contato adicionado com sucesso!', 
          icon : 'success', 
          showCancelButton: false, 
          showConfirmButton: false, 
          timer : 1500
        })
        props.getData()
      }else{
        Swal.fire({
          title : 'Corporate Web', 
          text : 'Não foi possível adicionar!', 
          icon : 'warning', 
          showCancelButton: false, 
          showConfirmButton: false, 
          timer : 1500
        })
      }
      setOpen(false)
    }).catch(error=>{
      Swal.fire({
        title : 'Corporate Web', 
        text : 'Não foi possível adicionar!', 
        icon : 'error', 
        showCancelButton: false, 
        showConfirmButton: false, 
        timer : 1500
      })
      setOpen(false)
    })
  }

  

  

//getNotasFiscaisContrato
  return (
    <div >

      <Fab onClick={()=>setOpen(true)} size='large' color='primary' ><AddIcon /></Fab>
        
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'sm'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'
      >
        <DialogTitle id="alert-dialog-title">Cadastro de Destino de Notificação</DialogTitle>
        <DialogContent dividers>   
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Nome Completo'
                        placeholder='Identifique o destino da notificação'
                        fullWidth
                        variant='outlined'
                        value={nomeCompleto}
                        onChange={(e)=>setNomeCompleto(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Contrato (deixe em branco para todos)'
                        placeholder='Selecione um contrato na lista'                        
                        fullWidth
                        select
                        SelectProps={{native : true}}
                        variant='outlined'
                        onChange={(e)=>{
                          setSites([])
                          setCodigoContrato(e.target.value)
                          if(e.target.value !=  -1){
                            const temp = contratos.filter(item=> item.TB02111_CODIGO == e.target.value )
                            if(temp){
                              setSites(temp[0].sites)
                            }else{
                              setSites()
                            }
                            
                           // setSites()
                          }
                         
                        }}
                        InputLabelProps={{shrink:true}}>
                          <option value='-1'></option>
                          {
                            contratos && contratos.map(function(contrato, i){
                              return <option value={contrato.TB02111_CODIGO} key={i}>{contrato.TB02111_CODIGO+' - '+contrato.TB02111_NOME}</option>
                            })
                          }
                    </TextField>
                </Grid>
            </Grid>   

            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        disabled={sites && sites.length  == 0}
                        label='Site (deixe em branco para todos)'
                        placeholder='Selecione um site na lista'
                        fullWidth
                        select
                        SelectProps={{native : true}}
                        variant='outlined'                      
                        onChange={(e)=>{
                          setDepartamentos([])
                          setCodigoSite(e.target.value)
                          if(e.target.value > -1){
                           
                              const temp = sites.filter(item => item.TB02176_CODIGO == e.target.value);
                              if(temp){
                                setDepartamentos(temp[0].departamentos)
                              }else{
                                setDepartamentos([])
                              }
                              
                            
                            
                          }
                        }}
                        InputLabelProps={{shrink:true}}>
                          <option value='-1'></option>
                          {
                            sites && sites.map(function(site, i){
                              return <option value={site.TB02176_CODIGO}>{site.TB02176_CODIGO+' - '+site.TB02176_NOME}</option>
                            })
                          }
                        </TextField>
                </Grid>
            </Grid> 

            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Departamento (deixe em branco para todos)'
                        fullWidth
                        select
                        disabled={departamentos && departamentos.length  == 0}
                        placeholder='Selecione um departamento na lista'
                        SelectProps={{native : true}}
                        variant='outlined'
                
                        onChange={(e)=>{
                          setCodigoDepartamento(e.target.value)
                        }}
                        InputLabelProps={{shrink:true}}>
                           <option value='-1'></option>
                           {
                            departamentos && departamentos.map(function(departamento, i){
                              return  <option value={departamento.TB02177_CODIGO}>{departamento.TB02177_CODIGO+' - '+departamento.TB02177_NOME}</option>
                            })
                           }
                        </TextField>
                </Grid>
            </Grid>     


            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Email'
                        fullWidth
                        variant='outlined'
                        value={email}
                        type ='email'
                        multiline
                        rowsMax={3}
                        rows={3}
                        placeholder='Informe a lista de destinos separados por vírgula'
                        inputProps={{
                          type : 'email'
                        }}
                        onChange={(e)=>setEmail(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <Checkbox  onChange={(e)=>{
                      setToner(e.target.checked ? 't' : 'f')
                    }}/>
                    <Typography variant='caption'>Notificar Requisições de Toner</Typography>
                    
                </Grid>
            </Grid>     
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <Checkbox  onChange={(e)=>{
                      setChamado(e.target.checked ? 't' : 'f')
                    }}/>
                    <Typography variant='caption'>Notificar Abertura de Ordens de Serviço</Typography>
                    
                </Grid>
            </Grid>        
        </DialogContent>
        <DialogActions>
            <Grid container spacing ={1}>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                    <Button  color='secondary' fullWidth className='button-error' onClick={()=>{
                        setOpen(false)
                    }}>Cancelar</Button>
                </Grid>
                <Grid item xs={2}>
                <Button className='button-default' fullWidth color='primary' onClick={()=>{

                      if(!nomeCompleto || nomeCompleto ==''){
                        Swal.fire({
                          title : 'Corporate Web', 
                          text : 'Você precisa informar o nome do contato!', 
                          icon : 'error', 
                          showCancelButton: false, 
                          showConfirmButton: false, 
                          timer : 1500,
                          backdrop :"rgba(255, 0, 0, 0.2)"
                        })
                        return
                      }


                      if(!email || email == ''){
                        Swal.fire({
                          title : 'Corporate Web', 
                          text : 'Informe o email antes de continuar', 
                          icon : 'error', 
                          showCancelButton: false, 
                          showConfirmButton: false, 
                          timer : 1500,
                          backdrop :"rgba(255, 0, 0, 0.2)"
                        })
                        return
                      }
                    
                      if(chamado.trim() === 'f' && toner.trim() === 'f'){
                        Swal.fire({
                          title : 'Corporate Web', 
                          text : 'Você precisa selecionar pelo menos uma opção de notificação', 
                          icon : 'error', 
                          showCancelButton: false, 
                          showConfirmButton: false, 
                          timer : 1500, 
                          backdrop :"rgba(255, 0, 0, 0.2)"
                        })
                        return
                      }


                    Swal.fire({
                      title : 'Corporate Web', 
                      title  : 'Deseja cadastrar este Usuário?', 
                      icon : 'question', 
                      showConfirmButton : true, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : true, 
                      cancelButtonText : 'Não'
                    }).then(resposta=>{
                      if(resposta.isConfirmed){
                        if(chamado.trim() === 'f' && toner.trim() === 'f'){
                          Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Você precisa selecionar pelo menos uma opção de notificação', 
                            icon : 'error', 
                            showCancelButton: false, 
                            showConfirmButton: false, 
                            timer : 1500,
                            backdrop :"rgba(255, 0, 0, 0.2)"
                          })
                          return
                        }
                        insertContatoNotificacao()
                        


                        
                      }
                    })
                }}>Gravar</Button>
                </Grid>
            </Grid>
            
            
        </DialogActions>
      </Dialog>
    </div>
  );
}
