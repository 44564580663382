

const GeradorLogErro = (dadosUsuario, tela, error) =>{
    const Conexao  = require('./Api/Api');
    const url = new URL(window.location.href);
    var sBrowser,
    sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("MSIE") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } 

    Conexao.post('EmpresaController/gerarLogErroSite', {
        codigoCliente : dadosUsuario.TB01066_CODCLI, 
        codigoEmpresa : url.hostname, 
        loginAcesso : dadosUsuario.TB01066_USUARIO, 
        nomeTela : tela =='' ? window.location.href : tela, 
        dominio : url.hostname, 
        uuid : dadosUsuario.uuid, 
        navegador : sBrowser, 
        error : error
    }).then(response=>{

    }).catch(error=>{
        console.log(error)
    })
}

module.exports = GeradorLogErro;