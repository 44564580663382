export const TOKEN_KEY = "corporate@usuario";

const GeradorLog = require('../GeradorLog');

const GeradorLogGenerico = require('../GeradorLogGenerico');


export const isAuthenticated = (data) => {
  if(data.location.state){
    if(data.location.state.dadosEmpresa){
      GeradorLog(data.location.state.dadosEmpresa);    
    }else{
      GeradorLogGenerico();
    }
  }
  
  return sessionStorage.getItem(TOKEN_KEY) != null;

  const dataUser = sessionStorage.getItem(TOKEN_KEY);
  return true;
}

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);  
};


export const checkLogout = () =>{
  
}
/*
export const TOKEN_KEY = "corporate@usuario";

export const isAuthenticated = (props) => sessionStorage.getItem(TOKEN_KEY) != null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  
};


export const checkLogout = () =>{
  
}*/