import React from 'react'
import LogoCentermaq from './img/logoCentermaq1.png'
import './Home.css'
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Componentes/Navbar'
import BannerPrincipal from './Components/BannerPrincipal'
import Carrosel from './Componentes/Carrosel'
import { Typography, Button } from '@material-ui/core';

import AppsIcon from '@material-ui/icons/Apps';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ExtensionIcon from '@material-ui/icons/Extension';
import HealingIcon from '@material-ui/icons/Healing';
import ScannerIcon from '@material-ui/icons/Scanner';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SchoolIcon from '@material-ui/icons/School';
import ImagemOutsourcing from './cardOutsourcing.svg'
import Imagem3d from './imagem3d.svg'
import ImagemRCM from './imagemECM.svg'
import ImagemZebra from './imagemZebra.svg'
import CarroselMarcas from './CarrosselMarcas'



const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

export default function (props){
    const [open, setOpen] = React.useState(false);
    const [position, setPosition] = React.useState(0);
    const classes = useStyles();

    React.useEffect(()=>{     
        window.addEventListener("scroll", handleScroll);     
    }, [])

    const  handleScroll = () => {
        setPosition(window.pageYOffset)
    };

      const handleClose = () => {
        setOpen(false);
      };
    return <div id='container-hone '>                
               <Navbar icon = {LogoCentermaq}/> 
               <BannerPrincipal /> 
               <br/>
               <br/>
               <div style ={{textAlign:"center"}}>
               <Typography variant='h3'>Conheça Nossas Soluções</Typography>
               <br/>
               </div>
               <div className='cards-solucoes-main'>
                
                 <div>
                 <img src ={ImagemOutsourcing} /><br/>
                   <Typography variant='caption'>Outsourcing de Impressão</Typography><br/>
                   <br/>
                     <Button variant='contained' color='secondary'>Saiba mais</Button>
                     <br/>
                 </div>
                 <div>
                   <img src ={ImagemRCM} /><br/>
                   <Typography variant='caption'>Gerenciamento de Documentos</Typography><br/>
                   <br/>
                     <Button variant='contained' color='secondary'>Saiba mais</Button>
                     <br/>
                 </div>
                 <div>
                 <img src ={Imagem3d} /><br/>
                 <Typography variant='caption'>Impressão 3D</Typography><br/>
                 <br/>
             <Button variant='contained' color='secondary'>Saiba mais</Button>
             <br/>
                 </div>

                 
                 <div>
                 <img src ={ImagemZebra} /><br/>
                 <Typography variant='caption'>Impressão Térmica</Typography><br/>
                 <br/>
             <Button variant='contained' color='secondary'>Saiba mais</Button><br/>
                 </div>
               </div>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
       
             
        </div>
}