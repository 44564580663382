import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import NivelToner from '../../../src/components/DisplayNivelToner/DisplayNivelToner'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ItensRequisicao from './ItensRequisicao';
import ModalVideo from '../../components/base/ModalVideo';
import GeradorLog from '../../providers/GeradorLog';

const Equipamentos = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)
    const [paginas, setPadinas]= useState(1)

    const [pedidos, setPedidos] = React.useState([]);


   // const dadosEmpresa = props.location.state.dadosEmpresa;

    const refDataInicial =useRef();
    const refDataFinal = useRef();
    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
    },[])

    useEffect(()=>{
        //GeradorLog(dadosUsuario);
    },[])

   

    const getData = () =>{
        setLoading(true)
        Conexao.post('pedidosController/getHistoricoPedidos', {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            contratos : dadosUsuario.contratos ? dadosUsuario.contratos.map(function(contrato){
                return contrato.numeroContrato
            }) : [],
            codigoEmpresa : codigoEmpresa, 
            dataInicial : refDataInicial.current ?refDataInicial.current.value : '', 
            dataFinal :  refDataFinal.current ? refDataFinal.current.value : '', 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            pagina : page
        }).then(response=>{
            console.log(response.data)
            if(response.data.status =='OK'){
                
                setPedidos(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setPedidos([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error=>{
            setPedidos([])
            setRegistros(0)
            setLoading(false)
        })
    }

    const renderRotulo = (cor)=>{
        switch(cor){
            case 'CIANO': return '#4FC3F7';
            case 'CIAN': return '#4FC3F7';
            case 'MAGENTA': return '#F50057';
            case 'AMARELO': return '#FDD835';
            case 'PRETO': return '#000';
        }
    }

    const renderStatusIcon = (status)=>{

        switch(status){
            case 'G':
                return <div><AccessTimeTwoToneIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>AGUARDANDO</Typography></div>
            case 'G':
                return <div><AccessTimeTwoToneIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>AGUARDANDO</Typography></div>
            case 'A':
                return <div><CheckIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>APROVADO</Typography></div>
            case 'C':
                return <div><BlockTwoToneIcon color="secondary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>CANCELADO</Typography></div>
            case 'F':
                return <div><EventBusyTwoToneIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>S/ ESTOQUE</Typography></div>
            case 'P':
                return <div><WarningTwoToneIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>FINANCEIRO</Typography></div>
            case 'E':
                 return <div><LocalShippingTwoToneIcon color="primary" style={{fontSize:"36px"}} /><br/><Typography variant='caption'>ENVIADO</Typography></div>
            
        }
       
    }


    const renderIcone = (valor,) =>{
        switch(valor){
            case 'PCONNECT':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>PRINTERCONNECT</div>
            case 'ALERTA':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>ALERTA</div>
            case 'MANUAL':
                return <div  style={{fontSize:"9px"}}><CallTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>MANUAL</div>
            case 'SITE':
                return <div  style={{fontSize:"9px"}}><LaptopChromebookTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>SITE</div>
            case 'INSTALAÇÃO':
                return <div  style={{fontSize:"9px"}}><DescriptionTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>INSTALAÇÃO</div>
            case 'REPOSICAO':
            return <div  style={{fontSize:"9px"}}><ThreeSixtyTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>REPOSIÇÃO</div>
            
            
        }
    }

    return <Principal {...props} botaoAtivo = 'suprimentos' loading={loading}>
        <div className='container-children'>
        <ModalVideo 
                    urlVideo = ''>
                        <iframe 
                            width="900" 
                            height="500" 
                            src="https://www.youtube.com/embed/rtccDtxwPqM?list=PLiKQeSFs5NZtaqx23PK8_I1Cglf7b2gNo" 
                            title="Como abrir um Novo Pedido de Suprimentos." 
                            frameborder="0" 
                            allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                                
                        </iframe>
                </ModalVideo>
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Requisições de Suprimentos</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage} 
                    labelRowsPerPage="Linhas por página:"                  
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
            <Grid item xs = {2}>
                    <TextField 
                        label = 'Incial'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataInicial}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Final'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataFinal}/>
                </Grid>
                

                <Grid item xs = {2}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'numero'>Pedido</option>
                            <option value = 'placa'>Patrimônio</option>
                            <option value = 'numeroSerie'>Série</option>                                                        
                            <option value = 'modelo'>Modelo</option>
                            <option value = 'setor'>Setor</option>
                    </TextField>
                </Grid>
                <Grid item xs = {4}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData();
                            }
                        }}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'G'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
            <Typography style={{marginTop : '5px'}} variant='caption'>* Relação de todos as Requisições de Suprimentos já realizadas (Enviadas, Canceladas, Aguardando), você pode consultar as requisições feitas recentemente pelo portal através da opção <Link to={{pathname : "/solicitacoes-suprimentos/empresa", state:{
                        ...props.location.state
                    }}}><span className='text-link-bold'>Solicitações Toner</span></Link>.</Typography>
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                    <TableHead>
                        <TableCell align='center' style={{width: '4%'}}>NUM</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>SOL</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>NF</TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Data</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Soliciante</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>Pat</TableCell>
                        <TableCell align='left' style={{width: '18%'}}>Equipamento</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Setor</TableCell>
                        <TableCell align='center' style={{width: '18%'}}>Tipo</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Status</TableCell>
                        <TableCell align='center' style={{width: '5%'}}>Ver</TableCell>
                      
                        
                    </TableHead>
                    <TableBody>
                        {
                            pedidos && pedidos.map(function(pedido, i){
                                return <TableRow key ={i}>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}}><strong>{pedido.TB02021_CODIGO}</strong></Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}} >{pedido.TB02021_NUMORC}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}} >{pedido.TB02021_NTFISC}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}}>{pedido.TB02021_DTCAD}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' style= {{fontSize:'10px'}}>{pedido.TB02021_NOME}</Typography></TableCell>
                                <TableCell align='center' >
                                    <Typography variant='caption'  style= {{fontSize:'10px'}}><span className='display-placa' >{pedido.TB02112_PAT}</span></Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption' className='texto-limitado'  style= {{fontSize:'10px'}}>{pedido.TB01010_NOME}</Typography><br/>
                                    <Typography variant='caption'  style= {{fontSize:'10px'}}>{pedido.TB02112_NUMSERIE}</Typography>
                                </TableCell>
                                <TableCell align='center'><Typography variant='caption'  style= {{fontSize:'10px'}} className='texto-limitado'>{pedido.TB02112_LOCAL}</Typography></TableCell>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}} className='texto-limitado'>{pedido.TB01022_NOME}</Typography></TableCell>
                                <TableCell align='center' ><Typography variant='caption' style= {{fontSize:'10px'}} className='texto-limitado'>{pedido.TB01021_NOME}</Typography></TableCell>
                                <TableCell align='center'>
                                    <ItensRequisicao pedido={pedido} Conexao={Conexao}/>
                                </TableCell>
                               
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>      
        </div>
        <div className='botaoAcoes'>
            <Tooltip title ='Abrir uma nova solicitação de suprimentos'>
                <Fab color="primary" aria-label="add"   elevation={10} onClick={()=>{
                        props.history.push({
                            pathname : '/equipamentos/centermaq', state:{
                               ...props.location.state
                            }
                        })
                    }}>
                    <AddIcon />
                </Fab>
            </Tooltip>               
        </div>
    </Principal>
}

export default withRouter(Equipamentos)