import React, { PureComponent } from 'react';
import { LineChart, Line, ReferenceLine,  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
//https://recharts.org/en-US/examples/TwoLevelPieChart




export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  constructor(props){
    super(props)
  }



  render() {
    return (
      <ResponsiveContainer width="100%" height="100%" >
        <LineChart
          width={'100%'}
          height={'100%'}
          data={this.props.data.grafico && this.props.data.grafico}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 1,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dia" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/*<ReferenceLine y={3} label="Média Mono" stroke="black" strokeDasharray="3 3" />
          <ReferenceLine y={3} label="Média Color" stroke="7b1fa2" strokeDasharray="3 3" />
        <ReferenceLine y={3} label="Média Total" stroke="004d40" strokeDasharray="3 3" />*/}
          <ReferenceLine y={this.props.data.mediaMono} label="Média Monocromática" stroke="black" strokeDasharray="5 5" />
          <ReferenceLine y={this.props.data.mediaColor} label="Média Colorida" stroke="#7b1fa2"  strokeDasharray="5 5"/>
          {(this.props.data.mediaTotal !=  this.props.data.mediaMono)  && <ReferenceLine y={this.props.data.mediaTotal} label="Média Total" stroke="#004d40" />}
          <Line type="monotone" dataKey="contadormono" stroke="black" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="contadorcolor" stroke="#7b1fa2" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="contadortotal" stroke="#004d40" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
