import React , {useEffect, useState, useRef} from 'react';
import { Typography, Divider, TextField, Grid, InputAdornment, Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, LinearProgress} from '@material-ui/core';
import Swal from 'sweetalert2';
import Conexao from '../providers/Api/Api'



export default function EquipamentoQRCode(props){
    const url = new URL(window.location.href);
    const [login, setLogin] = useState('tilibra')
    const [senha, setSenha] = useState('t123')
    const [dominio, setDominio] = useState('')
    const [loading, setLoading]= useState(false)
    const [numeroSerie, setNumeroSerie] = useState(props.match.params.numeroserie)
    const [produto, setProduto] = useState(props.match.params.produto)
    const [empresa, setEmpresa] = useState([])

    React.useEffect(()=>{
        let temp = url.hostname.split('.');
        setDominio(temp[0])
        getDataEmpresa();
    },[])
   
    const getDataEmpresa = () =>{
        setLoading(true)
       
        Conexao.get('empresaController/getDataEmpresaSite?dominio='+props.match.params.empresa).then(response=>{
            if(response.status == 200 && response.data.status == 'OK'){
                
                setEmpresa(response.data.payload);
            }else{
                setEmpresa([])
            }
            setLoading(false)
        }).catch(error=>{
           
            setEmpresa([])
            setLoading(false)
        })
        
    }
    const autenticarUsuario = () =>{
        /*props.history.push({pathname:'/home/'+props.dadosEmpresa.empresa, state :{
            dadosEmpresa : []//response.data.data
        }})*/
        
        setLoading(true)
    
        Conexao.post('ClientesController/autenticarUsuarioQRCode', {
            login : login, 
            senha: senha, 
            codigoEmpresa :'00',
            getEquipamento : 't', 
            numeroSerie : props.match.params.numeroserie
        }).then(response=>{
        
            if(response.status == 200 && response.data.status =='OK'){
               
                props.history.push({
                    pathname : '/qrcode/'+produto+'/'+props.match.params.numeroserie+'/'+response.data.payload.TB01066_CODCLI, state:{
                        payload : response.data.payload,  
                                        
                    }
                  })
            ///qrcode/:produto/:numeroserie/:empresa/:cliente
            }else{
               
                Swal.fire({
                    title :'Corporate WEB', 
                    text : 'Não foi possível autenticar! Verifique usuário e senha!', 
                    showConfirmButton:true, 
                    confirmButtonText:'Ok', 
                    showCancelButton:false, 
                    icon:'warning'
                })
            }
           setLoading(false)
        }).catch(error=>{
            alert(error)
            Swal.fire({
                title :'Corporate WEB',  
                text : 'Não foi possível autenticar! Verifique usuário e senha!', 
                showConfirmButton:true, 
                confirmButtonText:'Ok', 
                showCancelButton:false, 
                icon:'error'
            })
           setLoading(false)
        })

    }

    return <div>
                {loading && <LinearProgress variant='indeterminate' color='secondary'/>}
                <div className='container-chamado-qrcode'>
                        <div id='banner-qrcode'>
                            <Typography variant='h5' color='primary'>{props.match.params.empresa ? props.match.params.empresa.toUpperCase() : 'Corporate WEB'}</Typography>
                        </div>
                        <Grid container spacing={1} style ={{marginTop :'20px'}}>
                            <Grid item xs={12}>
                                <TextField 
                                    label = 'Login'
                                    variant='outlined'
                                    fullWidth
                                    value={login}
                                    onChange={(e)=>setLogin(e.target.value)}
                                    InputLabelProps={{shrink :true}}                        
                                    />
                            </Grid>
                            <br/>
                            <Grid item xs={12} style={{marginTop : '20px'}}>
                                <TextField 
                                    label = 'senha'
                                    variant='outlined'
                                    fullWidth
                                    value={senha}
                                    onChange={(e)=>setSenha(e.target.value)}
                                    InputLabelProps={{shrink :true}}    
                                    type='password'                    
                                    />
                            </Grid>
                            <Grid item xs={12} style={{marginTop : '20px'}}>
                                <TextField 
                                    label = 'Número Série'
                                    value={numeroSerie}
                                    variant='outlined'
                                    fullWidth
                                    InputLabelProps={{shrink :true}}    
                                                    
                                    />
                            </Grid>
                            <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <Typography variant='caption'>* Informe seus dados de acesso para seguir com a abertura de chamado técnico ou solicitação de suprimento.</Typography>
                            </div>
                            <Grid item xs={12} style={{marginTop : '20px'}}>
                                <Button variant='contained' color='primary' fullWidth size='large' style={{height : '60px'}} disabled={numeroSerie == '' || loading} onClick={()=>{
                                    if(loading){
                                        return
                                    }
                                    if(login == '' || senha == ''){
                                        Swal.fire({
                                            title : 'Corporate Web', 
                                            text : 'Informe o login e senha antes de continuar!', 
                                            icon : 'warning', 
                                            showCancelButton : false, 
                                            cancelButtonText : 'Não', 
                                            showConfirmButton : true, 
                                            confirmButtonText : 'OK', 
                                        }) 
                                        return;
                                    }
                                    Swal.fire({
                                        title : 'Corporate Web', 
                                        text : 'Deseja prossegur?', 
                                        icon : 'question', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Não', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Sim'
                                    }).then(resposta =>{
                                        if(resposta.isConfirmed){
                                            autenticarUsuario()
                                        }
                                    })
                                }}>Entrar</Button>
                            </Grid>
                        </Grid>
                </div>
    </div>
}


