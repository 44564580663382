import React from 'react';
import Conexao from '../../providers/Api/Api'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/AccountTree';
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography , Fab, Badge, InputAdornment, LinearProgress} from '@material-ui/core';
import IconeNFe from '@material-ui/icons/Assignment'
import Swal from 'sweetalert2';
import IconeCarrinhoCheio from '@material-ui/icons/ShoppingCart'
import AddCart from '@material-ui/icons/ArrowDownward'
import iconRemove from '@material-ui/icons/Close'
import {Tabs, Tab} from 'react-bootstrap'
import IconeLocalizar from '@material-ui/icons/Search'
import IconeLike from '@material-ui/icons/ThumbUpAlt'
import IconeNotLike from '@material-ui/icons/ThumbDownAlt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Delete from '@material-ui/icons/DeleteForever'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconeFinanceiro from '@material-ui/icons/MonetizationOn'
import IconeAprovar from '@material-ui/icons/Done'
import IconeCancelar from '@material-ui/icons/RemoveShoppingCart'
import IconeEstoque from '@material-ui/icons/EventAvailable'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Brush'
import DelIcon from '@material-ui/icons/DeleteForever'
import ModalIncluiPermissaoEquipamento from './ModalIncluiPermissaoEquipamento';
import GeradorLog from '../../providers/GeradorLog';
import GeradorLogErro from '../../providers/GeradorLogErro';

export default function ModalImpressorasVinculadas(props) {
  const [open, setOpen] = React.useState(false);
  const [equipamentos, setEquipamentos] = React.useState([])
  const [loading, setLoading] = React.useState(false);


 

  const handleClose = () => {
    setOpen(false);
   
  };
  React.useEffect(()=>{
    if(open){
      GeradorLog(props.dadosUsuario, '[MODAL IMPRESSORAS VINCULADAS]->open-> '+props.userName);
      getData();
    }else{
      
    }
  },[open])


  const getData = () =>{
    setLoading(true)
    Conexao.post('equipamentosController/getEquipamentosPermitidos', {
      codigoCliente : props.codigoCliente, 
      naoPermitidos : '', 
      userName: props.userName
    }).then(response=>{
      if(response.status == 200 && response.data.status =='OK'){
        setEquipamentos(response.data.payload)
      }else{
        setEquipamentos([])
      }
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
    })
  }

//getNotasFiscaisContrato
  return (
    <div >

      
      {!props.hideNovoButton &&<Button variant='outlined' color='secondary' onClick={()=>setOpen(true)}>Todos</Button>}
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop
  
        scroll='body'
      >
        <DialogTitle id="alert-dialog-title">Equipamentos Permitidos</DialogTitle>
        <DialogContent dividers style={{minHeight :'500px'}}>   
        {loading &&<LinearProgress variant='indeterminate' color='secondary'/>}
        <Typography variant='subtitle2' style= {{marginTop : 10}}>* Adicione novos equipamentos a lista de permissões do usuário</Typography>
            <ModalIncluiPermissaoEquipamento getData={()=>getData()} codigoCliente ={props.codigoCliente} userName={props.userName}/>
            <Table className='table table-striped table-hover table-sm table-small' style= {{marginTop : 10}}>
              <TableHead>
                <TableCell align='center' style ={{width : '5%'}}><Typography variant='caption'>Patrimônio</Typography></TableCell>
                <TableCell align='center' style ={{width : '10%'}}><Typography variant='caption'>Número de Série</Typography></TableCell>
                <TableCell style ={{width : '25%'}}><Typography variant='caption'>Modelo</Typography></TableCell>
                <TableCell align='center' style ={{width : '10%'}}><Typography variant='caption'>Contrato</Typography></TableCell>
                <TableCell style ={{width : '20%'}}><Typography variant='caption'>Site</Typography></TableCell>
                <TableCell style ={{width : '20%'}}><Typography variant='caption'>Departamento</Typography></TableCell>
                <TableCell align='center' style ={{width : '15%'}}><Typography variant='caption'>Remove</Typography></TableCell>
              </TableHead>

              <TableBody>
                {
                  equipamentos && equipamentos.map(function(equipamento, i){
                    return <TableRow key = {i}>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02112_PAT}</Typography></TableCell>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02112_NUMSERIE}</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB01010_NOME}</Typography></TableCell>
                    <TableCell align='center'><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02111_CODIGO}</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02176_NOME }</Typography></TableCell>
                    <TableCell ><Typography variant='caption' style={{fontSize:'11px'}}>{equipamento.TB02177_NOME }</Typography></TableCell>
                    <TableCell align='center'>{equipamento.REMOVIVEL =='T' && <Button variant='outlined' color='secondary' onClick={()=>{
                      Swal.fire({
                        title : 'Corporate Web', 
                        text : 'Deseja remover este equipamento na lista de permissão para este usuário?', 
                        icon:'warning', 
                        showConfirmButton: true, 
                        showCancelButton: true, 
                        confirmButtonText:'Sim', 
                        cancelButtonText:'Não'
                      }).then(resp=>{
                        if(resp.isConfirmed){
                          Conexao.post('equipamentosController/removeEquipamentoPermitidoUsuario', {
                            codigoCliente : equipamento.TB02111_CODIGO, 
                            codigoContrato : equipamento.TB02111_CODCLI,
                            numeroSerie : equipamento.TB02112_NUMSERIE, 
                            userName : props.userName
                          }).then(response=>{
                            if(response.status == 200 && response.data.status =='OK'){
                              const temp = equipamentos.filter(item => (item.TB02112_NUMSERIE != equipamento.TB02112_NUMSERIE))
                              setEquipamentos(temp)
                            }else{
                            
                            }
                            
                          }).catch(error=>{
                            
                          })

                        }
                      })
                      
                    }}><DelIcon /></Button>}</TableCell>
                  </TableRow >
                  })
                }
              </TableBody>
            </Table>
            {equipamentos && equipamentos.length == 0 && <Typography variant='caption' color='secondary'>* O usuário esta com permissão em todos os equipamentos disponíveis!</Typography>}
        </DialogContent>
        <DialogActions>
            <Grid container spacing ={1}>
                <Grid item xs={10}></Grid>
                <Grid item xs={2}>
                    <Button  color='secondary' variant='outlined' fullWidth  onClick={()=>{
                       GeradorLog(props.dadosUsuario, '[MODAL IMPRESSORAS VINCULADAS]->close');
                        setOpen(false)
                    }}>Cancelar</Button>
                </Grid>
                
            </Grid>
            
            
        </DialogActions>
      </Dialog>
    </div>
  );
}
