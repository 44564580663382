import React, {useState, useRef, Fragment, useEffect} from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
  import Typography from '@material-ui/core/Typography';




  export default function(props){

    return <div className='nivel-toner-container' style={{opacity : props.opacity ? 0.2 : 1}}>
                <div >
                <div style = {{width : props.width, height : props.height, display:'flex', alignContent:'center', alignItems:'center', justifyContent:'center', }}>
                    <CircularProgressbar value={props.percentual} text={props.percentual+'%'} strokeWidth ={props.lineSize} styles={buildStyles({
                        textColor: '#000',
                        pathColor: props.color,                        
                    })}/>
                </div>
                <Typography variant = 'caption' style ={{fontSize:props.fontRotulo ? props.fontRotulo : "9px", minWidth:"100%", width:"100%"}}>{props.descricao}</Typography>
                </div>
            </div> 

  }

