import React , {useEffect, useState, useRef} from 'react';
import { Typography, Divider, TextField, Grid, InputAdornment, Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, LinearProgress} from '@material-ui/core';
import Swal from 'sweetalert2';
import Conexao from '../providers/Api/Api'
import Dataservice from '../providers/Api/Dataservice';
import IconeSolicitacoes from '@material-ui/icons/ShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import {withRouter} from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'


const  HomeQRCode = (props) =>{
    const {payload} =props.location.state

    useEffect(()=>{
       // getDataEquipamento()
       console.log(payload)
    }, [])

    return <div className='main-container-chamadoQR'>
        <div style ={{backgroundColor :'#fff', padding :'5px', borderRadius :'3px'}}>
            <Grid container spacing={2} >
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>PAT</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>{payload.equipamento ? payload.equipamento.TB02112_PAT : null}</Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>SERIAL</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>{payload.equipamento ? payload.equipamento.TB02112_NUMSERIE : null}</Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>MODELO</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>{payload.equipamento ? payload.equipamento.TB01010_NOME : null}</Typography>
                </Grid>            
            </Grid>
        </div>
        <div style={{padding :'7px'}}>

        <Grid container spacing={2} style={{marginTop:'10px'}}>
            <Button variant='outlined' style={{height:'150px', backgroundColor:'#fff'}}fullWidth>
                <div>
                    <IconeSolicitacoes style={{fontSize:'60px'}} /><br/>
                    <Typography variant='caption'>Solicitação de Toner</Typography>
                </div>
                
            </Button>
        </Grid>
        <Grid container spacing={2} style={{marginTop:'20px'}}>
            <Button variant='outlined' style={{height:'150px' , backgroundColor:'#fff'}} fullWidth>
                <div>
                    <BuildIcon  style={{fontSize:'60px'}}/><br/>
                    <Typography variant='caption'>CHAMADO TÉCNICO</Typography>  
                </div>
                   
            </Button>
        </Grid>
        <Grid container spacing={2} style={{marginTop:'20px'}}>
            <Button variant='outlined' style={{height:'150px', backgroundColor:'#fff'}} fullWidth color='secondary'>
                <div>
                    <CloseIcon style={{fontSize:'60px'}} /><br/>
                    <Typography variant='caption'>SAIR</Typography> 
                </div>
                    
            </Button>
        </Grid>
        </div>
    </div>
}

export default withRouter(HomeQRCode)