import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import Api from '../../providers/Api/Api'

export default function ItensRequisicao(props) {
  const [open, setOpen] = React.useState(false);
  const [itensRequisicao, setItensRequisicao] = React.useState([]);
  const [entrega, setEntrega] = React.useState([]);


  const handleClickOpen = () => {
    setOpen(true);
    getItensPedidos();
  };

  const handleClose = () => {
    setOpen(false);
  };


  const getItensPedidos =  () =>{
      props.Conexao.post('pedidosController/getItensPedidos', {
        numeroRequisicao : props.pedido.TB02021_CODIGO, 
        codigoEmpresa : props.pedido.TB02021_CODEMP
      }).then(response=>{
        if(response.status == 200 && response.data.status == 'OK'){
            setItensRequisicao(response.data.payload.data)
            setEntrega(response.data.payload.entrega);
        }else{
            setItensRequisicao([])
            setEntrega([])
        }
      }).catch(error=>{
        setItensRequisicao([])
        setEntrega([])
      })
    
      
      
  }

  return (
    <div>
 
        <Button variant='outlined' color='secondary' onClick={handleClickOpen}>
            <SearchIcon />
        </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = {'md'}
        fullWidth
        scroll='body'
        hideBackdrop
      >
        <DialogTitle id="alert-dialog-title">Detalhes Requisição de Suprimentos</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Typography variant='subtitle2'>Requisição Nº <strong>{props.pedido.TB02021_CODIGO}</strong> </Typography>
          <Typography variant='subtitle2'>Equipamento <strong>{props.pedido.TB02112_NUMSERIE}</strong>{' '+props.pedido.TB01010_NOME} </Typography>
          {
            entrega && entrega.TB02096_SEDEX && <Typography variant='subtitle2'>Sedex Nº <strong>{entrega.TB02096_SEDEX}</strong> </Typography>
          }
          <br/>
            <Table  style ={{width:"100%"}}>
                <TableHead>
                    <TableCell align='center' style ={{width:"20%"}}>Código</TableCell>
                    <TableCell style ={{width:"60%"}}>Descrição</TableCell>
                    <TableCell align='center' style ={{width:"20%"}}>Quantidade</TableCell>
                </TableHead>
                <TableBody>
                    
                    {
                        itensRequisicao && itensRequisicao.map(function(item, i){
                            return <TableRow key ={i}>
                                <TableCell align='center'>{item.TB01010_REFERENCIA}</TableCell>
                                <TableCell>{item.TB01010_NOME}</TableCell>
                                <TableCell align='center'>{item.TB02022_QTPROD}</TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>     
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant='outlined'>
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
