import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button,Checkbox, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, Switch} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/PrinterconnectV2Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'

import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import IconeOnline from '@material-ui/icons/Link'
import IconeOffLine from '@material-ui/icons/LinkOff'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import GeradorLog from '../../providers/GeradorLog';
import Api from '../../providers/Api/Api';

const GestaoRelatorios = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [dataInicial, setDataInicial] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [contrato, setContrato] = useState('f')
    const [site, setSite] = useState('f')
    const [departamento, setDepartamento] = useState('f')
    const [pat, setPat] = useState('')
    const [numeroSerie, setNumeroSerie] = useState('')
    const [modelo, setModelo] = useState('')
    const [atendimento, setAtendimento]= useState('f')
    const [preventiva, setPrevetiva]= useState('f')
    const [retorno, setRetorno]= useState('f')
    const [instalacao, setInstalacao]= useState('f')
    const [desinstalacao, setDesinstalacao]= useState('f')
    const [tipoRelatorio, setTipoRelatorio]= useState('html')

    const [parametrosFiltro, setParametrosFiltro] = useState([])


   
  
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [coletas, setColetas] = React.useState([]);

  
  

    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    
    //alert(JSON.stringify(dadosUsuario.operacaoEmpresa))
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       getData();
    },[])

    


    useEffect(()=>{
        
       // GeradorLog(dadosUsuario);
    },[])

    let chavePrinterconnect = dadosUsuario.TB01008_TOKEN.split('-');



    const getData = () =>{
        

        setLoading(true)
        Api.post('relatoriosController/getParametrosRelatorio?empresa='+codigoEmpresa+'&codigoCliente='+dadosUsuario.TB01066_CODCLI, {
            idempresa : codigoEmpresa,
            idcliente : dadosUsuario.TB01066_CODCLI,            
        }).then(response=>{
            
            if(response.data.status =='OK'){
                setParametrosFiltro(response.data.payload);
                
            }else{
                setParametrosFiltro([]);
               
            }
            setLoading(false)
        }).catch(error=>{
            setParametrosFiltro([]);
          
            setLoading(false)
        })
    }

  

    const renderIconeConexao = (dias)=>{
        return dias == 0 ? <IconeOnline style={{color : '#388E3C'}}/> : <IconeOffLine style={{color : '#C62828'}}/>
    }

    const renderSites = () =>{
        if(contrato == 'f'){
            return parametrosFiltro.sites.map(function(site, i){
                return <option value ={site.TB02176_CODIGO}>{site.TB02176_NOME}</option>
            })
        }else{
            return parametrosFiltro.sites.filter(item=> item.TB02176_CONTRATO == contrato).map(function(site, i){
                return <option value ={site.TB02176_CODIGO}>{site.TB02176_NOME}</option>
            })
        }
        
    }

    const renderDepartamentos = () =>{
        if(site == 'f'){
            return  parametrosFiltro.departamentos.map(function(departamento, i){
                return <option value ={departamento.TB02177_CODIGO}>{departamento.TB02177_NOME}</option>
            })
        }else{
            return  parametrosFiltro.departamentos.filter(item => item.TB02177_CODSITE == site ).map(function(departamento, i){
                return <option value ={departamento.TB02177_CODIGO}>{departamento.TB02177_NOME}</option>
            })
        }
    }

   
    return <Principal {...props} botaoAtivo = 'monitoramento' loading={loading}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                              ...props.location.state, 
                              
                            }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Relatório de Ordens de Serviço</Typography>
                </Breadcrumbs>
                </div>
                
               
                
            </div>
            <br/>
            <div style ={{paddingLeft : '25%', paddingRight : '25%'}}>
            <Grid container spacing={1} style={{marginTop : '20px'}}>
                <Grid item xs ={6}>
                    <TextField
                        label = 'Data Inicial'
                        variant='outlined'
                        type='date'
                        value={dataInicial}
                        onChange={(e)=>setDataInicial(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
                <Grid item xs ={6}>
                    <TextField
                        label = 'Data Final'
                        variant='outlined'
                        type='date'
                        value={dataFinal}
                        onChange={(e)=>setDataFinal(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
                
               
            </Grid>
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs ={4}>
                    <TextField
                        label = 'Contrato'
                        variant='outlined'
                        value={contrato}
                        onChange={(e)=>setContrato(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{
                            native : true
                        }}
                        fullWidth>
                            <option value ='f'>* Todos</option>
                            {
                                parametrosFiltro.contratos && parametrosFiltro.contratos.map(function(contrato, i){
                                    return <option value = {contrato.TB02111_CODIGO}>{contrato.TB02111_CODIGO}</option>
                                })
                            }
                    </TextField>
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        label = 'Local de Instalação (Site)'
                        variant='outlined'
                        value={site}
                        onChange={(e)=>setSite(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{
                            native : true
                        }}
                        fullWidth>
                            <option value ='f'>* Todos</option>
                            {
                                parametrosFiltro.sites && renderSites()
                            }
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs ={8}>
                    <TextField
                        label = 'Departamento'
                        variant='outlined'
                        value={departamento}
                        onChange={(e)=>setDepartamento(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{
                            native : true
                        }}
                        fullWidth>
                            <option value ='f'>* Todos</option>
                            {
                                parametrosFiltro.departamentos && renderDepartamentos()
                            }
                    </TextField>
                </Grid>
                <Grid item xs ={4}>
                    <TextField
                        label = 'PAT'
                        variant='outlined'
                        value={pat}
                        onChange={(e)=>setPat(e.target.value)}
                        fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs ={4}>
                    <TextField
                        label = 'Número Série'
                        variant='outlined'
                        value={numeroSerie}
                        onChange={(e)=>setNumeroSerie(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        label = 'Modelo'
                        variant='outlined'
                        value={modelo}
                        onChange={(e)=>setModelo(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs ={4}>
                    <TextField
                        label = 'Tipo Relatório'
                        variant='outlined'
                        value={tipoRelatorio}
                        onChange={(e)=>setTipoRelatorio(e.target.value)}
                        InputLabelProps={{shrink:true}}
                        select
                        SelectProps={{native : true}}
                        fullWidth>
                            <option value='html'>Tela</option>
                            <option value='excel'>Excel</option>
                    </TextField>
                </Grid>
                
            </Grid>
            <br/>
            <br/>
            {/*
            <Typography variant='subtitle1'>Tipos de Atendimento</Typography>
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs = {2}  style={{textAlign:'center'}}>
                    <Typography variant='caption'>Atendimento</Typography><br/>
                    <Checkbox />
                </Grid>
            
                <Grid item xs = {2}  style={{textAlign:'center'}}>
                    <Typography variant='caption'>Retorno</Typography><br/>
                    <Checkbox />
                </Grid>

                <Grid item xs = {2} style={{textAlign:'center'}}>
                    <Typography variant='caption'>Preventiva</Typography><br/>
                    <Checkbox />
                </Grid>

                <Grid item xs = {2}  style={{textAlign:'center'}}>
                    <Typography variant='caption'>Instalação</Typography><br/>
                    <Checkbox />
                </Grid>
  
                <Grid item xs = {2}  style={{textAlign:'center'}}>
                    <Typography variant='caption'>Retirada</Typography><br/>
                    <Checkbox />
                </Grid>

                <Grid item xs = {2}  style={{textAlign:'center'}}>
                    <Typography variant='caption'>Aferição</Typography><br/>
                    <Checkbox />
                </Grid>
            </Grid>
            */
            }
            <Grid container spacing={1} style={{marginTop : '10px', textAlign:'center', marginTop : '30px'}}>
                <a style ={{width:'100%'}} href ={'/api/index.php/relatoriosController/relatorioChamadosPeriodo?empresa='+codigoEmpresa+'&cliente='+dadosUsuario.TB01066_CODCLI+'&datainicial='+dataInicial+'&datafinal='+dataFinal+'&contrato='+contrato+'&site='+site+'&departamento='+departamento+'&pat='+pat+'&numeroserie='+numeroSerie+'&modelo='+modelo+'&razaoSocial='+dadosUsuario.TB01008_NOME+'&tipo='+tipoRelatorio} target='_blank'>
                    <Button variant='contained' fullWidth style={{height :'65px'}}>
                        <Typography>Gerar Relatório</Typography>
                    </Button>
                </a>
                    
            </Grid>
            </div>
            

           
           
          
            
                    
        </div>
       
    </Principal>
}

export default withRouter(GestaoRelatorios)