

const GeradorLog = (dadosUsuario, tela = '') =>{
    const Conexao  = require('./Api/Api');
    const url = new URL(window.location.href);
    var sBrowser,
    sUsrAg = navigator.userAgent;
    console.log(sUsrAg)
    if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("MSIE") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } 
    if(dadosUsuario !=null && dadosUsuario.TB01066_CODCLI)
    Conexao.post('EmpresaController/gerarLogSiteV2', {
        codigoCliente : dadosUsuario.TB01066_CODCLI, 
        codigoEmpresa : url.hostname, 
        loginAcesso : dadosUsuario.TB01066_USUARIO, 
        nomeTela : tela =='' ? '[NAVIGATION]-['+window.location.href +']': tela, 
        dominio : url.hostname, 
        uuid : dadosUsuario.uuid, 
        navegador : sBrowser
    }).then(response=>{

    }).catch(error=>{

    })
}

module.exports = GeradorLog;