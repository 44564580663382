import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import DetalhesIcon from './ModalDetalhes'
import Swal from 'sweetalert2'
import ModalPedidoSuprimentos from './ModalPedidoSuprimento'
import ModalAberturaChamado from './ModalAberturaChamado'
import DisplayNivelToner from '../../components/DisplayNivelToner/DisplayNivelToner'
import ModalVideo from '../../components/base/ModalVideo';



let firstOpen = true


const EquipamentosLeiturasPendentes = function(props){
    const [page, setPage] = React.useState(0);
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)
    const [paginas, setPaginas]= useState(0)

    const [equipamentos, setEquipamentos] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [contatos, setContatos] = useState([])
    const dadosEmpresa = props.location.state.dadosEmpresa;

    const refTipoFiltro = useRef();
    const refFiltro = useRef();

    let chavePrinterconnect = dadosUsuario.TB01008_TOKEN ? dadosUsuario.TB01008_TOKEN.split('-') : '';

    React.useEffect(()=>{
        console.log(dadosUsuario)
        if(props.location.state.tipoFiltro){
            refTipoFiltro.current.value = props.location.state.tipoFiltro
            refFiltro.current.value = props.location.state.filtro
        }
        
    },[])
    React.useEffect(()=>{
        if(!firstOpen){
            getData();
            
        }        
    },[rowsPerPage, page])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        //alert(newPage)
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTimeout(()=>{
            getData()
        },500)
    };

    useEffect(()=>{
        getData('T')
        firstOpen=true
    },[])

    useEffect(()=>{
        
        setTimeout(()=>{
            getData('T')
        },500)
    },[page])


    const getData = async (optionDepartamento = 'F') =>{
        setLoading(true)
        Conexao.post('equipamentosController/getEquipamentosLeiturasPendentes?codigoEmpresa='+codigoEmpresa,{
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            codigoEmpresa : dadosUsuario.TB01066_CODCLI,
            equipamentos : dadosEmpresa.equipamento, 
            userName : dadosUsuario.TB01066_LOGIN, 
            userType :  dadosUsuario.userType,
            naoPermitidos : dadosUsuario.userEquipamentos == 'full' ? 'not' : '', 
            tipoFiltro : refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro : refFiltro.current ? refFiltro.current.value : '', 
            listaContratos : dadosUsuario.contratos.map(function(contrato){
                return contrato.numeroContrato
            }), 
            codigoEmpresa : codigoEmpresa,
            registros : rowsPerPage, 
            pagina : page + 1, 
            getDepartamentos : optionDepartamento

        }).then(response=>{
            if(response.status == 200 && response.data.status =='OK'){
                setEquipamentos(response.data.payload)
                setRegistros(response.data.payload.length)
         
                
            }else{
                setEquipamentos([])
                Swal.fire({
                    title : 'Corporate Web', 
                    text : 'Não existem informações para exibir!', 
                    icon : 'question', 
                    showConfirmButton:true, 
                    showCancelButton : false
                })
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error=>{
            setRegistros(0)
            setEquipamentos([])
            setLoading(false)
        })
    }


    return <Principal {...props} botaoAtivo = 'equipamentos' loading={loading}>
        <div className='container-children' >

            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosEmpresa
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Relação de Equipamentos com Leitura de Medidores pendente.</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}                                     
                    rowsPerPage={rowsPerPage}                     
                    labelRowsPerPage="Linhas por página:"                          
                    />                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'placa'>Nº de patrimônio</option>
                            <option value = 'numeroSerie'>Nº de série</option>
                            <option value = 'contrato'>Nº do Contrato</option>                                                        
                            <option value = 'modelo'>Modelo</option>
                            <option value = 'site'>Descrição Site</option>
                            <option value = 'departamento'>Departamento</option>
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData();
                            }
                        }}
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {2}>
                    <Button fullWidth  className='button-default' onClick={()=>getData()}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error'><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
            
            <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{width: '2%'}}>Pat</TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Número Série</TableCell>                        
                        <TableCell align='left' style={{width: '22%'}}>Modelo</TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Contrato</TableCell>
                        <TableCell align='center' colSpan={3}>Medidores</TableCell>                          
                        <TableCell align='center' style={{width: '4%'}}>Gravar</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>Limpar</TableCell>                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        equipamentos && equipamentos.map(function(equipamento, i){
                            return <TableRow key={i}>
                            <TableCell align='center' >{equipamento.TB02112_PAT && <Typography variant='caption'><span className='display-placa'>{equipamento.TB02112_PAT}</span></Typography>}</TableCell>
                            <TableCell align='center' ><Typography variant='caption'>{equipamento.TB02112_NUMSERIE}</Typography></TableCell>                            
                            <TableCell align='left' ><Typography variant='caption' className='texto-limitado'>{equipamento.TB01010_NOME}</Typography></TableCell>
                            <TableCell align='center' ><Typography variant='caption'>{equipamento.TB02112_CODIGO }</Typography></TableCell>
                            <TableCell align='left' >
                               <TextField
                                    variant='outlined'
                                    label='Contador (in)'
                                    defaultValue={0}
                                    fullWidth
                                    placeholder={'Informe o contador de páginas p&b'}
                                    size='small'/>                               
                            </TableCell>
                            <TableCell align='left' >
                                <TextField
                                 label='Contador (cm)'
                                    variant='outlined'
                                    placeholder={'Informe o contador de páginas color'}
                                    defaultValue={0}
                                    fullWidth
                                    size='small'/>                                
                            </TableCell>                                                   
                            <TableCell align='center' >
                                <TextField
                                    variant='outlined'
                                    label='Contador Mono'
                                    InputLabelProps={{shrink : true}}
                                    disabled
                                    defaultValue={0}
                                    placeholder={'Informe o contador de digitalização'}
                                    fullWidth
                                    size='small'/>
                            </TableCell>
                            <TableCell align='center' >
                                <Button className='button button-sucess'>
                                    <Typography variant='caption'>Gravar</Typography>
                                </Button>
                            </TableCell>
                            <TableCell align='center' >
                            <Button className='button button-error'>
                                    <Typography variant='caption'>Gravar</Typography>
                                </Button>
                            </TableCell>
                        </TableRow>
                        })
                    }
                    {
                        equipamentos && equipamentos.length == 0 && <TableRow >
                        <TableCell align='left' colSpan={8} >* Não há registros.</TableCell>
                        
                    </TableRow>
                    }
                </TableBody>
            </Table>      
        </div>

    </Principal>
}

export default withRouter(EquipamentosLeiturasPendentes)