import React , {useEffect} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {Toolbar, Tooltip, Divider, TextField, InputAdornment} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/SearchTwoTone';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertMessage from '../../components/base/Alert/Alert'
import Logout from './Logout/Logout'
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentIcon from '@material-ui/icons/Apps';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconeSolicitacoes from '@material-ui/icons/ShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import IconAlertToner from '@material-ui/icons/NotificationImportant'
import { SnackbarProvider, useSnackbar } from 'notistack';
import NotificationsTwoToneIcon from '@material-ui/icons/Notifications';
import AccountTree from '@material-ui/icons/AccountTree'
import IconeDashboard from '@material-ui/icons/PieChart'
import Pessoa from '@material-ui/icons/People'
import IconePrinterconnect from '@material-ui/icons/Usb'
import ReactShadowScroll from 'react-shadow-scroll';//https://www.npmjs.com/package/react-shadow-scroll
import RelatorioIcon from '@material-ui/icons/Assignment'
import ErrorIcon from '@material-ui/icons/Warning'
import AtolamentoIcon from '@material-ui/icons/Gesture'
import Swal from 'sweetalert2';
import IconSenha from '@material-ui/icons/VpnKey'
import DCAIcon from '@material-ui/icons/Dashboard'
import ModalVideo from '../../components/base/ModalVideo';
import IconQuestion from '@material-ui/icons/Help'
import {Link , useHistory } from 'react-router-dom'
import HelpIcon from '@material-ui/icons/Videocam'
import IconNivelToner from '@material-ui/icons/BarChart'
import EmailIcon from "@material-ui/icons/Mail"
import LeiturasIcon from '@material-ui/icons/Keyboard'
import IconeFeedback from '@material-ui/icons/Feedback'
import DialogFeedback from './DialogFeedback';
import Conexao from '../../providers/Api/Api'
import IconTelefone from '@material-ui/icons/Phone'
import GeradorLog from '../../providers/GeradorLog';
import PrinterconnectV2Api from '../../providers/Api/PrinterconnectV2Api';
import PaperIcon from '@material-ui/icons/Assignment'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  content :{
    backgroundColor : '#f6f7f8',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    backgroundColor:'#f5f7fa',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
let timer = null;
function Navbar(props) {

  

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const {printerconnect, codigoEmpresa }= props.location.state;
  const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
  const [nomeEmpresa, setNomeEmpresa] = React.useState(props.location.state.dadosEmpresa.TB01008_NOME);
  const logoEmpresa = props.location.state.logotipo
  const [userName, setUserName] = React.useState(props.location.state.dadosEmpresa.TB01066_LOGIN);
  const [userType, setUserType] = React.useState(props.location.state.dadosEmpresa.userType);
  const [showLogout, setShowLogout] = React.useState(false)
  const [suprimentos, setSuprimentos] = React.useState(0);
  const [chamados, setChamados] = React.useState(0);
  const [dataColetas, setDataColetas]= React.useState([]);

  useEffect(()=>{
    getEquipamentosCollector();
  }, [])
  

  const showMessage = (variant, msg) =>{
    enqueueSnackbar(msg, { variant, autoHideDuration:5000, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' } });

  }


  const getEquipamentosCollector = () =>{

    if(!dadosUsuario.TB01008_TOKEN){
        return;
    }
    if(!props.location.state.dataColetas){
      let chavePrinterconnect = dadosUsuario.TB01008_TOKEN.split('-');


      PrinterconnectV2Api.post('coletasController/getContadoresCorporateInterface?codigoEmpresa='+printerconnect, {
        idempresa : chavePrinterconnect[1] ? chavePrinterconnect[1] : -1,
        idcliente : chavePrinterconnect[2] ? chavePrinterconnect[2] : -1,       
      }).then(response =>{
        if(response.status == 200 && response.data.status =='OK'){
          setDataColetas(response.data.payload);
        }else{
          setDataColetas([])
        }
      }).catch(error =>{
          setDataColetas([])
      })
    }
    
  }




  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };



  const setLogout = () =>{
    gerarLogSite();
    setShowLogout(!showLogout)
  }
  const logout = () =>{
     
      window.location.href ='/'
  }

  const gerarLogSite = (tela ) =>{
    const url = new URL(window.location.href);        
    Conexao.post('equipamentosController/gerarLogSite', {
        codigoCliente : dadosUsuario.TB01066_CODCLI, 
        codigoEmpresa : url.hostname, 
        loginAcesso : dadosUsuario.TB01066_USUARIO, 
        nomeTela : tela ? tela : 'ATIVOU O LOGOFF/SAIR', 
        dominio : url.hostname, 
        uuid : dadosUsuario.uuid
    }).then(response=>{

    }).catch(error=>{

    })
}

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const url = new URL(window.location.href);
  const urlImagen = (url.host =='centermaq.net' || url.host == 'www.centermaq.net') ? 'centermaq.png' : 'uziprint.png'

  return (
      <div style ={{backgroundColor:'#fff', minHeight:'95vh'}}>
        <Logout setLogout = {setLogout} showLogout ={showLogout} logout = {logout} empresa = {nomeEmpresa}/>
    <div className={classes.grow}>
    {/*props.atualizar && <LinearProgress color="secondary" style ={{
        zIndex:"9999999", 
        position:"fixed", 
        top:"0px",
        left:"0px", 
    width:"100%"}}/>*/}
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
        
          {props.atualizar && <CircularProgress color="secondary" size={28} /> }
          <div style={{paddingLeft:'10px', paddingRight:'10px'}}>
           
           <Badge color='secondary' badgeContent={codigoEmpresa}>
           {<img src ={props.location.state.url+'img/'+urlImagen} width='90px'/>}
           </Badge>
          </div>
          <div style= {{display:'flex',  width:'100%'}}>
            <div style ={{width:'650px', }} className='text-limitado'>
              <Typography variant='subtitle1' className='text-limitado' style ={{ marginLeft:'10px', marginTop:'4px'}}>Empresa: <strong>{nomeEmpresa}</strong></Typography>
              <Typography variant='CAPTION' className='text-limitado' style ={{ marginLeft:'10px', marginTop:'4px'}}>Usuário conectado: <strong>{userName}</strong></Typography>
            </div>
              
            <div style ={{width:'200px', }}>
              <div style ={{marginTop :'-10px'}}>
              <Typography variant='caption' style={{fontSize:'10px',color: '#fff'}}>Digite seu serial/patrimônio aqui...</Typography><br/>
              
              <TextField className='input-search'  InputProps={{
                        startAdornment : <InputAdornment position='start'>
                          <ModalVideo iconSize={'28px'} color= '#FFC107' gerarLogSite={(op)=>GeradorLog(dadosUsuario,'[MODAL AJUDA]-[BOTÃO PESQUISA]')}>
                            <iframe 
                              width="900" 
                              height="500" 
                              src="https://www.youtube.com/embed/tjbRX4zucRI" 
                              title="Atalho para barra de pesquisa." 
                              frameborder="0" 
                              allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >                                  
                            </iframe>
                          </ModalVideo>
                          
                          </InputAdornment>
                    }} onKeyDown={(e)=>{
                      if(e.target.value != ''){
                        if(e.keyCode == 13){
                          props.history.push({
                            pathname : '/DetalhesEquipamentoBusca/'+e.target.value, state:{
                              ...props.location.state
                            }
                          })
                        }
                      }
                      
                    }}/>
                    </div>
            </div>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            
            

            <IconButton style ={{width:"50px", paddingTop : '0px'}}>
              <Tooltip title='Acesse aqui ajuda do sistema'><Link color="inherit" to={{pathname : "/ajuda", state:{
                ...props.location.state, 
                dataColetas : dataColetas
              }}} >
                  <HelpIcon style ={{color:'#FBC02D', fontSize :'36px',marginTop :'10px'}}  />
              </Link>
              </Tooltip>
       
             
              
              
            </IconButton>


            <IconButton aria-label="showLogout 4 new mails" color="inherit" style ={{width:"50px"}}>
              <Badge badgeContent={0} color="secondary">
                <MailIcon onClick={()=>{
                  showMessage('info', 'teste')
                }}/>
              
              </Badge>
              
              
            </IconButton>
            <Tooltip title={'Existe(m) '+chamados+' pendentes !'}>
            <IconButton aria-label="showLogout 4 new mails" color="inherit" style ={{width:"50px"}}>
              <Badge badgeContent={chamados} color="secondary">
                <BuildIcon />              
              </Badge>
            </IconButton>
            </Tooltip>




            <Tooltip title ={'Existe(m) ' + suprimentos +' alerta(s) de suprimentos aberto(s)' }>
            <IconButton aria-label="showLogout 17 new notifications" color="inherit" style ={{width:"50px"}}>
              <Badge badgeContent={0} color="secondary">
               
                <NotificationsTwoToneIcon />
              
              </Badge>
            </IconButton>
            </Tooltip>



           
          </div>
          <div className={classes.sectionMobile} style={{background:'#f5f7fa'}}>
            <IconButton
              aria-label="showLogout more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={()=>{alert('teste')}}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        
      </AppBar>
       
    </div>
    
            
 
      <ReactShadowScroll id='nav-main-left' scrollColor = 'transparent' scrollWidth={0} shadow={false}>
              { props.location.state.dadosEmpresa.TB01008_TOKEN && <React.Fragment><Tooltip title='Visualizar o dashboard ...'>
                  <div className={props.botaoAtivo != 'dashboard' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{                    
                     props.history.push({
                            pathname : '/dashboard/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                    <div>{props.loadingIcon ? <CircularProgress color='secondary' className='small-loading'/> : <IconeDashboard/>}</div>
                      <div><span className='rotulo-button-nav'>DASHBOARD</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              </React.Fragment>}
              <Tooltip title='Trocar sua senha'>
                  <div className={props.botaoAtivo != 'ajuda' ? 'div-button-nav' : 'div-button-nav-ativa'}  onClick={()=>{
                     props.history.push({
                            pathname : '/ajuda', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><HelpIcon/></div>
                      <span className='rotulo-button-nav'>Ajuda</span>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <DialogFeedback codigoCliente = {props.location.state.dadosEmpresa.TB01066_CODCLI} dadosEmpresa={props.location.state.dadosEmpresa}/>
              <Divider/>
              <Tooltip title='Trocar sua senha'>
                  <div className={props.botaoAtivo != 'trocar-senha' ? 'div-button-nav' : 'div-button-nav-ativa'}  onClick={()=>{
                     props.history.push({
                            pathname : '/trocarsenha/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconSenha/></div>
                      <span className='rotulo-button-nav'>Trocar Senha</span>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>


              <Tooltip title='Gerenciar os usuários com acesso ao portal'>
                  <div className={props.botaoAtivo != 'usuarios' ? 'div-button-nav' : 'div-button-nav-ativa'}  onClick={()=>{
                    if(userType == 'SUBUSER'){
                      Swal.fire({
                        title :'Corporate Web', 
                        text : 'Você não possui permissão para acessar este recurso', 
                        showConfirmButton : true,
                        showCancelButton: false, 
                        icon :'warning'
                      })
                      return;
                    }else{
                      props.history.push({
                              pathname : '/usuarios/empresa', state:{
                                ...props.location.state, 
                                dataColetas : dataColetas
                              }
                      })
                    }
                    
                  }}>
                    <div>
                      <div><Pessoa/></div>
                      <span className='rotulo-button-nav'>Usuários</span>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              
              <Tooltip title='Gerenciar os endereços de destino de notificação'>
                  <div className={props.botaoAtivo != 'emails-notificacao' ? 'div-button-nav' : 'div-button-nav-ativa'}  onClick={()=>{
                    if(userType == 'SUBUSER'){
                      Swal.fire({
                        title :'Corporate Web', 
                        text : 'Você não possui permissão para acessar este recurso', 
                        showConfirmButton : true,
                        showCancelButton: false, 
                        icon :'warning'
                      })
                      return;
                    }else{
                      props.history.push({
                              pathname : '/emails-notificacao', state:{
                                ...props.location.state, 
                                dataColetas : dataColetas
                              }
                      })
                    }
                    
                  }}>
                    <div>
                      <div><EmailIcon/></div>
                      <span className='rotulo-button-nav'>Email(s) Notificação</span>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              
             

              <Tooltip title='Consultar os contratos da empresa'>
                  <div className={props.botaoAtivo != 'contatos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                    props.history.push({
                            pathname : '/contratos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><RelatorioIcon/></div>
                      <div><span className='rotulo-button-nav'>Contratos</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Gerenciar os sites (locais de instalação) da empresa'>
                  <div className={props.botaoAtivo != 'sites' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                    props.history.push({
                            pathname : '/sites/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><AccountTree/></div>
                      <div><span className='rotulo-button-nav'>Locais</span></div>
                    </div>
                  </div>
              </Tooltip>

              <Divider/>
              <Tooltip title='Gerenciar os setores da empresa'>
                  <div className={props.botaoAtivo != 'setores' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                    props.history.push({
                            pathname : '/departamentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><AssignmentIcon/></div>
                      <div><span className='rotulo-button-nav'>Setores</span></div>
                    </div>
                  </div>
              </Tooltip>


              <Divider/>
              <Tooltip title='Visualizar seus equipamentos'>
                  <div className={props.botaoAtivo != 'equipamentos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                    props.history.push({
                            pathname : '/equipamentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><PrintIcon/></div>
                      <div><span className='rotulo-button-nav'>Equipamentos</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Solicitações de Suprimento aguardando...'>
                  <div className={props.botaoAtivo != 'solicitacoes-suprimentos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/solicitacoes-suprimentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><AddShoppingCartIcon/></div>
                      <div><span className='rotulo-button-nav'>Solicitações Toner</span></div>
                    </div>
                  </div>
              </Tooltip>

              <Divider/>
              <Tooltip title='Gestão de pedidos de suprimento'>
                  <div className={props.botaoAtivo != 'suprimentos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/suprimentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconeSolicitacoes/></div>
                      <div><span className='rotulo-button-nav'>Requisições Toner</span></div>
                    </div>
                  </div>
              </Tooltip>

              <Divider/>
              <Tooltip title='Solicitações de Suprimento aguardando...'>
                  <div className={props.botaoAtivo != 'solicitacoes-chamado' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/solicitacoes-chamado/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconTelefone/></div>
                      <div><span className='rotulo-button-nav'>Solicitação Chamado</span></div>
                    </div>
                  </div>
              </Tooltip>

              <Divider/>
              <Tooltip title='Gestão de abertura de ordens de serviço'>
                  <div className={props.botaoAtivo != 'chamados' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/chamados/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><BuildIcon/></div>
                      <div><span className='rotulo-button-nav'>Chamados Abertos</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>

              <Tooltip title='Relatórios'>
                  <div className={props.botaoAtivo != 'relatorios' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/relatorios/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Informar Leituras de Medidores'>
                
                  <div className={props.botaoAtivo != 'leituras' ? 'div-button-nav-disabled' : 'div-button-nav-ativa'} onClick={()=>{
                    return
                    props.history.push({
                            pathname : '/leituras/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><LeiturasIcon/></div>
                      <div><span className='rotulo-button-nav'>Leituras Pendentes</span></div>
                    </div>
                  </div>
              </Tooltip>
              { props.location.state.dadosEmpresa.TB01008_TOKEN && 
              <React.Fragment>
              <Divider/>
              <Tooltip title='Chaves de instalação criadas'>
                  <div className={props.botaoAtivo != 'dcas' ? 'div-button-nav-disabled' : 'div-button-nav-ativa'} onClick={()=>{
                     /*props.history.push({
                            pathname : '/dcas/empresa', state:{
                              dadosEmpresa : props.location.state.dadosEmpresa, 
                              printerconnect : props.location.state.printerconnect, 
                              codigoEmpresa : props.location.state.codigoEmpresa, 
                              logotipo : props.location.state.logotipo
                            }
                    })*/
                  }}>
                    <div>
                      <div><DCAIcon/></div>
                      <div><span className='rotulo-button-nav'>DCA`s</span></div>
                    </div>
                  </div>
              </Tooltip>


              <Divider/>
              <Tooltip title='Monitoramento do parque de equipamentos (Printerconnect)'>
                  <div className={props.botaoAtivo != 'monitoramento' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/monitoramento/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconePrinterconnect/></div>
                      <div><span className='rotulo-button-nav'>Monitoramento</span></div>
                    </div>
                  </div>
              </Tooltip>
              
              <Divider/>

              <Tooltip title='Equipamento com baixo nível de toner'>
                  <div className={props.botaoAtivo != 'níveis-toner' ? 'div-button-nav-disabled'  : 'div-button-nav-ativa'} onClick={()=>{
                    return;
                     props.history.push({
                            pathname : '/niveistoner/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconNivelToner/></div>
                      <div><span className='rotulo-button-nav'>Níveis Toner</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>


              <Tooltip title='Relatórios de erros'>
                  <div className={props.botaoAtivo != 'erros' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/erros/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><ErrorIcon/></div>
                      <div><span className='rotulo-button-nav'>Erros</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Alteras de suprimento baixo gerados, aguardando importação automática'>
                  <div className={props.botaoAtivo != 'alertas-suprimentos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/alertas-suprimentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><IconAlertToner/></div>
                      <div><span className='rotulo-button-nav'>Alertas Toner</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Relatório de atolamentos'>
                  <div className={props.botaoAtivo != 'atolamentos' ? 'div-button-nav' : 'div-button-nav-ativa'} onClick={()=>{
                     props.history.push({
                            pathname : '/atolamentos/empresa', state:{
                              ...props.location.state, 
                              dataColetas : dataColetas
                            }
                    })
                  }}>
                    <div>
                      <div><AtolamentoIcon/></div>
                      <div><span className='rotulo-button-nav'>Atolamentos</span></div>
                    </div>
                  </div>
              </Tooltip>
              
              <Divider/>
              </React.Fragment>}
              {/*<Tooltip title='Relatórios diversos'>
                  <div className={props.botaoAtivo != 'monitoramento' ? 'div-button-nav' : 'div-button-nav-ativa'}>
                    <div>
                      <div><RelatorioIcon/></div>
                      <div><span className='rotulo-button-nav'>Contratos</span></div>
                    </div>
                  </div>
              </Tooltip>
              <Divider/>
              <Tooltip title='Consulta financeira'>
                  <div className={props.botaoAtivo != 'monitoramento' ? 'div-button-nav' : 'div-button-nav-ativa'}>
                    <div>
                      <div><IconeFinanceiro/></div>
                      <div><span className='rotulo-button-nav'>Financeiro</span></div>
                    </div>
                  </div>
                </Tooltip>*/}
                <Divider/>
                <Tooltip title='Sair do Sistema'>
                  <div className={props.botaoAtivo != 'sair' ? 'div-button-nav-sair' : 'div-button-nav-ativa'} onClick={()=>{
                     Swal.fire({
                      title : 'Corporate Web', 
                      text : 'Deseja realmente sair?', 
                      icon : 'question', 
                      showCancelButton : true, 
                      cancelButtonText : 'Não', 
                      showConfirmButton : true, 
                      confirmButtonText : 'Sim',
                      backdrop :"rgba(255, 0, 0, 0.2)"
                     
                     }).then(resposta=>{
                      if(resposta.isConfirmed){
                        gerarLogSite();
                        props.history.push({
                          pathname : '/'
                        })
                      }
                     })
                  }}>
                    <div>
                      <div><CloseIcon/></div>
                      <div><span className='rotulo-button-nav'>SAIR</span></div>
                    </div>
                  </div>
              </Tooltip>
              <br/>
              <br/>
              <br/>
     </ReactShadowScroll>
      
    <main id={props.hideToolar ? 'div-main-container2'  : 'div-main-container'}>

    <AlertMessage 
        activeMessage = {props.loading} 
        titleMessage ={props.message ? props.message : 'Aguarde...carregando dados'} 
        typeMessage ={'warning'} />
        
      {props.children }
      
    </main>
    {/*<Backdrop className={classes.backdrop} open={props.loading} onClick={null} style ={{zIndex:"9999998"}}>
      <CircularProgress color="secondary" />
                </Backdrop>*/}
   
      
    
    </div>

  );
}

export default function IntegrationNotistack(props) {
  return (
    <SnackbarProvider maxSnack={10}>
      <Navbar {...props}/>
      
    </SnackbarProvider>
  );
}
