import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetalhesIcon from '@material-ui/icons/FormatListBulleted'
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography , Fab, Badge, InputAdornment, CircularProgress} from '@material-ui/core';
import NivelToner from './NivelToner'
import Swal from 'sweetalert2';
import Conexao from '../../../providers/Api/PrinterconnectV2Api'
import GraficoToner from './V2/GraficoUsoToner'




export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);




 
/*

printerConnectCliente: "3937"
​
printerConnectEmpresa: "2"
​
printerConnectVersao: 2

*/




  const handleClickOpen = () => {      
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };


//getNotasFiscaisContrato
  return (
    <div style={{textAlign:'center', }} >
        
        
      <NivelToner corToner={props.corToner} 
        color= {props.corToner}  
        descricao={props.descricao} 
        lineSize={6}
        contador={props.contador}
        percentual={props.percentual} showButton={true} height = {props.sizeDisplay ?props.sizeDisplay  : 120 } width={props.sizeDisplay ?props.sizeDisplay  : 120 } click={handleClickOpen}/>
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'
      >
          <DialogTitle id="alert-dialog-title">Evolução do consumo de toner</DialogTitle>
        <DialogContent dividers>         
            <GraficoToner serial={props.numeroSerie} cor={props.cor} preenchimento={props.preenchimento}/>
        </DialogContent>
        <DialogActions>
            <Button variant='outlined' color='secondary' onClick={()=>{
                setOpen(false)
            }}>Cancelar</Button>
            
        </DialogActions>
      </Dialog>
    </div>
  );
}
