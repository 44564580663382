import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import NivelToner from '../../components/DisplayNivelToner/DisplayNivelToner'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalDefeitoChamado from './ModalDefeitoChamado';
import ModalVideo from '../../components/base/ModalVideo';
import gerarEventoSite from '../../providers/GeradorLog';
import PrintIcon from '@material-ui/icons/Print'


const Chamados = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [chamados, setChamados] = React.useState([]);


    const dadosEmpresa = props.location.state.dadosEmpresa;

    const refDataInicial =useRef();
    const refDataFinal = useRef();
    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // gerarLogSite();
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)

    const gerarLogSite = (tela = 'CHAMADOS') =>{
        gerarEventoSite(dadosUsuario);
    }

    const getData = () =>{
        setLoading(true)
        Conexao.post('chamadosController/getOdensServicoCorporate?empresa='+props.match.params.empresa, {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            codigoEmpresa : codigoEmpresa,
            dataInicial : refDataInicial.current ?refDataInicial.current.value : '', 
            dataFinal :  refDataFinal.current ? refDataFinal.current.value : '', 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            pagina : page + 1
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setChamados(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setChamados([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error=>{
            setChamados([])
            setRegistros(0)
            setLoading(false)
        })
    }

    const renderRotulo = (cor)=>{
        switch(cor){
            case 'CIANO': return '#4FC3F7';
            case 'CIAN': return '#4FC3F7';
            case 'MAGENTA': return '#F50057';
            case 'AMARELO': return '#FDD835';
            case 'PRETO': return '#000';
        }
    }

    const renderStatusIcon = (status,descricao)=>{

        switch(status){
            case 1:return <span className='labelDefault'>ANDAMENTO</span>
            case 2:return <span className='labelSucess'>{descricao}</span>
            case 3:return <span className='labelSucess'>{descricao}</span>
            case 23:return <span className='labelPreSucess'>{descricao}</span>
            case 7:return <span className='labelInfo'>{descricao}</span>
            case 22:return <span className='labelInfo'>{descricao}</span>
            case 24:return <span className='labelInfo'>{descricao}</span>
            case 25:return <span className='laberlWarning'>{descricao}</span>
            case 26:return <span className='laberlWarning'>{descricao}</span>
            default:return <span className='labelDefault'>{descricao}</span>; 
        }
       
    }


    const renderIcone = (valor,) =>{
        switch(valor){
            case 'PCONNECT':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>PRINTERCONNECT</div>
            case 'ALERTA':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>ALERTA</div>
            case 'MANUAL':
                return <div  style={{fontSize:"9px"}}><CallTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>MANUAL</div>
            case 'SITE':
                return <div  style={{fontSize:"9px"}}><LaptopChromebookTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>SITE</div>
            case 'INSTALAÇÃO':
                return <div  style={{fontSize:"9px"}}><DescriptionTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>INSTALAÇÃO</div>
            case 'REPOSICAO':
            return <div  style={{fontSize:"9px"}}><ThreeSixtyTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>REPOSIÇÃO</div>
            
            
        }
    }

    const renderTipoIntervencao = (tipo, atendente) =>{
        switch(tipo){
            case 'N':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px', paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px', background: '#fff', color:'#000', border:'solid 1px #000'}}>ORDEM SERVIÇO</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div>
            case 'I':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px', paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px',  background: '#fff', color:'green', border:'solid 1px green'}}>INSTALAÇÃO</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div>

            case 'D':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px', paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px',  background: '#fff', color:'#f00', border:'solid 1px #f00'}}>RETIRADA</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div> 
            case 'S':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px' , paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px',  background: '#fff', color:'blue', border:'solid 1px blue'}}>PREVENTIVA</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div> 
            case 'R':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px' , paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px',  background: '#fff', color:'#000', border:'solid 1px #000'}}>RETORNO</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div> 
            case 'A':
                return <div>
                    <Typography variant='caption' style={{fontSize:'10px' , paddingLeft:'5px', paddingRight : '5px', borderRadius: '3px',  background: '#fff', color:'#000', border:'solid 1px #000'}}>AFERIÇÃO</Typography><br/>
                    <Typography variant='caption' style={{fontSize:'8px'}}>{atendente == 'CORPORATE WEB'? 'SITE' : atendente}</Typography>
                </div> 
        }
    }

    return <Principal {...props} botaoAtivo = 'chamados' loading={loading}>
        <div className='container-children'>
                <ModalVideo 
                    urlVideo = ''>
                        <iframe 
                            width="900" 
                            height="500" 
                            src="https://www.youtube.com/embed/jLWpovBGVd0?list=PLiKQeSFs5NZtaqx23PK8_I1Cglf7b2gNo" 
                            title="Como abrir uma Ordem de Serviço" 
                            frameborder="0" 
                            allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                                
                        </iframe>

                        
                </ModalVideo>
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosEmpresa
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Ordens de Serviço</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}  
                    labelRowsPerPage="Linhas por página:"               
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Incial'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataInicial}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Final'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        type={'date'}
                        inputRef={refDataFinal}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'numero'>Chamado</option>
                            <option value = 'placa'>Nº de patrimônio</option>
                            <option value = 'numeroSerie'>Nº de série</option>                                                        
                            <option value = 'modelo'>Modelo</option>
                            <option value = 'setor'>Setor</option>
                            <option value = 'solicitante'>Solicitante</option>
                    </TextField>
                </Grid>
                <Grid item xs = {4}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData();
                            }
                        }}
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'numero'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                    <TableHead>
                        <TableCell align='center' style={{width: '3%'}}>Chamado</TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Tipo </TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Data Abertura</TableCell>
                        <TableCell align='center' style={{width: '5%'}}>Pat</TableCell>
                        <TableCell align='left' style={{width: '20%'}}>Equipamento</TableCell>
                        <TableCell align='left' style={{width: '20%'}}>Defeito</TableCell>
                        <TableCell align='center' style={{width: '10%'}}>Setor</TableCell>
                        <TableCell align='center' style={{width: '6%'}}>Solicitante</TableCell>
                        <TableCell align='center' style={{width: '5%'}}>Detalhes</TableCell>
                       
                        <TableCell align='center' style={{width: '10%'}}>Status</TableCell>
                      
                        
                    </TableHead>
                    <TableBody>
                        {
                            chamados && chamados.map(function(chamado, i){
                                return <TableRow key ={i}>
                                <TableCell align='center' ><Typography variant='caption'><strong>{chamado.TB02115_CODIGO}</strong></Typography></TableCell>
                                <TableCell align='center' >
                                    {
                                        renderTipoIntervencao(chamado.TB02115_PREVENTIVA, chamado.TB02115_ATENDENTE)
                                    }
                                </TableCell>
                                <TableCell align='center'><Typography variant='caption' style ={{fontSize:'10px'}} color='primary'>{chamado.TB02115_DTCAD}</Typography>{renderIcone(chamado.TIPO)}</TableCell>
                                <TableCell align='center'><Typography variant='caption'><span className='display-placa'>{chamado.TB02112_PAT}</span></Typography></TableCell>                        
                                <TableCell align='left'>
                                <Typography variant='subtitle1' style={{fontSize:'12px'}} color='primary'>{chamado.TB01010_NOME}</Typography>
                                <Typography variant='subtitle1' style={{fontSize:'10px'}}>{chamado.TB02115_NUMSERIE}</Typography>
                                </TableCell>
                                <TableCell align='left'><Typography variant='caption'>{chamado.TB02115_NOME}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption'>{chamado.TB02112_LOCAL}</Typography></TableCell>
                                <TableCell align='center' ><Typography variant='caption' style={{fontSize :'9px'}}>{chamado.TB02115_CONTATO != '0' ? chamado.TB02115_CONTATO : ''}</Typography></TableCell>
                                <TableCell align='center' >
                                    {chamado.TB02115_OBS && chamado.TB02115_OBS != '' && <ModalDefeitoChamado titulo='Detalhes do Chamado técnico' numeroChamado={chamado.TB02115_CODIGO} codigoEmpresa={chamado.TB02115_CODEMP} texto={"Defeito Informado: " + chamado.TB02115_OBS}/>}
                                </TableCell>

                                
                                <TableCell align='center'>
                                    {
                                        chamado.TB02122_CODIGO && chamado.TB02122_CODIGO != '' ?
                                        <ModalDefeitoChamado 
                                        titulo="Informações de Fechamento"
                                        numeroChamado={chamado.TB02115_CODIGO} tituloBotao={chamado.TB01073_NOME} 
                                        codigoEmpresa={chamado.TB02115_CODEMP} texto={'Solução técnica: ' + chamado.TB02122_OBS}/> 
                                        :
                                        <Typography variant='caption' style={{fontSize :'10px'}}>{chamado.TB01073_NOME}</Typography>
                                    }
                                    
                                   
                                </TableCell>
                               
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>      
        </div>
        <div className='botaoAcoes'>
        <div>
            <div style={{paddingBottom : '5p´x'}}>
            <Tooltip title ='Abrir uma nova Ordem de Serviço'>
                <Fab color="primary" aria-label="add"   elevation={10} onClick={()=>{
                        props.history.push({
                            pathname : '/equipamentos/empresa', state:{
                                ...props.location.state
                            }
                        })
                    }}>
                    <AddIcon />
                </Fab>
            </Tooltip> 
            </div>
            <div><Tooltip title ='Relatório de Ordens de Serviço'>
                <Fab color="secondary" aria-label="add" style={{marginTop :'5px'}}   elevation={10} onClick={()=>{
                        props.history.push({
                            pathname : '/relatorios/empresa', state:{
                                ...props.location.state
                            }
                        })
                    }}>
                    <PrintIcon />
                </Fab>
            </Tooltip> </div>
        </div>
        
                          
        </div>
    </Principal>
}

export default withRouter(Chamados)