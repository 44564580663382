import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell , Badge} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import PrinterconnectV2 from '../../providers/Api/PrinterconnectV2Api'
import PrinterconnectV1 from '../../providers/Api/PrinterconnectV1Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import USBIcon from '@material-ui/icons/Print'
import PowerOn from '@material-ui/icons/PowerSettingsNew'
import PowerSaving from '@material-ui/icons/Notifications'
import PowerOff from '@material-ui/icons/PowerOff'
import Swal from 'sweetalert2'
import WarningIcon from '@material-ui/icons/Warning'
import { Chart } from "react-google-charts";
import {Alert, AlertTitle} from '@material-ui/lab'
import GraficoPiza from './GraficoPiza'
import PrintDisabled from '@material-ui/icons/PrintDisabled'
import DisplayToner from '../../components/DisplayNivelToner/DisplayNivelToner'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import IconSort from '@material-ui/icons/Sort'


let data = [];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];








var firstOpen = true



export const options = {
    title: "Coletores de Dados Instalados",
  };


const Dashboard = function(props){
    
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const [loading, setLoading]= React.useState(false);

    const [alertas, setAlertas]= React.useState([]);
    const [dcas, setDcas]= React.useState([]);
    const [equipamentos, setEquipamentos]= React.useState([]);
    const [suprimentos, setSuprimentos]= React.useState([]);
    const [coletas, setColetas]= React.useState([]);
    const [consumiveis, setConsumiveis]= React.useState([]);
    const [pronto, setPronto]= React.useState(0);
    const [atencao, setAtencao]= React.useState(0);
    const [alerta, setAlerta]= React.useState(0);
    const [offLine, setOffLine]= React.useState(0);
    const [total, setTotal]= React.useState(0);
    const dadosEmpresa = props.location.state.dadosEmpresa;
    const [totalDispositivos, setTotalDispositivos]= React.useState(0);

    const dadosPrinterconnect = dadosUsuario.TB01008_TOKEN ? dadosUsuario.TB01008_TOKEN.split('-') : []
    
    
    React.useEffect(()=>{
        
        getData();
        
        getUltimosAlertasDashboard();
        getAlertaTonerSite()
        getEquipamentosSemColetaOnclick();
        getDcasDashboardChart();
        getAlertasPartsAtivos();
        getEquipamentosNivelTonerBaixo();
        const timer = setInterval(()=>{
            getData();      
            getUltimosAlertasDashboard();
            getAlertaTonerSite()
            //getDcasDashboardChart();
            getAlertasPartsAtivos();
        }, 240000)

        return ()=>{
            clearInterval(timer)
        }
    },[])


  





      

    const getData =  () =>{
        setLoading(true)
        PrinterconnectV2.post('coletasController/getPrintersDashboardPainel?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){               
               
                setPronto(response.data.payload.filter(item => item.status=='1').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setAtencao(response.data.payload.filter(item => item.status=='2').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setAlerta(response.data.payload.filter(item => item.status=='3').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setOffLine(response.data.payload.filter(item => item.status=='4').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
         
               setTotal( response.data.payload.reduce(function(a, b,){
                return a + b.total
             }, 0))
               
             
            }else{
              
            }
            setLoading(false)
        }).catch(error=>{
         
           
            setLoading(false)
        })
    }

    const getDcasDashboardChart =  () =>{
        data = []
        setLoading(true)
        PrinterconnectV2.get('dashboardController/getDataGraficoDCAs?idEmpresa='+dadosPrinterconnect[1]+'&idCliente='+dadosPrinterconnect[2],{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){      
                
              data = response.data.payload 
                  //data = teste          
            }else{
                data = [];
            }
            setLoading(false)
        }).catch(error=>{
            data = [];
            setLoading(false)
        })
    }

    const getUltimosAlertasDashboard = () =>{
        PrinterconnectV2.post('coletasController/getUltimosAlertasDashboard?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
                setAlertas(response.data.payload)
            
            }else{
                setAlertas([])
            }
            setLoading(false)
        }).catch(error=>{
            setAlertas([])
            setLoading(false)
        })
    }


    const getAlertaTonerSite = () =>{
        PrinterconnectV2.post('coletasController/getAlertaTonerSite?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
            nivel : 25
        }).then(response=>{
            if(response.data.status =='OK'){
                setSuprimentos(response.data.payload)
            
            }else{
                setSuprimentos([])
            }
            setLoading(false)
        }).catch(error=>{
            setSuprimentos([])
            setLoading(false)
        })
    }

    const getEquipamentosNivelTonerBaixo = () =>{
        PrinterconnectV2.post('coletasController/getEquipamentosNivelTonerBaixo?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
            nivel : 25
        }).then(response=>{
            if(response.data.status =='OK'){

                const temp = [];

                
                for(let i = 0; i < response.data.payload.length; i++){
                    if(response.data.payload[i].nivelpreto != null && response.data.payload[i].nivelpreto <= 25){
                        temp.push({
                            nivelToner : response.data.payload[i].nivelpreto, 
                            cor : 'PRETO', 
                            fontColor: '#000', 
                            border : '#444',
                            numeroserie : response.data.payload[i].numeroserie, 
                            marca : response.data.payload[i].marca, 
                            modelo: response.data.payload[i].modelo, 
                            enderecoip : response.data.payload[i].enderecoip
                        })
                    }
                    if(response.data.payload[i].nivelciano != null && response.data.payload[i].nivelciano <= 25){
                        temp.push({
                            nivelToner : response.data.payload[i].nivelciano, 
                            cor : 'CIANO', 
                            fontColor: '#283593',
                            border : '#283593',
                            numeroserie : response.data.payload[i].numeroserie, 
                            marca : response.data.payload[i].marca, 
                            modelo: response.data.payload[i].modelo, 
                            enderecoip : response.data.payload[i].enderecoip
                        })
                    }
                    if(response.data.payload[i].nivelmagenta != null && response.data.payload[i].nivelmagenta <= 25){
                        temp.push({
                            nivelToner : response.data.payload[i].nivelmagenta, 
                            cor : 'MAGENTA', 
                            fontColor: '#B71C1C',
                            border : '#B71C1C',
                            numeroserie : response.data.payload[i].numeroserie, 
                            marca : response.data.payload[i].marca, 
                            modelo: response.data.payload[i].modelo, 
                            enderecoip : response.data.payload[i].enderecoip
                        })
                    }

                    if(response.data.payload[i].nivelamarelo != null && response.data.payload[i].nivelamarelo <= 25){
                        temp.push({
                            nivelToner : response.data.payload[i].nivelamarelo, 
                            cor : 'AMARELO', 
                            border : '#F57F17',
                            fontColor: '#F57F17',
                            numeroserie : response.data.payload[i].numeroserie, 
                            marca : response.data.payload[i].marca, 
                            modelo: response.data.payload[i].modelo, 
                            enderecoip : response.data.payload[i].enderecoip
                        })
                    }
                }
                setColetas(temp.sort((a, b)=> a.nivelToner - b.nivelToner))
            
            }else{
                setColetas([])
            }
            setLoading(false)
        }).catch(error=>{
            setColetas([])
            setLoading(false)
        })
    }


    
    const getEquipamentosSemColetaOnclick = () =>{
        PrinterconnectV2.post('coletasController/getEquipamentosSemColetaOnclick', {
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
                setEquipamentos(response.data.payload)
                
            }else{
                setEquipamentos([])
            }
            setLoading(false)
        }).catch(error=>{
            
            setEquipamentos([])
            setLoading(false)
        })
    }

    const getAlertasPartsAtivos = () =>{
        PrinterconnectV2.post('coletasController/getAlertasPartsAtivos', {
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
           
                setConsumiveis(response.data.payload)
               
                
            }else{
                setConsumiveis([])
            }
            setLoading(false)
        }).catch(error=>{
        
            setConsumiveis([])
            setLoading(false)
        })
    }
      

 const renderSeverity = (codigo) =>{
    if(codigo == 1){
        return 'info'
    }
    if(codigo > 1 && codigo < 3 ){
        return 'warning'
    }
    if(codigo > 3){
        return 'error'
    }
    
 }

 const renderCor = (cor) =>{
     switch(cor){
       case 'CIANO': return '#1565C0';
       case 'MAGENTA': return '#AD1457';
       case 'AMARELO': return '#FF8F00';
       case 'PRETO': return '#000000';
     }
 }
 const COLORS = ['#26A69A', '#EF5350', '#FFBB28', '#FF8042'];

 const RADIAN = Math.PI / 180;
 const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
   const x = cx + radius * Math.cos(-midAngle * RADIAN);
   const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
   return (
     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
       {`${(percent * 100).toFixed(0)}%`}
     </text>
   );
 };


    return <div className='container-children' id='dash2'>
        
            <div style ={{display :'flex', }}>
            
                <div style ={{width:'15%', height:'80px'}}>
                <div style= {{width:'100%',textAlign:'left'}}>
                <Typography variant='subtitle2' color='primary'><strong>DCA's Instalados</strong></Typography>
                </div>
                          {data && data.length > 0 &&  <ResponsiveContainer width="100%" height="100%">
                                                                <PieChart width={100} height={100}>
                                                                <Pie
                                                                    data={data}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={40}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer> }
                        </div>

                    <div style={{width:'85%'}}>
                    <Typography variant='subtitle2' color='primary'><strong>Equipamentos monitorados</strong></Typography>
                    
                    <div id='half-definition' className='container-cards-dashboard-dca'>                                                         
                        <div className='container-cards-dashboard-dca-item gradiente-info'>
                            <div className='container-cards-dashboard-dca-item-icon'>
                                <USBIcon color='#fff' style ={{color:'#fff'}}/>
                            </div>
                            <div className='container-cards-dashboard-dca-item-area'>
                                <Typography variant='subtitle2' style= {{color:'#fff'}} className='text-align'>Total</Typography>
                                <Typography variant='h5' style= {{color:'#fff'}}><strong>{total}</strong></Typography>
                            </div>
                        </div>
                        <div className='container-cards-dashboard-dca-item gradiente-success'>
                            <div className='container-cards-dashboard-dca-item-icon'>
                                <PowerOn color='#fff' style ={{color:'#fff'}}/>
                            </div>
                            <div className='container-cards-dashboard-dca-item-area'>
                                <Typography variant='subtitle2' style= {{color:'#fff'}}>Online</Typography>
                                <Typography variant='h5' style= {{color:'#fff'}}><strong>{pronto}</strong></Typography>
                            </div>
                        </div>
                        <div className='container-cards-dashboard-dca-item gradiente-warning'>
                            <div className='container-cards-dashboard-dca-item-icon'>
                                <PowerSaving color='#fff' style ={{color:'#fff'}}/>
                            </div>
                            <div className='container-cards-dashboard-dca-item-area'>
                                <Typography variant='subtitle2' style= {{color:'#fff'}}>Atenção</Typography>
                                <Typography variant='h5' style= {{color:'#fff'}}><strong>{atencao}</strong></Typography>
                            </div>
                        </div>
                        <div className='container-cards-dashboard-dca-item gradiente-error'>
                            <div className='container-cards-dashboard-dca-item-icon'>
                                <WarningIcon color='#fff' style ={{color:'#fff'}}/>
                            </div>
                            <div className='container-cards-dashboard-dca-item-area'>
                                <Typography variant='subtitle2' style= {{color:'#fff'}}>Alertas</Typography>
                                <Typography variant='h5' style= {{color:'#fff'}}><strong>{alerta}</strong></Typography>
                            </div>
                        </div>
                        <div className='container-cards-dashboard-dca-item gradiente-default'>
                            <div className='container-cards-dashboard-dca-item-icon'>
                                <PowerOff color='#fff' style ={{color:'#fff'}}/>
                            </div>
                            <div className='container-cards-dashboard-dca-item-area'>
                                <Typography variant='subtitle2' style= {{color:'#fff'}}>Offline</Typography>
                                <Typography variant='h4' style= {{color:'#fff'}}><strong>{offLine}</strong></Typography>
                            </div>
                        </div>
                        <div className='container-cards-dashboard-dca-item '>
                            <Button color='primary' variant='outlined' onClick={()=>{
                                props.history.push({pathname:'/home/empresa', state :{
                                    ...props.location.state
                                }})
                            }}><IconSort color='primary' className='color-default'/></Button>
                        </div>
                    </div>
                </div>
            </div>

    
            <Typography variant='subtitle2' style= {{ marginTop : '20px'}}><strong>Equipamentos com toner abaixo de 25%</strong></Typography>
                    <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                        
                        {
                            coletas  && coletas.map(function(coleta, i){
                                return <div style ={{fontSize:'10px', border:'none!important', padding:'4px', paddingLeft :'2px', backgroundColor:'#f5f5f5',  borderRadius :'3px', width:'12%',  marginLeft : '2px', marginTop :'2px', }}>
                                        <div variant='outlined'className='no-border'   style={{display:'flex'}}>
                                            <div style = {{width :'35%', color:'#B71C1C', display:'flex', alignContent:'center', justifyContent:'center', justifyItems:'center', alignItems:'center'}}>
                                            <DisplayToner width={32} height={32} color={renderCor(coleta.cor)}  percentual = {coleta.nivelToner}/>
                                            </div>
                                            <div style = {{width :'65%', color:coleta.fontColor, paddingLeft :'2px'}} className='texto-limitado'>
                                            <span style={{fontSize:'10px', color:coleta.fontColor}}>{coleta.modelo}</span><br/>
                                            <span style={{fontSize:'10px', color:coleta.fontColor}}><strong>{coleta.numeroserie}</strong></span><br/>
                                            <span style={{fontSize:'10px', color:coleta.fontColor}}><strong>{coleta.enderecoip}</strong></span>
                                            </div>
                                    
                                        </div>
                                        
                                    </div>
                            })
                        }
                </div>
                
           
            <div className='container-sub-cards' style={{marginTop:'10px'}}>
                
                <div style ={{width:'100%', marginTop :'20px!important', paddingTop:'20px!important'}}>
                <Typography variant='subtitle2' style={{cursor:'pointer',}} onClick={()=>{
                     props.history.push({
                        pathname : '/dashboard-toner/empresa', state:{
                           ...props.location.state
                        }
                      })
                }}><strong>Alertas de Suprimento (click aqui para acessar)</strong></Typography>
                <div style ={{minHeight:'40px', display:'flex', flexWrap :'wrap'}}>
                    {
                    suprimentos && suprimentos.map(function(suprimento, i){
                        return  <div className= {'div-alert-toner-dash ' +'rotulo-'+suprimento.cor}>
                        <div style ={{display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', paddingLeft:'4px', paddingRight:'4px'}}>                            
                            <DisplayToner width={38} height={38} color={renderCor(suprimento.cor)}  percentual = {suprimento.nivelabertura}/>                            
                        </div>
                        <div style={{width:'100%', paddingTop: '0px', paddingBottom : '0px', paddingRight:'5px'}} id='divLinhas' className='texto-limitado'>
                                <div>
                                    <span style ={{fontSize:'10px', }}>{suprimento.modelo}</span>
                                </div>
                                <div>
                                    <span style ={{fontSize:'9px', marginTop:'-20px!important', }}><strong>{suprimento.numeroserie}</strong> </span>
                                </div>
                                <div>
                                <span style ={{fontSize:'9px', }}>IP:{suprimento.enderecoip}</span>
                                </div>
                               
                               
                         
                        </div>
                    </div>
                    })
                }
                {
                    suprimentos && suprimentos.length == 0 && <span style= {{fontSize:'8px'}}>* não existe(m) informações sobre alertas de suprimento(s)</span>
                }
                </div>
                
                </div>
            </div>
            <Divider style={{marginTop : '5px'}}/>
            <Typography variant='subtitle2' style= {{ marginTop : '20px'}}><strong>Alertas de Consumiveis</strong></Typography>
                    <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                        
                        {
                            consumiveis  && consumiveis.map(function(consumivel, i){
                                return <div style ={{fontSize:'10px', border:'none!important', padding:'5px',borderRadius :'3px', width:'12%', backgroundColor:'#f5f5f5', marginLeft : '5px', marginTop :'5px'}}>
                                        <div variant='outlined'className='no-border'  severity={consumivel.DATACOLETA ? 'warning' : 'error'} style={{display:'flex'}}>
                                            <div style = {{width :'25%', color:'#B71C1C'}}><WarningIcon style={{color: '#B71C1C'}}/></div>
                                            <div style = {{width :'75%', color:'#B71C1C'}} className='texto-limitado'>
                                            <span style={{fontSize:'10px', color:'#B71C1C'}}>{consumivel.modelo}</span><br/>
                                            <span style={{fontSize:'10px', color:'#B71C1C'}}><strong>{consumivel.numeroserie}</strong></span><br/>
                                            <span style={{fontSize:'10px', color:'#B71C1C'}}><strong>{consumivel.enderecoip}</strong></span>
                                            </div>
                                    
                                        </div>
                                        <span style={{fontSize:'10px', color:'#B71C1C'}}><strong>{consumivel.prtalertdescription}</strong></span>
                                    </div>
                            })
                        }
                </div>
                {
                    consumiveis && consumiveis.length == 0 && <span style= {{fontSize:'8px'}}>* não existe(m) informações sobre consumiveis(s)</span>
                }
            <Divider style={{marginTop :'10px'}}/>
            <Typography variant='subtitle2' style= {{ marginTop : '20px'}}><strong>Equipamentos sem conexão</strong></Typography>
                    <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                        
                        {
                            equipamentos  && equipamentos.map(function(equipamento, i){
                                return <div style ={{fontSize:'10px', border:'none!important', padding:'5px',borderRadius :'3px', width:'12%', backgroundColor:'#f5f5f5', marginLeft : '5px', marginTop :'5px'}}>
                                        <div variant='outlined'className='no-border'  severity={equipamento.DATACOLETA ? 'warning' : 'error'} style={{display:'flex'}}>
                                            <div style = {{width :'25%'}}><PrintDisabled/></div>
                                            <div style = {{width :'75%'}} className='texto-limitado'>
                                            <span style={{fontSize:'10px'}}>{equipamento.modelo}</span><br/>
                                            <span style={{fontSize:'10px'}}><strong>{equipamento.numeroserie}</strong></span><br/>
                                            <span style={{fontSize:'10px'}}><strong>{equipamento.enderecoip}</strong></span>
                                            </div>
                                    
                                        </div>
                                        <span style={{fontSize:'10px' , color:'#B71C1C'}}>{'* Sem conexão há '+equipamento.dias+' dia(s)'}</span>
                                    </div>
                            })
                        }
                        {
                    equipamentos && equipamentos.length == 0 && <span>* não existe(m) informações sobre alertas de consumíveis(s)</span>
                }
                </div>
                <Divider style= {{marginTop : '30px'}}/>
            <div >
                    <Typography variant='subtitle2' style= {{ marginTop : '20px'}}><strong>Eventos Recentes</strong></Typography>
                    <div>
                        {
                            alertas && alertas.map(function(alerta, i){
                                return <Alert variant='outlined'className='no-border' style ={{fontSize:'10px', border:'none!important'}} severity={renderSeverity(alerta.prtalertcode)}>
                                        <AlertTitle style ={{ fontSize:'12px'}}>{alerta.horaalerta.substr(0,8) +' - '+alerta.numeroserie+ ' '+alerta.modelo}</AlertTitle>
                                        {
                                            alerta.prtalertdescription
                                        }
                                       
                                </Alert>
                            })
                        }
                    </div>

                    
                </div>
        </div>

    
}

export default withRouter(Dashboard)