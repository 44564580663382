import React, {Fragment, useEffect, useState} from 'react'
import CheckBoxOn from '@material-ui/icons/CheckCircle'
import CheckBoxOff from '@material-ui/icons/RemoveCircle'
import Api from '../providers/Api/Api' 
import CircularProgress from '@material-ui/core/CircularProgress'

export default function(props){
  
    const updateStatus = () =>{
       
        Api.get(props.url).then(response=>{
            if(response.data.status == 'OK'){
                if(props.updateStatus){
                    props.updateStatus(true)
                    
                }
               
            }
           
        }).catch(error=>{
            
        })
        
    }

    return props.ativo ==  'T' ? <CheckBoxOn style={{cursor:'pointer'}} color='primary' onClick={()=>{
        updateStatus()
    }}/> : <CheckBoxOff style={{cursor:'pointer'}} color='secondary' onClick={()=>{
        updateStatus()
    }}/>
}