import React , {useEffect, useState, useRef} from 'react';
import Principal from '../../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../../providers/Api/PrinterconnectV2Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import NivelToner from '../../../components/DisplayNivelToner/DisplayNivelToner'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import IconeOnline from '@material-ui/icons/Link'
import IconeOffLine from '@material-ui/icons/LinkOff'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import GeradorLog from '../../../providers/GeradorLog';

const Atolamentos = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [coletas, setColetas] = React.useState([]);

    console.log(props.location.state)
  

    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
    },[])


    useEffect(()=>{
       // GeradorLog(dadosUsuario);
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)
    
    const getData = () =>{

        if(!dadosUsuario.TB01008_TOKEN){
            return;
        }
        let chavePrinterconnect = dadosUsuario.TB01008_TOKEN.split('-');

        
        setLoading(true)
        Conexao.post('coletasController/getAlertasAtolamentosCorporate?codigoEmpresa='+printerconnect, {
            idempresa : chavePrinterconnect[1] ? chavePrinterconnect[1] : -1,
            idcliente : chavePrinterconnect[2] ? chavePrinterconnect[2] : -1, 
            tipoFiltro : refTipoFiltro.current ? refTipoFiltro.current.value  : '' ,  
            filtro : refFiltro.current ? refFiltro.current.value  : '' , 
            limit : 100,           
            offSet : 0,            
            status : '-1', 
            tipodata : 'COLETA'
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setColetas(response.data.payload)
                
            }else{
                setColetas([])
               
            }
            setLoading(false)
        }).catch(error=>{
            setColetas([])
          
            setLoading(false)
        })
    }





    return <Principal {...props} botaoAtivo = 'atolamentos' loading={loading}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Atolamentos de Papel</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}        
                    labelRowsPerPage="Linhas por página:"         
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {3}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'numeroSerie'>Número Série</option>
                            <option value = 'modelo'>Modelo</option>
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'descricao'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'25px'}}>
                    <TableHead>
                        <TableRow>

                            <TableCell align='center' style={{width: '6%'}}>Data</TableCell>
                            <TableCell align='center' style={{width: '6%'}}>Hora</TableCell>
                            <TableCell align='left' style={{width: '20%'}}>Equipamento</TableCell>
                            <TableCell align='left' style={{width: '50%'}}>Mensagem</TableCell> 
                            <TableCell align='center' style={{width: '20%'}}>Contadores</TableCell> 
                            
                        </TableRow>                       
                    </TableHead>
                    <TableBody>
                        {
                            coletas && coletas.map(function(coleta, i){
                                return <TableRow key ={i}>
                                     <TableCell align='center' >
                                
                                <Typography variant='caption' style={{fontSize:'10px'}}>{coleta.dataalerta}</Typography>
                                </TableCell>
                                <TableCell align='center' >
                                
                                   
                                    <Typography variant='caption' style={{fontSize : '10px'}}>
                                    {
                                       coleta.horaalerta && coleta.horaalerta.substr(0,8)
                                    }    
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption' style ={{fontSize:'12px'}} color='primary'>{coleta.modelo}</Typography><br/>
                                    <Typography variant='caption' style ={{fontSize:'10px'}} color='primary'><strong>{coleta.numeroserie}</strong></Typography>
                                </TableCell>
                         
                                <TableCell align='left'>
                                    <Typography variant='subtitle1' style={{fontSize:'10px'}}>{coleta.prtalertdescription}</Typography>
                                </TableCell>   
                                <TableCell align='center' >
                                    <Typography variant='subtitle2' >{coleta.contadortotal}</Typography>
                                    <Typography variant='caption' style={{fontSize:'10px'}}>{'[PB: '+coleta.contadormono+'/CL: '+coleta.contadorcolor+']'}</Typography>
                                </TableCell> 
                                                           
                            </TableRow>
                            })
                        }
                    </TableBody>
                    
                </Table>  
                {
                        coletas && coletas.length == 0 && <Typography variant='subtitle2'>* não existe(m) informação(ões)</Typography>
                    }    
        </div>
       
    </Principal>
}

export default withRouter(Atolamentos)