import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import BotaoStatus from '../../components/BotaoOnOffAtualizcao'
import Swal from 'sweetalert2'

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNovoContato from './ModalNovoContato'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import ModalEdicaoContato from './ModalEdicaoContato'
import GeradorLog from '../../providers/GeradorLog';

const Contratos = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [contratos, setContratos] = React.useState([]);
    const [contato, setContato]= useState([])


    

    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
        getData();
    },[])



    useEffect(()=>{
        
       // GeradorLog(dadosUsuario)
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)
    



    const getData = () =>{
        setLoading(true)
        Conexao.post('contratosController/getDetalhesContratoWeb?codigoEmpresa='+codigoEmpresa, {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            codigoEmpresa : codigoEmpresa,
            pagina : page
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setContratos(response.data.payload)
               
            }else{
                setContratos([])                
            }

            setLoading(false)

        }).catch(error=>{
            setContratos([])
            
            setLoading(false)
        })
    }

 


    return <Principal {...props} botaoAtivo = 'contatos' loading={loading}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosUsuario : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Contratos</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}    
                    labelRowsPerPage="Linhas por página:"             
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {3}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'numeroContrato'>Número</option>
                            <option value = 'responsavel'>Nome do Responsavel</option>
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'descricao'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'25px'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' style={{width: '15%'}}>Número</TableCell>
                            <TableCell align='center' style={{width: '10%'}}>Equipamentos</TableCell>
                            <TableCell align='left' style={{width: '30%'}}>Responsável</TableCell>
                            <TableCell align='center' style={{width: '15%'}}>Inicio</TableCell>
                            <TableCell align='center' style={{width: '15%'}}>Reajuste</TableCell>
                            <TableCell align='center' style={{width: '15%'}}>Vencimento</TableCell>
                            <TableCell align='center' style={{width: '15%'}}>Encerramento</TableCell>

                        </TableRow>                       
                    </TableHead>
                    <TableBody>
                        {
                            contratos && contratos.map(function(contrato, i){
                                return <TableRow key ={i}>
                                <TableCell align='left' >
                                    <Tooltip title='Clique para acessar detalhes do contrato'>
                                        <Typography className='text-link' variant='caption' onClick={()=>{
                                                props.history.push({
                                                    pathname : '/detalhescontrato', state:{
                                                    ...props.location.state,                                                 
                                                    numeroContrato : contrato.TB02111_CODIGO,                                                   
                                                    }
                                            })
                                            }} >{contrato.TB02111_CODIGO }</Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align='center'>
                                    <Tooltip title='Clique aqui para acessar os equipamentos pertencentes a este contrato'>
                                        <Typography className='text-link' variant='caption' color='primary' onClick={()=>{
                                            props.history.push({
                                                pathname : '/equipamentos/empresa', state:{
                                                ...props.location.state,                                                 
                                                tipoFiltro : 'contrato', 
                                                filtro : contrato.TB02111_CODIGO
                                                }
                                        })
                                        }}>{parseInt(contrato.TB02111_QTDE)}</Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption' color='primary'>{contrato.TB01006_NOME}</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography variant='caption'>{contrato.TB02111_DTINICIO && contrato.TB02111_DTINICIO.substr(0,10)}</Typography>
                                </TableCell>                        
                                <TableCell align='center'>
                                    <Typography variant='caption'>{contrato.TB02111_DTREAJUSTE && contrato.TB02111_DTREAJUSTE.substr(0,10)}</Typography>
                                </TableCell>   
                                <TableCell align='center' >
                                <Typography variant='caption'>{contrato.TB02111_VENCCONTR && contrato.TB02111_VENCCONTR.substr(0,10)}</Typography>
                                </TableCell> 
                                <TableCell align='center' >
                                    <Typography variant='caption'>{contrato.TB02111_DTENCERRA && contrato.TB02111_DTENCERRA.substr(0,10)}</Typography>
                                </TableCell>         
                                                   
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>      
        </div>
     
    </Principal>
}

export default withRouter(Contratos)