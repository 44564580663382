import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, TextField, Grid} from '@material-ui/core';
import Swal from 'sweetalert2';
import { ModalTitle } from 'react-bootstrap';
import validateEmailList from '../../providers/emailValidator';


export default function ModalEditaEmail(props) {
 
    const [showModal, setShowModal] = React.useState(false)
    const [listaEmails, setListaEmails] = React.useState(props.lista)
    const refEmail = React.useRef()
  return (
    <div>
        <Typography variant='caption' style = {{fontSize: '10px', cursor:'pointer', }} className='text-link' onClick={()=>setShowModal(true)}>{props.lista}</Typography>
      <Dialog
        open={showModal}
        onClose={()=>setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop
        maxWidth='md'
        fullWidth
      >
       
        <DialogContent>
            <ModalTitle>{props.titulo}</ModalTitle>
            <br/>
          <TextField 
            variant='outlined'
            fullWidth
            defaultValue={props.lista}
            value={listaEmails}
            onChange={(e)=>setListaEmails(e.target.value.replace(/[:,;]/g, ','))}
            label='Lista de Emails'/>
            <Typography style = {{fontSize: '10px', }}>* Esta lista será usada para envio de notificações de Notas Fiscais.</Typography>
            <br/>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs = {8}>

            </Grid>
            <Grid item xs={2}>
                <Button className='button-error' onClick={()=>{setShowModal(false)}} color="primary">
                    Cancelar
                </Button>
            </Grid>
            <Grid item xs={2}>                
                <Button className='button-success' onClick={()=>{
                    if(validateEmailList(listaEmails)){
                        Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Deseja editar esta lista de email de contato?', 
                            icon : 'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'SIM', 
                            showCancelButton : true, 
                            cancelButtonText: 'Não'
                        }).then(resp=>{
                            if(resp.isConfirmed){
                                
                            }
                        })
                        
                    }else{
                        Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Você precisa informar um email válido, ou uma lista de emails separados por ","!', 
                            icon : 'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'OK', 
                            showCancelButton : false, 
                            cancelButtonText: 'Não'
                        })
                    }
                    
                }} color="primary" autoFocus>
                    Confirmar
                </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
