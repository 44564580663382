import React from 'react'
import Bannerhome from './Bannerhome'
import './Landingpage.css'
import CardsAtuacao from './CardsAtuacao'
import {Typography, Button, TextField, Grid} from '@material-ui/core'
import Carrossel from './Carrosel'
import IMGOutsourcing from './imagens/equipamentosSamsung.png'
import ImagemTermicaHosp from './imagens/imagemColetoresHospital.png'
import ImagemTermicaHosp2 from './imagens/imagemColetoresHospital2.png'
import ImagemTermicaHosp3 from './imagens/imagemColetoresHospital3.png'
import ImagemTermicaLogistica from './imagens/imagemColetoresLogistica.png'
import ImagemColetoresImpressorasZebra from './imagens/imagemColetoresImpressorasZebra.png'
import Aspas from './imagens/aspas.png'
import Aspas2 from './imagens/aspas2.png'
import Imp3d from './imagens/img3dCloner.png'
import LogoCentermaqBranco from './imagens/logoCentermaqBranco.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LogoWhats from './imagens/logoWhatsapp.png'
import Phone from '@material-ui/icons/PermPhoneMsg'
import { Link, animateScroll as scroll } from "react-scroll";
import IconWhats from '@material-ui/icons/WhatsApp'
import {dadosEmpresa} from '../../providers/Auth/EmpresaPadrao'
import Conexao from '../../providers/Api/Api'
import GeradorLogGenerico from '../../providers/GeradorLogGenerico'
import {Alert, AlertTitle} from '@material-ui/lab'
import LogoPrinterconnect from './imagens/logoPrinterConnect.png'


export default function(props){
    const url = new URL(window.location.href);
    const [empresa, setEmpresa] = React.useState([])
    const [dominio, setDominio]= React.useState('')
    const [sufixoDominio, setSufixoDominio]= React.useState('')
    if(url.host =='localhost:3000'){
        url.host = 'centermaq.net'
      }
    
    React.useEffect(()=>{
        let temp = url.hostname.split('.');
       
        setDominio(temp[0])
        if(temp[1]){
            setSufixoDominio(temp[1])
        }
        getDataEmpresa();
       console.log('Checar url');
       console.log(url)
       GeradorLogGenerico()
    },[])
    const getDataEmpresa = () =>{

        //url.hostname = 'centermaq.net';
       
        Conexao.get('empresaController/getDataEmpresaSite?dominio='+url.hostname).then(response=>{
            
            if(response.status == 200 && response.data.status == 'OK'){
               
                setEmpresa(response.data.payload);
            }else{
                setEmpresa([])
            }

        }).catch(error=>{

            setEmpresa([])
            
        })
        
    }
    const urlImagen = (url.host =='centermaq.net' || url.host == 'www.centermaq.net') ? 'centermaq.png' : 'uziprint.png'
    const empresaNome = (url.host =='centermaq.net' || url.host == 'www.centermaq.net') ? 'CENTERMAQ' : 'UZIPRINT'
    return <div >
        <Bannerhome 
            dadosEmpresa={empresa} 
            dominioEmpresa = {dominio.toUpperCase()} 
            url ={url.href} 
            dadosTemplateEmpresa={empresa} 
            icone={<img src ={url.href+'img/'+urlImagen} width='100px'/>}/>
            
        <CardsAtuacao />
        <div id='div-outsourcing-main'>
        
            <div style ={{display:"flex", justifyContent:"flexStart", }}>
               <img src ={IMGOutsourcing} width="50%"/>
               <div style ={{width:"50%", textAlign:"left"}}>
                    <h2 >Outsourcing de Impressão</h2>
                    <p>
            A <strong>{empresaNome}</strong> é especilizada em Outsourcing de Impressão atuando há 30 anos e se destacando como referência no interior de São Paulo, essa experiência já rendeu diversos prêmios pelo seu alto grau de qualificação técnica.

Hoje a {empresaNome} conta com equipe técnica certificada e especializada nas marcas <strong>Ricoh, Brother, Samsung, Epson, OKI, Lexmark, Kyocera, Konica Minolta e Canon</strong>, além de contar com uma equipe de especialistas em projetos de Outsourcing.
                    </p>
                    <Button variant='contained' color='primary'>Saiba mais</Button>
               </div>
            </div>
           

        </div>

        <br/>

        <br/>
        <br/>

        <div style ={{textAlign:"center"}}>
        <Typography variant= 'h4' color='primary'>Impressoras Térmicas e Coletores de Dados</Typography>
        </div>
       
        <br/>
        <div style ={{paddingLeft:"15%", paddingRight:"15%"}}>
        <Typography variant='subtitle1' color='primary'> Aumente a produtividade, eficiência e exatidão dos seus processos logisticos com as soluções em <strong>Outsourcing de Impressão Térmica e Coletores de dados</strong> que a {empresaNome} tem a oferecer, conheça os seguimentos em que atuamos.</Typography>
        </div>
        

        <br/>
        <br/>
        
        <div id='div-termicas'>
          
            <div class="image">
                <img src={ImagemTermicaHosp}  alt="" />
                <div class="overlay" >
                <Typography variant='h6' color ='primary' style = {{marginTop:"5%"}}>Solução para Área da Saúde</Typography>
                <Typography variant='caption' color ='primary' style = {{marginTop:"5%"}}>
                    Faça gestão de suprimentos hospitalar com equipamentos e soluções Zebra.<br/>
                    <Button color='primary' variant='contained' style = {{marginTop:"5%"}}>Saiba mais</Button>
                </Typography>
                </div>
            </div> 
           
            <div class="image">
                <img src={ImagemTermicaHosp3}  alt="" />
                <div class="overlay" >
                <Typography variant='h6' color ='primary' style = {{marginTop:"5%"}}>Identificação de Pessoas</Typography>
                <Typography variant='caption' color ='primary' style = {{marginTop:"5%"}}>
                    Com as <strong>Soluções Zebra</strong> em impressão de pulseiras e crachás, aumente a segurança das suas operações.<br/>
                    <Button color='primary' variant='contained' style = {{marginTop:"5%"}}>Saiba mais</Button>
                </Typography>
                </div>
            </div> 
            <div class="image">
                <img src={ImagemTermicaLogistica}  alt="" />
                <div class="overlay" >
                <Typography variant='h6' color ='primary' style = {{marginTop:"5%"}}>Logistica e Armazém</Typography>
                <Typography variant='caption' color ='primary' style = {{marginTop:"5%"}}>
                    Otimize os fluxos de trabalho e ganhe em produtividade e eficiência com as <strong>Soluções Zebra</strong> para logistica e armazém.<br/>
                    <Button color='primary' variant='contained' style = {{marginTop:"10px"}}>Saiba mais</Button>
                </Typography>
                </div>
            </div> 
            <div class="image">
                <img src={ImagemColetoresImpressorasZebra}  alt="" />
                <div class="overlay" >                
                    <Typography variant='h6' color ='primary' style = {{marginTop:"5%"}}>Etiquetas Térmicas</Typography>
                    <Typography variant='caption' color ='primary' style = {{marginTop:"5%"}}>
                    Fornecimento de equipamentos e etiquetas sob medida para uma maior eficiência operacional.<br/>
                    <Button color='primary' variant='contained' style = {{marginTop:"5%"}}>Saiba mais</Button>
                    </Typography>
                </div>
            </div> 
        </div>

        <div style ={{textAlign:"center"}}>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
        <br/>
        <Typography variant= 'h4' color='primary'>Gerenciamento Eletrônico de Documentos</Typography>
        </div>
        <br/>
        <div style = {{paddingLeft:"15%", paddingRight:"15%", textAlign:"center"}}>
            <p><img src = {Aspas} className='icon-aspas'/><strong>Gerenciamento de conteúdo empresarial</strong> ou ECM (sigla em Inglês) não são apenas softwares de armazenamento de documentos, mas sim um conjunto de tecnologias usadas para gerenciar o ciclo de vida da informação não estruturada, passando pelas fases de criação ou captura, armazenamento, controle de versão, indexação, gerenciamento, limpeza, distribuição, publicação, pesquisa e arquivo, relacionando os conteúdos com processos de negócio<img src = {Aspas2} className='icon-aspas'/></p>

            <br/>
       
            
            <Button variant='contained' color ='primary'>Saiba mais</Button>
            <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
       
        </div>

        <div id='container-3d-flex'>
            <div id='container-3d-text'>
            <Typography variant='h4' color ='primary' style = {{marginTop:"5%"}}>Equipamentos e Suprimentos para impressão 3D</Typography>
            <br/>
            <p>
            A <strong>impressão 3D</strong> é o processo de transformação de um arquivo (objeto) tridimensional digital, em um objeto físico, real, tátil. <br/>
            Esse processo ocorre através da adição de material (polímero), camada sobre camada.  Atualmente a matéria-prima mais utilizada é o termoplástico, por conta da sua facilidade de ser transformado se exposto a temperaturas elevadas.<br/>Os equipamentos responsáveis por realizar esse processo de transformação, são chamados de impressoras 3D ou também conhecidos como máquinas de manufatura aditiva.
            </p>
            <br/>
        
            <Button variant='contained' color ='primary'>Saiba mais</Button>
            </div>
            <div id='container-3d-img'>
                <img src ={Imp3d} />
            </div>
        </div>
        <div id='div-pre-footer'>
            <div id='form'>
            <Typography variant='h4' color ='primary' >Fale com a {empresaNome}</Typography>
            <br/>
            <br/>
            <Grid container spacing = {2}>
                <Grid item xs = {12}>
                    <TextField
                        label='Nome Completo'
                        fullWidth
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {12}>
                    <TextField
                        label='Nome da Empresa (opcional)'
                        fullWidth
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {12}>
                    <TextField
                        label='Email'
                        fullWidth
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {12}>
                    <TextField
                        label='Telefone'
                        fullWidth
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {12}>
                    <TextField
                        label='Seguimento'
                        fullWidth
                        variant='outlined'
                        select
                        SelectProps={{native : true}}
                        >
                            <option>Outsourcing de Impressão</option>
                            <option>Impressoras Térmicas</option>
                            <option>Coletores de Dados</option>
                            <option>Gerenciamento de Documentos</option>
                            <option>Impressoras 3D</option>
                    </TextField>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {2}>
                <Grid item xs = {12}>
                    <Button variant='contained' color='primary'> Enviar</Button>
                </Grid>
            </Grid>
            <br/>
            <br/>
            </div>

        </div>
        <div style ={{textAlign:'center', backgroundColor:"#f5f5f5"}}>
        <br/>
            <br/>
            <Typography variant='h2'>Downloads</Typography>
            <hr/>
            <br/>
            <a href ={(url.host =='centermaq.net' || url.host == 'www.centermaq.net') 
                    ? 'https://www.datacount.com.br/downloads/v2/centermaq-collector-setup.zip' 
                    : 'https://www.datacount.com.br/downloads/v2/uziprint-collector-setup.zip'} 
                target='_blank'><img src={LogoPrinterconnect} height={80}/></a>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        <Link 
            to = "div-pre-footer"  
            activeClass="active"   
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}><div id='div-fale-especialista'> 
            <div id='div-fale-icon'><Phone/></div>
            <div id='div-fale-text'>
            <Typography variant='subtitle2'>Fale com um</Typography>
            <Typography variant='subtitle1'>Consultor</Typography>
            </div>
          
        </div></Link>  
        <footer id='footer'>
            <div id='footer-logo'>
                <img src ={'http://'+url.hostname+'/img/'+urlImagen} width='100px'/>
                <div>
                    <FacebookIcon />
                    <InstagramIcon />
                    <LinkedInIcon />
                    <YouTubeIcon />
                </div>
            </div>
            <div id='logo-whats'>
                <a href = {'https://api.whatsapp.com/send?phone='+empresa.TB00012_FONE+'&text=Bem%20Vindo%20a%20'+empresa.TB01007_NOME} target="_blank"><img src ={LogoWhats}  /></a>
            </div>            
            <hr/>
            <div>
                <Typography variant='h6'>{empresa.TB01007_NOME}</Typography>
                <Typography variant='subtitle2'>{empresa.TB00012_END}</Typography>
                <Typography variant='subtitle2'>{empresa.TB00012_CEP}</Typography>
                <Typography variant='subtitle2'>{empresa.TB01007_CNPJ}</Typography>
                <Typography variant='subtitle2'>{empresa.TB00012_FONE}</Typography>
                <Typography variant='subtitle2'></Typography>
            </div>
        </footer>
        
    </div>
}