

const GeradorLogGenerico = (error ='') =>{
    const Conexao  = require('./Api/Api');
    const { v4: uuidv4 } = require('uuid');
    const url = new URL(window.location.href);
    var sBrowser,
    sUsrAg = navigator.userAgent;
    console.log(sUsrAg)
    if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("MSIE") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } 
   
    Conexao.post('EmpresaController/gerarLogSiteGenerico', {
        dominio : url.hostname, 
        navegador : sBrowser, 
        error : error
    }).then(response=>{

    }).catch(error=>{

    })
}

module.exports = GeradorLogGenerico;