import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetalhesIcon from '@material-ui/icons/FormatListBulleted'
import { TextField, Grid, Table, TableHead, TableBody,  TableCell, TableRow, Typography , Fab, Badge, InputAdornment, LinearProgress} from '@material-ui/core';
import NivelToner from './NivelToner'
import Swal from 'sweetalert2';
import Conexao from '../../../providers/Api/PrinterconnectV2Api'
import DetalhesColeta from './DetalhesColetaV2'
import ListaAlertas from './V2/ListaAlertas'
import GraficoContadoresEquipamento from './V2/GraficoContadoresEquipamento'
import { Alert, AlertTitle } from '@material-ui/lab';



export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading]= React.useState(false);
  const [dataGraficoDetalhesContadores, setDataGraficoDetalhesContadores]= React.useState([]);
  const [coleta, setColeta] = React.useState({
      nivelToner : 25, 
      numeroSerie : '', 
      contadorToner : 0
  });

  const [token, setToken] = React.useState({
    chave : '', 
    codigoEmpresa : '', 
    codigoCliente : '',
  })


  React.useEffect(()=>{
    if(token && token.codigoCliente){      
      getDetalhesColeta();
      getDataGraficoContadoresDetalhesEquipamento()
      console.log(token)
    }    
  },[token])

  React.useEffect(()=>{

    if(props.dadosEmpresa.TB01008_TOKEN){
      let temp = props.dadosEmpresa.TB01008_TOKEN.split('-')

      setToken({
        chave : temp[0], 
        codigoEmpresa : temp[1], 
        codigoCliente : temp[2],
      })
      
    }
    
  },[])
/*

printerConnectCliente: "3937"
​
printerConnectEmpresa: "2"
​
printerConnectVersao: 2

*/

const getDetalhesColeta = async () =>{
    setLoading(true)
    props.setLoading(true)
    console.log(token)
    Conexao.post('coletasController/getLeitura', {
      numeroserie : props.numeroSerie, 
      idempresa : token.codigoEmpresa, 
      idcliente : token.codigoCliente, 
      chave : token.chave,
     
    }).then(response =>{
      if(response.data.status == 'OK'){
        setColeta(response.data.payload);
        
        if(props.setColeta){
          props.setColeta(response.data.payload);
         
        }
        
      }else{
        setColeta([]);
        if(props.setColeta){
          props.setColeta([]);
        }
      }
      setLoading(false)
      props.setLoading(false)
    }).catch(error =>{
      setColeta([]);
      if(props.setColeta){
          props.setColeta([]);
        }
      setLoading(false)
      props.setLoading(false)
    })
  }



  const handleClickOpen = () => {      
    setOpen(true)
    getDetalhesColeta()
  };

  const handleClose = () => {
    setOpen(false);
  };


  const getDataGraficoContadoresDetalhesEquipamento = async () =>{
    props.setLoading(true)
    Conexao.get('coletasController/getDataGraficoContadoresDetalhesEquipamento?idempresa='+token.codigoEmpresa+'&idcliente='+token.codigoCliente+'&intervalo=0&numeroserie='+props.numeroSerie).then(response=>{
        if(response.data.status == 'OK'){
            setDataGraficoDetalhesContadores(response.data.payload)
        }else{
            setDataGraficoDetalhesContadores([])
        }
        props.setLoading(false)
    }).catch(error =>{
        setColeta([])
        props.setLoading(false)
        Swal.fire({
            title : 'Printerconnect', 
            text : 'Não foi possível recuperar informações sobre coleta, Sem conexão com servidor remoto !', 
            showCancelButton : true,
            cancelButtonText : 'Cancelar', 
            showConfirmButton :false, 
            icon : 'error'
        })
    });
}



//getNotasFiscaisContrato 
  return <div>

<Typography style ={{marginTop:'10px'}} variant='subtitle1'>Status do equipamento {coleta && coleta.datacoleta && <Typography style ={{marginTop:'2px'}} variant='caption' className={coleta.dias > 0 ? 'label-error' : 'label-success'}>Informações coletadas em: {coleta.datacoleta+' '+(coleta.horacoleta && coleta.horacoleta.substr(0,8))}</Typography> }</Typography>
{
  !loading && coleta && !coleta.datacoleta &&  <Alert  severity="error">
    <AlertTitle>Printerconnect</AlertTitle>Não há informações de coleta</Alert>
}
      <br/>
            {!loading && coleta && coleta.datacoleta && <React.Fragment><Grid container spacing = {1}>      
            
                <Grid item xs = {2}>
                    <DetalhesColeta contador={coleta.contadorciano} sizeDiplay={props.sizeDiplay} cor='CIANO' corToner='#1976D2' preenchimento='#1976D2' descricao={coleta.numeroserieciano} percentual={coleta.nivelciano}  numeroSerie={props.numeroSerie}/>      
                    <Typography variant='caption' ></Typography>                         
                </Grid>
                <Grid item xs = {2}>
                    <DetalhesColeta contador={coleta.contadormagenta}  sizeDiplay={props.sizeDiplay} cor='MAGENTA' corToner='#C2185B' preenchimento='#C2185B'  descricao={coleta.numeroseriemagenta} percentual={coleta.nivelmagenta}  numeroSerie={props.numeroSerie} />                               
                </Grid>
                <Grid item xs = {2}>
                    <DetalhesColeta contador={coleta.contadoramarelo}  sizeDiplay={props.sizeDiplay} cor='AMARELO' corToner='#FBC02D' preenchimento='#FBC02D'  descricao={coleta.numeroserieamarelo} percentual={coleta.nivelamarelo}  numeroSerie={props.numeroSerie}/>                               
                </Grid>
                <Grid item xs = {2}>
                    <DetalhesColeta contador={coleta.contadorpreto}  sizeDiplay={props.sizeDiplay} cor='PRETO' corToner='#000' preenchimento='#000'  descricao={coleta.numeroseriepreto} percentual={coleta.nivelpreto}   numeroSerie={props.numeroSerie}/>                              
                </Grid> 
                <Grid item xs = {4}>                
                    <div>
                      <div><Typography variant='caption'>Contador Monocromático</Typography></div>
                      <div>{coleta.monototal ? coleta.monototal : 'n/d'}</div>
                    </div>
                    <div>
                      <div><Typography variant='caption'>Contador Colorido</Typography></div>
                      <div>{coleta.colortotal ? coleta.colortotal : 'n/d'}</div>
                    </div>
                    <div>
                      <div><Typography variant='caption'>Contador Total</Typography></div>
                      <div>{coleta.contadortotal ? coleta.contadortotal : 'n/d'}</div>
                    </div> 
                    <Typography style ={{marginTop:'2px'}} variant='caption' color={coleta.dias > 0 ? 'secondary' : ''}>Endereço IP: {coleta.enderecoip}</Typography>
                </Grid>           
            </Grid>
            
            {!props.resumido &&  <div style ={{width:'100%', height:'230px',}}>
          <Typography  variant='subtitle1'>Produção de páginas</Typography>
          {dataGraficoDetalhesContadores && ! props.resumido && <GraficoContadoresEquipamento data={dataGraficoDetalhesContadores}/>   }
          <br/>
          <div>
                {!props.resumido && <ListaAlertas serial={props.numeroSerie}/>}
            </div>
          <br/>
          
          <br/>
          
          </div>}
          </React.Fragment>}
        </div>
}
