import React, {useState, useRef, Fragment, useEffect} from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
  import Typography from '@material-ui/core/Typography';
  import Button from '@material-ui/core/Button'
  import Tooltip from '@material-ui/core/Tooltip'




  export default function(props){

    return <div>
                <div className='nivel-toner-container'  >
                    <div>
                    <Typography variant = 'caption' style ={{fontSize:props.fontRotulo ? props.fontRotulo : "10px", minWidth:"100%", width:"100%"}}>{props.contador}</Typography>
                    <div style = {{width : props.width, height : props.height}}>
                        <CircularProgressbar value={props.percentual ? props.percentual : 0} text={props.percentual ? props.percentual+'%'  : '0%'} strokeWidth ={props.lineSize} styles={buildStyles({
                            textColor: '#000',
                            pathColor: props.color,                        
                        })}/>
                    </div>
                    <Typography variant = 'caption' style ={{fontSize:props.fontRotulo ? props.fontRotulo : "10px", minWidth:"100%", width:"100%"}}>{props.descricao}</Typography>
                    </div>              
                </div>
                    {(props.percentual && !props.hide ) ? <Button fullWidth variant='outlined' onClick={()=>props.click()} style ={{cursor:'pointer',fontSize:'10px', marginTop:'10px' }}>Detalhes Consumo</Button>:''  }
            </div>
  }

