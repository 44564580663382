import React from 'react';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter as Router, Switch, Route, Link, Redirect, HashRouter} from 'react-router-dom'
import {isAuthenticated} from './providers/Auth/IsAutenticate'
import Navbar from './views/navbar/Navbar'

//Pagina Principal
import PaginaPrincipal from './views/TemplateHome/Home'
import EquipamentosLeiturasPendentes from './views/Equipamentos/EquipamentosLeiturasPendentes';



/*
wireframe

https://app.moqups.com/HalSlLylGM/edit/page/ae8fe8eb0

trello
//https://www.npmjs.com/package/react-trello
*/


/*,
GERANDO PDF COM PHP/CODEIGNITER
http://www.danielsousa.com.br/wp/2010/05/23/integrando-codeigniter-e-mpdf-para-geracao-de-arquivos-pdf/

*/


//landing page
import Landingpage from './views/LandingPage/Landingpage'
import Equipamentos from './views/Equipamentos/Equipamentos'
import Suprimentos from './views/Suprimentos/PedidosSuprimento'
import Chamados from './views/Chamados/Chamados'
import Departamentos from './views/Departamentos/Departamentos'
import Contatos from './views/Contatos/Contatos'
import Contratos from './views/Contratos/Contratos'
import Sites from './views/Sites/Sites'
import DetalhesEquipamentoBusca from './views/Equipamentos/DetalhesEquipamentoBusca'
import Monitoramento from './views/Printerconnect/Monitoramento/Monitoramento';
import Atolamentos from './views/Printerconnect/Atolamentos/Atolamentos';
import Erros from './views/Printerconnect/AlertasErro/Erros';
import AlertasSuprimento from './views/Printerconnect/AlertasSuprimento/Suprimentos';
import SolicitacoesSuprimentos from './views/Suprimentos/SolicitacoesSuprimentos';
import Usuarios from './views/Usuarios/Usuarios';
import TrocarSenha from './views/Usuarios/TrocarSenha';
import EquipamentoQRCode from './EquipamentoQRCode/EquipamentoQRCode';
import HomeQRCode from './EquipamentoQRCode/HomeQRCode';
import DashboardToner from './views/Dashboard/DashboardToner';
import DashboardMini from './views/Dashboard/Dashboard2';
import Dashboard from './views/Dashboard/Dashboard';
import DetalhesContrato from './views/Contratos/DetalhesContrato';
import Welcome from './views/Welcome/Welcome';
import InterfaceVideos from './views/Principal/InterfaceVideos';
import EmailsNotificacoes from './views/Usuarios/EmailsNotificacoes';
import SolicitacoesChamados from './views/Chamados/SolicitacoesChamados';
import GestaoRelatorios from './views/GestaoRelatorios/GestaoRelatorios';
const theme = createMuiTheme({
  typography :{
    useNextVariants : true,
    
  },
  palette: {
    primary: {
      main :'#37474F' 
    }, 
    secondary: {
      main: '#b00020',
    },
    success: {
      main: '#009688',
    },
    default: {
      main: '#42a5f5',
    },
    warning: {
      main: '#ff9800',
    },
    danger: {
      main: '#f44336',
    },

  },
  
  typography: {
    
    fontFamily: [
      'Nunito',
      'BlinkMacSystemFont',-
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  
});



const PrivateRoute = ({ component: Component, ...rest }) => (
 
  <Route
    {...rest}
    render={props =>
      isAuthenticated({...props}) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const RotasPublicas = ({ component: Component, ...rest }) =>(
<Route
    {...rest}
    render={props =>
      isAuthenticated({...props}) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const verificarQRCode = () =>{
  
}


function App() {

  var fragmento = window.location.href.split('#');

  if(fragmento.length > 1){
    
    let pagina = fragmento[1].substring(1)
    
    if( pagina.substring(0,6) == 'qrcode'){

      window.location.href = fragmento[0]+pagina

      
    }
    
  }
  
 
  return (
    <MuiThemeProvider theme={theme}>     
      <Router>
        <Switch>        
          <Route path ='/' exact component={Landingpage}/>
          <Route path ='/newdataservice' exact component={Landingpage}/>
          <Route path ='/newdataservice/qrcode/:pdoduto/:numeroserie' exact component={EquipamentoQRCode}/>
          <PrivateRoute  path ='/Welcome/:empresa' exact component={Welcome}/>
          <PrivateRoute  path ='/home/:empresa' exact component={Dashboard}/>
          <PrivateRoute  path ='/home2/:empresa' exact component={DashboardMini}/>
          <PrivateRoute  path ='/equipamentos/:empresa' exact component={Equipamentos}/>
          <PrivateRoute  path ='/leituras/:empresa' exact component={EquipamentosLeiturasPendentes}/>          
          <PrivateRoute  path ='/suprimentos/:empresa' exact component={Suprimentos}/>
          <PrivateRoute  path ='/solicitacoes-suprimentos/:empresa' exact component={SolicitacoesSuprimentos}/>
          <PrivateRoute  path ='/solicitacoes-chamado/:empresa' exact component={SolicitacoesChamados}/>
          
          <PrivateRoute  path ='/chamados/:empresa' exact component={Chamados}/>

          <PrivateRoute  path ='/relatorios/:empresa' exact component={GestaoRelatorios}/>
          
          <PrivateRoute  path ='/departamentos/:empresa' exact component={Departamentos}/>          
          <PrivateRoute  path ='/Dashboard-toner/:empresa' exact component={DashboardToner}/>
          <PrivateRoute  path ='/Dashboard/:empresa' exact component={Dashboard}/>
          <PrivateRoute  path ='/contatos/:empresa' exact component={Contatos}/>
          <PrivateRoute  path ='/contratos/:empresa' exact component={Contratos}/>
          <PrivateRoute  path ='/detalhescontrato' exact component={DetalhesContrato}/>
          <PrivateRoute  path ='/Sites/:empresa' exact component={Sites}/>
          <PrivateRoute  path ='/DetalhesEquipamentoBusca/:filtro' exact component={DetalhesEquipamentoBusca}/>
          <PrivateRoute  path ='/Monitoramento/:empresa' exact component={Monitoramento}/>
          <PrivateRoute  path ='/Atolamentos/:empresa' exact component={Atolamentos}/>
          <PrivateRoute  path ='/erros/:empresa' exact component={Erros}/>
          <PrivateRoute  path ='/alertas-suprimentos/:empresa' exact component={AlertasSuprimento}/>
          <PrivateRoute  path ='/Usuarios/:empresa' exact component={Usuarios}/>
          <PrivateRoute  path ='/trocarsenha/:empresa' exact component={TrocarSenha}/>
          <Route  path ='/qrcode/:produto/:numeroserie' exact component={EquipamentoQRCode}/>
          <Route  path ='/qrcode/:produto/:numeroserie/:cliente' exact component={HomeQRCode}/>
          <PrivateRoute  path ='/ajuda' exact component={InterfaceVideos}/>
          <PrivateRoute  path ='/emails-notificacao' exact component={EmailsNotificacoes}/> 
        </Switch>
      </Router>       
    </MuiThemeProvider>   

  );
}


export default App;
