import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField, InputAdornment, Grid} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Api from '../../providers/Api/Api'
import GeradorLog from '../../providers/GeradorLog';
import GeradorLogErro from '../../providers/GeradorLogErro';
import GeradorLogGenerico from '../../providers/GeradorLogGenerico';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalLogin = (props) => {
    const url = new URL(window.location.href);
    const [open, setOpen] = React.useState(false);
    const refLogin = React.useRef();
    const refSenha = React.useRef();

    const autenticarUsuario =  () =>{
        
        Api.post('WebController/autenticar?idEmpresa=centermaq', {
            login : refLogin.current ? refLogin.current.value : '',
            senha : refSenha.current ? refSenha.current.value : '', 
            dominio: url.host
        }).then(response =>{
            if(response.status == 200){
                if(response.data.status == 'OK'){
                    sessionStorage.setItem('corporate@usuario-nivel', JSON.stringify(response.data.nivel))
                    sessionStorage.setItem('corporate@usuario', JSON.stringify(response.data.data))
                    if(response.data.nivel=='USER'){
                      sessionStorage.setItem('corporate@equipamentosPermitidos', JSON.stringify(response.data.data.equipamentosPermitidos))
                    }
                    
                    props.history.push({pathname :'/dashboard/cliente/'+response.data.data.CODIGOCLIENTE, state :{
                      nivel:response.data.nivel,
                      usuario : response.data.data,
                      equipamentos : response.data.data.equipamentosPermitidos
                  }})
        
                    console.log(response.data);
        
                    //elseshowMessage('filé', 'sucess');
                }else{
                    Swal.fire({
                        title : 'Centermaq - Portal Web', 
                        text : 'Usuário ou senha inválido !', 
                        icon : 'warning', 
                        showConfirmButton : true, 
                        confirmButtonText : 'OK', 
                        showCancelButton : false

                    })
                    GeradorLogGenerico(response.data.mensagem);
                }
            }else{
                Swal.fire({
                    title : 'Centermaq - Portal Web', 
                    text : 'Usuário ou senha inválido !', 
                    icon : 'error', 
                    showConfirmButton : true, 
                    confirmButtonText : 'OK', 
                    showCancelButton : false

                })
                GeradorLogGenerico('Usuário não localizado ' +refLogin.current.value );
            }
        }).catch(error =>{
          GeradorLogGenerico(error);
            Swal.fire({
                title : 'Centermaq - Portal Web', 
                text : 'Erro ao acessar recurso remoto !', 
                icon : 'error', 
                showConfirmButton : true, 
                confirmButtonText : 'OK', 
                showCancelButton : false

            })
        })
        
    }
    const handleClose = () =>{
        setOpen(false)
    }

  return (
    <div id='div-login'>
      <Tooltip title='Selecionar uma empresa cadastrada'>
        <Button variant="contained" color="secondary"  onClick={()=>{setOpen(true)}}>
         Entrar
        </Button>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        fullWidth
       
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Area restrita
        </DialogTitle>
        
        <DialogContent dividers >
        <br/>
            <Grid container spacing = {2}>
                <Grid item xs={12}>
                    <TextField
                        label='Login'
                        variant='outlined'
                        fullWidth
                        inputRef={refLogin}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                        />
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <TextField
                        label='Senha'
                        inputRef={refSenha}
                        variant='outlined'
                        fullWidth
                        type='password'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                        />
                </Grid>
            </Grid>
            <br/>
            <br/>
             
                
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button  onClick={()=>autenticarUsuario()} variant='contained'  color="primary">
            Entrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalLogin)