import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/AccountTree';
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography , Fab, Badge, InputAdornment} from '@material-ui/core';
import IconeNFe from '@material-ui/icons/Assignment'
import Swal from 'sweetalert2';
import IconeCarrinhoCheio from '@material-ui/icons/ShoppingCart'
import AddCart from '@material-ui/icons/ArrowDownward'
import RevemoIcon from '@material-ui/icons/Close'
import {Tabs, Tab} from 'react-bootstrap'
import IconeLocalizar from '@material-ui/icons/Search'
import IconeLike from '@material-ui/icons/ThumbUpAlt'
import IconeNotLike from '@material-ui/icons/ThumbDownAlt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Delete from '@material-ui/icons/DeleteForever'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconeFinanceiro from '@material-ui/icons/MonetizationOn'
import IconeAprovar from '@material-ui/icons/Done'
import IconeCancelar from '@material-ui/icons/RemoveShoppingCart'
import IconeEstoque from '@material-ui/icons/EventAvailable'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Brush'

export default function ModalNovoSetor(props) {
  const [open, setOpen] = React.useState(false);

  const [sequencia, setSequencia] = React.useState(-1);
  const [descricao, setDescricao] = React.useState('');
  const [responsavel, setResponsavel] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telefone, setTelefone] = React.useState('');

  const handleClickOpen = () => {   
    setOpen(true);
    if((props.contato && props.contato.SEQUENCIA > -1)){
      setSequencia(props.contato.SEQUENCIA)
      setDescricao(props.contato.CONTATO)
      setResponsavel(props.contato.NOME)
      setEmail(props.contato.EMAIL)
      setTelefone(props.contato.TELEFONE)
    }
    
  };

  const handleClose = () => {
    setOpen(false);
    props.clearContato();
   
  };
  React.useEffect(()=>{
    if(!open){
      setSequencia(-1)
      setDescricao('')
      setResponsavel('')
      setEmail('')
      setTelefone('')
    }
  },[open, props.contato.SEQUENCIA])

  const  validateEmail = (email) =>{
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
//getNotasFiscaisContrato
  return (
    <div >

        { (props.contato && props.contato.SEQUENCIA > -1) ? <Button className='button-default-outlined' color='primary' onClick={()=>{
            handleClickOpen()
            if(props.setContato){
                props.setContato();
            }
        }}>
           <EditIcon  />
            </Button> : <Fab onClick={handleClickOpen} size='large' color='primary' ><AddIcon /></Fab>}
        
      <Dialog
        open={open || (props.contato.SEQUENCIA && props.contato.SEQUENCIA > -1) }
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'sm'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'
      >
        <DialogTitle id="alert-dialog-title">Cadastro de Contato</DialogTitle>
        <DialogContent dividers>   
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Descrição'
                        fullWidth
                        variant='outlined'
                        value={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Responsável'
                        fullWidth
                        variant='outlined'
                        value={responsavel}
                        onChange={(e)=>setResponsavel(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Email'
                        fullWidth
                        variant='outlined'
                        type='email'
                        value={email}                       
                        inputProps={{'type':'email'}}
                        onChange={(e)=>setEmail(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='telefone de contato'
                        fullWidth
                        variant='outlined'
                        value={telefone}
                        onChange={(e)=>setTelefone(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
        </DialogContent>
        <DialogActions>
            <Grid container spacing ={1}>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                    <Button  color='secondary' fullWidth className='button-error' onClick={()=>{
                        setOpen(false)
                        props.clearContato()
                    }}>Cancelar</Button>
                </Grid>
                <Grid item xs={2}>
                <Button className='button-default' fullWidth color='primary' onClick={()=>{
                    Swal.fire({
                      title : 'Contatos', 
                      title  : 'Deseja cadastrar este CONTATO?', 
                      icon : 'question', 
                      showConfirmButton : true, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : true, 
                      cancelButtonText : 'Não'
                    }).then(resposta=>{
                      if(resposta.isConfirmed){
                        props.Conexao.post('clientesController/insertContatosCliente?empresa='+props.empresa,{
                          codigoCliente : props.cliente, 
                          codigo : sequencia,
                          descricao : descricao, 
                          responsavel : responsavel, 
                          email : email, 
                          telefone : telefone
                        }).then(response=>{
                          if(response.data.status =='OK'){
                            props.getData();
                            handleClose();
                          }else{
                            Swal.fire({
                              title : 'Contatos', 
                              title  : 'não foi possível incluir/alterar', 
                              icon : 'warning', 
                              showConfirmButton : false, 
                              confirmButtonText : 'Sim', 
                              showCancelButton : false, 
                              cancelButtonText : 'Não', 
                              timer : 1000
                            })
                          }
                          
                        }).catch(error=>{                          
                          Swal.fire({
                            title : 'setors', 
                            title  : 'não foi possível incluir/alterar', 
                            icon : 'error', 
                            showConfirmButton : false, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 1000
                          })
                          handleClose();
                        })
                      }
                    })
                }}>Gravar</Button>
                </Grid>
            </Grid>                      
        </DialogActions>
      </Dialog>
    </div>
  );
}






