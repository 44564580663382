var axios = require('axios');

const origin = 'http://datacenter2.databit.com.br:53175'


module.exports = axios.create({
  baseURL: 'http://centermaq.info/databitservice/',
  timeout: 30000,
  headers: {    
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*', 
    'Access-Control-Allow-Headers':'*',      
    'Access-Control-Allow-Methods' : '*'
  }
});


