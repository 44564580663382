import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import ImagemEquipamentosVideo from './imgVideos/miniaturaVideoEquipamentos.png'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import ModalVideo from '../../components/base/ModalVideo';
import gerarLogSite from '../../providers/GeradorLog';
import Conexao from '../../providers/Api/Api'


const InterfaceVideos = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
   

    React.useEffect(()=>{
        gerarLogSite(dadosUsuario)
    }, [])
    
    const [dataVideos, setDataVideos]= React.useState([
    {
        descricao : "Ajuda da aba equipamentos, filtros, colunas, etc.",
        src:"https://www.youtube.com/embed/03ZQaV1zuo0" ,
        title:"Equipamentos."  
    },{
        descricao : "Tutorial de informações da tela de detalhes do equipamento, como trocar o departamento e outras informações.",
        src : 'https://www.youtube.com/embed/hD4vdbstQ7M', 
        title : 'Detalhes do Equipamento.', 
    },{
        descricao : "Ajuda do campo de pesquisa rápida.",
        src:"https://www.youtube.com/embed/tjbRX4zucRI",
        title:"Atalho para barra de pesquisa."  
    },{
        descricao : "Tutorial de abertura de ordens de serviço.",
        src:"https://www.youtube.com/embed/jLWpovBGVd0" ,
        title:"Como abrir uma Ordem de Serviço." 
    },{
        descricao : "Tutorial de abertura de requisições de suprimento.",
        src:"https://www.youtube.com/embed/rtccDtxwPqM" ,
        title:"Como abrir um Novo Pedido de Suprimentos." 
    },{
        descricao : "Criação de usuários, permissões especificas, etc.",
        src:"https://www.youtube.com/embed/XOWmxsFIUtk" ,
        title:"Como adicionar um novo usuário." 
    }]);


    

    


    

    return <Principal {...props} botaoAtivo = 'ajuda' loading={false}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit"  >
                        Home
                    </Link>
                    <Typography color="textPrimary">Ajuda do sistema</Typography>
                </Breadcrumbs>
                </div>
            </div>
            <Table className='table table-striped table-hover' style = {{ marginTop : '60px'}}>
               <TableHead>
                    <TableCell style={{width:'5%'}}>Ver</TableCell>
                    <TableCell style={{width:'95%'}}>Descrição do vídeo</TableCell>
               </TableHead>
                <TableBody>
                    
                    {
                    dataVideos && dataVideos.map(function(video, i){
                        return <TableRow>
                            <TableCell><ModalVideo  gerarLogSite ={()=>gerarLogSite(dadosUsuario, '[MODAL AJUDA]-['+video.descricao+']')}>
                        <iframe 
                            width="900" 
                            height="500" 
                            src={video.src} 
                            title={video.title}
                            frameborder="0" 
                            allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                                
                        </iframe>
                        </ModalVideo></TableCell>
                            <TableCell>
                            <Typography variant='subtitle2'>{video.title}</Typography>
                            <Typography variant='caption'>{video.descricao}</Typography>
                            </TableCell>
                        </TableRow>
                    })
                }
                    
                </TableBody>
            </Table>
          
            
        </div>
       
    </Principal>
}

export default withRouter(InterfaceVideos)