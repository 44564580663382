import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import {Alert, AlertTitle} from '@material-ui/lab'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalPedidoSuprimentos from './ModalPedidoSuprimento'
import ModalAberturaChamado from './ModalAberturaChamado'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import Api from '../../providers/Api/Api';
import ModalVideo from '../../components/base/ModalVideo';

const DetalhesEquipamentoBusca = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);


    const [loading, setLoading]= React.useState(false);

    const [equipamento, setEquipamento] = React.useState([]);
    const [tipoFiltro, setTipoFiltro]= React.useState('');

    console.log(dadosUsuario)


    useEffect(()=>{
       
        getData();
    },[])

    useEffect(()=>{
        if(props.match.params.filtro != ''){
            getData();
        }
        
    },[props.match.params.filtro])



    const getData = () =>{
        setLoading(true)
        Conexao.post('equipamentosController/getEquipamentoDetail', {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            filtro :  props.match.params.filtro ? props.match.params.filtro : '',
            userName : dadosUsuario.TB01066_LOGIN, 
            userType :  dadosUsuario.userType,
            naoPermitidos : dadosUsuario.userEquipamentos == 'full' ? 'not' : '', 
           
        }).then(response=>{
            
            if(response.data.status =='OK'){
                if(props.match.params.filtro == response.data.payload.TB02112_NUMSERIE){
                    setTipoFiltro('Registro localizado número de série');
                }
                if(props.match.params.filtro == response.data.payload.TB02112_PAT){
                    setTipoFiltro('Registro localizado número de patrimônio');
                }
                setEquipamento(response.data.payload)
                
            }else{
                setEquipamento([])
                setTipoFiltro('')
            }
            setLoading(false)
        }).catch(error=>{
            setEquipamento([])
            setTipoFiltro('')
            setLoading(false)
        })
    }

   


    return <Principal {...props} botaoAtivo = 'DetalhesEquipamentoBusca' loading={loading}>
        <div className='container-children'>    
        <ModalVideo  >
            <iframe 
                width="900" 
                height="500" 
                src="https://www.youtube.com/embed/fH-Ok-wWWWg" 
                title="Aplicação da impressora Zebra ZT230 na indústria." 
                frameborder="0" 
                allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                    
            </iframe>
            </ModalVideo>     
           <Typography variant='subtitle1'># Pesquisa de equipamentos</Typography>
           <br/>
           <Divider/>
           <br/>
           
           <Typography variant='h5'>Filtro: <strong>{props.match.params.filtro}</strong></Typography>
           <Typography variant='caption'>{tipoFiltro}</Typography>
           <br/>
           {
            equipamento && equipamento.length == 0 ? <Alert severity='error' style ={{marginTop : '10px'}}>* Não foi localizado nenhum equipamento com os dados fornecidos.</Alert> : <div>
            <Alert severity='info' style ={{marginTop : '10px', cursor:'pointer'}} onClick={()=>{
                
            }}>
                <AlertTitle>{equipamento.TB01010_NOME}</AlertTitle>
                <Typography variant='subtitle2'>Número de Série: {equipamento.TB02112_NUMSERIE}</Typography>
                <Typography variant='caption'>PAT: {equipamento.TB02112_PAT}</Typography><br/>
                <Typography variant='caption'>Setor: {equipamento.TB02112_LOCAL }</Typography>
                <br/>
                <br/>
                <div style = {{display:'flex',  width : '520px', alignContent:'space-between', alignItems: 'space-between', justifyContent:'space-between'}}>
                   
                    <ModalPedidoSuprimentos state ={props.location.state} dadosEmpresa={props.location.state.dadosEmpresa} setLoading={()=>{}} equipamento={equipamento} Conexao = {Api} botaoGrande/>
                    
        
                    <ModalAberturaChamado dadosEmpresa={props.location.state.dadosEmpresa} setLoading={()=>{}} equipamento={equipamento} Conexao = {Api} botaoGrande />
                 
                </div>
            </Alert>
            </div>
           }
        </div>
       
    </Principal>
}

export default withRouter(DetalhesEquipamentoBusca)