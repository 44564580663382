import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell , Badge} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import PrinterconnectV2 from '../../providers/Api/PrinterconnectV2Api'
import PrinterconnectV1 from '../../providers/Api/PrinterconnectV1Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import USBIcon from '@material-ui/icons/Print'
import PowerOn from '@material-ui/icons/PowerSettingsNew'
import PowerSaving from '@material-ui/icons/Notifications'
import PowerOff from '@material-ui/icons/PowerOff'
import Swal from 'sweetalert2'
import WarningIcon from '@material-ui/icons/Warning'
import { Chart } from "react-google-charts";
import {Alert, AlertTitle} from '@material-ui/lab'
import GraficoPiza from './GraficoPiza'
import PrintDisabled from '@material-ui/icons/PrintDisabled'
import DisplayToner from '../../components/DisplayNivelToner/DisplayNivelToner'
let data = [];





var firstOpen = true



export const options = {
    title: "Coletores de Dados Instalados",
  };


const DashBoardToner = function(props){
    
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const [loading, setLoading]= React.useState(false);

    const [alertas, setAlertas]= React.useState([]);
    const [dcas, setDcas]= React.useState([]);
    const [equipamentos, setEquipamentos]= React.useState([]);
    const [suprimentos, setSuprimentos]= React.useState([]);
    const [pronto, setPronto]= React.useState(0);
    const [atencao, setAtencao]= React.useState(0);
    const [alerta, setAlerta]= React.useState(0);
    const [offLine, setOffLine]= React.useState(0);
    const [total, setTotal]= React.useState(0);
    const dadosEmpresa = props.location.state.dadosEmpresa;

    const dadosPrinterconnect = dadosUsuario.TB01008_TOKEN.split('-')
    
    
    React.useEffect(()=>{
        
        getData();
        
        getUltimosAlertasDashboard();
        getAlertaTonerSite()
        getEquipamentosSemColetaOnclick();
        getDcasDashboardChart();
        const timer = setInterval(()=>{
            getData();      
            getUltimosAlertasDashboard();
            getAlertaTonerSite()
            getDcasDashboardChart();
        }, 240000)

        return ()=>{
            clearInterval(timer)
        }
    },[])


  





      

    const getData =  () =>{
        setLoading(true)
        PrinterconnectV2.post('coletasController/getPrintersDashboardPainel?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
               
               
                setPronto(response.data.payload.filter(item => item.status=='1').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setAtencao(response.data.payload.filter(item => item.status=='2').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setAlerta(response.data.payload.filter(item => item.status=='3').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
                setOffLine(response.data.payload.filter(item => item.status=='4').reduce(function(acumulador, item){
                    return acumulador + item.total;
                },0))
         
               setTotal( response.data.payload.reduce(function(a, b,){
                return a + b.total
             }, 0))
               
             
            }else{
              
            }
            setLoading(false)
        }).catch(error=>{
         
           
            setLoading(false)
        })
    }

    const getDcasDashboardChart =  () =>{
        setLoading(true)
        PrinterconnectV2.post('coletasController/getDcasDashboardChart?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){               
                var teste = Object.fromEntries(
                    Object.entries(response.data.payload)
                    .map(([ key, val ]) => {                        
                        data.push([ key, val  ])
                    })
                  );             
            }else{
                data = [];
            }
            setLoading(false)
        }).catch(error=>{
        
            setLoading(false)
        })
    }

    const getUltimosAlertasDashboard = () =>{
        PrinterconnectV2.post('coletasController/getUltimosAlertasDashboard?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
                setAlertas(response.data.payload)
            
            }else{
                setAlertas([])
            }
            setLoading(false)
        }).catch(error=>{
            setAlertas([])
            setLoading(false)
        })
    }


    const getAlertaTonerSite = () =>{
        PrinterconnectV2.post('coletasController/getAlertaTonerSite?empresa='+props.match.params.empresa,{
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
            nivel : 25
        }).then(response=>{
            if(response.data.status =='OK'){
                setSuprimentos(response.data.payload)
            
            }else{
                setSuprimentos([])
            }
            setLoading(false)
        }).catch(error=>{
            setSuprimentos([])
            setLoading(false)
        })
    }

    const getEquipamentosSemColetaOnclick = () =>{
        PrinterconnectV2.post('coletasController/getEquipamentosSemColetaOnclick', {
            idcliente : dadosPrinterconnect[2],
            idempresa : dadosPrinterconnect[1],
        }).then(response=>{
            if(response.data.status =='OK'){
                setEquipamentos(response.data.payload)
                
            }else{
                setEquipamentos([])
            }
            setLoading(false)
        }).catch(error=>{
            
            setEquipamentos([])
            setLoading(false)
        })
    }
      

 const renderSeverity = (codigo) =>{
    if(codigo == 1){
        return 'info'
    }
    if(codigo > 1 && codigo < 3 ){
        return 'warning'
    }
    if(codigo > 3){
        return 'error'
    }
    
 }

 const renderCor = (cor) =>{
     switch(cor){
       case 'CIANO': return '#1565C0';
       case 'MAGENTA': return '#AD1457';
       case 'AMARELO': return '#FF8F00';
       case 'PRETO': return '#000000';
     }
 }
      /*

      const object1 = { a: 1, b: 2, c: 3 };

const object2 = Object.fromEntries(
  Object.entries(object1)
  .map(([ key, val ]) => [ key, val * 2 ])
);

*/


    return <Principal {...props} botaoAtivo = 'dashboard' loadingIcon = {loading}>
        <div className='container-children' id='dash2'>
            
          
                <Typography variant='subtitle1'><strong>Alertas de Suprimento</strong></Typography>
                <div style ={{minHeight:'150px', display:'flex', flexWrap :'wrap'}}>
                    {
                    suprimentos && suprimentos.map(function(suprimento, i){
                        return  <div className= {'div-alert-toner-dash ' +'rotulo-'+suprimento.cor}>
                        <div style ={{display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', paddingLeft:'8px', paddingRight:'8px'}}>                            
                            <DisplayToner width={48} height={48} color={renderCor(suprimento.cor)}  percentual = {suprimento.nivelabertura}/>                            
                        </div>
                        <div style={{width:'100%', paddingTop: '0px', paddingBottom : '0px', paddingRight:'5px'}} id='divLinhas' className='texto-limitado'>
                                <div>
                                    <span style ={{fontSize:'11px', }}>{suprimento.modelo}</span>
                                </div>
                                <div>
                                    <span style ={{fontSize:'10px', marginTop:'-20px!important', }}><strong>{suprimento.numeroserie}</strong> </span>
                                </div>
                                <div>
                                <span style ={{fontSize:'10px', }}>IP:{suprimento.enderecoip}</span>
                                </div>
                                <div>
                                <span style ={{fontSize:'10px', }}>{suprimento.horaabertura ? suprimento.dataabertura+' '+suprimento.horaabertura.substr(0,8) : ''}</span>
                                </div>
                               
                         
                        </div>
                    </div>
                    })
                }
                </div>
      
          
            
        </div>

    </Principal>
}

export default withRouter(DashBoardToner)