import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import Conexao from '../../providers/Api/Api'
import Swal from 'sweetalert2'
import { Alert, AlertTitle } from '@material-ui/lab';
import GeradorLog from '../../providers/GeradorLog';

const DetalhesContrato = function(props){
    const {codigoEmpresa, numeroContrato }= React.useState(props.location.state);
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const [loading, setLoading]= React.useState(false);
    const [contrato, setContrato] = React.useState([])
    

   

    useEffect(()=>{
        GeradorLog(dadosUsuario);
        getData();
    },[])



    const getData = () =>{
        setLoading(true)
        Conexao.post('contratosController/getDetalhesContrato', {
            codigoEmpresa : props.location.state.codigoEmpresa,
            numeroContrato : props.location.state.numeroContrato
        }).then(response=>{            
            if(response.data.status =='OK'){
                setContrato(response.data.payload)                             
            }else{               
                setContrato([])
            }
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setContrato([])
        })
    }

 


    return <Principal {...props} botaoAtivo = 'contatos' loading={loading}>
        <div className='container-children'>         
            <Typography variant='h5'>Detalhes do contrato: <strong>{contrato.TB02111_CODIGO}</strong></Typography>
            <Divider/>
            <br/>
            <div style={{paddingLeft : '100px', paddingRight : '100px'}}>
            <Grid container fluid spacing={1}>
                <Grid item xs={4}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Vendedor responsável'
                        value={contrato.TB01006_NOME}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Data Inicial'
                        value={contrato.TB02111_DTINICIO ? contrato.TB02111_DTINICIO.substr(0,10) : ''}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Data Reajuste'
                        value={contrato.TB02111_DTREAJUSTE ? contrato.TB02111_DTREAJUSTE.substr(0,10) : ''}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Data Vencimento'
                        value={contrato.TB02111_VENCCONTR ? contrato.TB02111_VENCCONTR.substr(0,10) : ''}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Data Encerramento'
                        value={contrato.TB02111_DTENCERRA ? contrato.TB02111_DTENCERRA.substr(0,10) : ''}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                
            </Grid>
            <br/>
            <Grid container fluid spacing={1}>
            <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Valor PB'
                        value={contrato.TB02111_VLRNOTAPB ? contrato.TB02111_VLRNOTAPB.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'não informado'}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Valor CL'
                        value={contrato.TB02111_VLRNOTACL ? contrato.TB02111_VLRNOTACL.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : 'não informado'}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={1}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Dia Leitura'
                        value={contrato.TB02111_DIALEITURA}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Quantidade Equipamentos'
                        value={contrato.TB02111_QTDE }
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs={5}>
                    <TextField 
                        variant='outlined' 
                        fullWidth 
                        size='small'
                        label='Email cadastrado'
                        value={contrato.TB02111_EMAIL }
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                
                
                
            </Grid>
            <br/>
            <Typography variant='h6'>Informações sobre Franquias</Typography>
            <br/>
            <Table className='table table-hover table-small'>
                <TableHead>
                    <TableCell style={{width:'35%'}}><Typography variant='caption'>Nome do grupo</Typography></TableCell>
                    <TableCell style={{width:'5%'}}><Typography variant='caption'>Monocromático</Typography></TableCell>
                    <TableCell style={{width:'5%'}}><Typography variant='caption'>Color</Typography></TableCell>
                    <TableCell style={{width:'5%'}}><Typography variant='caption'>Monocromático A3</Typography></TableCell>
                    <TableCell style={{width:'5%'}}><Typography variant='caption'>Color A3</Typography></TableCell>
                    <TableCell style={{width:'5%'}}><Typography variant='caption'>Digitalização</Typography></TableCell>
                    <TableCell style={{width:'40%'}}><Typography variant='caption'>Coberturas</Typography></TableCell>
                </TableHead>
                <TableBody>
                {
                    contrato.gruposFranquia && contrato.gruposFranquia.map(function(grupo, i){
                        return <TableRow>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_NOME}</Typography></TableCell>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_FRANQPB}</Typography></TableCell>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_FRANQCOLOR}</Typography></TableCell>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_FRANQGF}</Typography></TableCell>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_FRANQGFC}</Typography></TableCell>
                        <TableCell ><Typography variant='caption'>{grupo.TB02185_FRANQDG}</Typography></TableCell>
                        <TableCell >
                           <Table className='table table-small'>
                            <TableHead>
                                <TableCell style={{width:'70%'}}><Typography variant='caption'>Nome da Cobertura</Typography></TableCell>
                                <TableCell style={{width:'30%'}}><Typography variant='caption'>Valor</Typography></TableCell>
                            </TableHead>
                            {
                                grupo.coberturas && grupo.coberturas.map(function(cobertura, i){
                                    return <TableRow key={'sub'+i}>
                                    <TableCell style={{width:'70%'}}><Typography variant='caption'>{cobertura.TB01049_NOME}</Typography></TableCell>
                                    <TableCell style={{width:'30%'}}><Typography variant='caption'>{cobertura.TB02186_valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography></TableCell>
                                </TableRow>
                                })
                            }
                           </Table>
                        </TableCell>
                    </TableRow>
                    })
                }
                </TableBody>
            </Table>
            <br/>
            <Typography variant='h6'>Últimos Faturamentos</Typography>
            <br/>
            <Table className='table table-hover table-small'>
                <TableHead>
                    <TableCell style={{width:'20%'}}><Typography variant='caption'>Recibo</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>Cliente</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>Data</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>Referência</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>V Bruto</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>V Frete</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>V Desconto</Typography></TableCell>
                    <TableCell style={{width:'10%'}}><Typography variant='caption'>V Nota</Typography></TableCell>
                </TableHead>
                <TableBody>
                    {
                        contrato.recibosLocacao && contrato.recibosLocacao.map(function(recibo, i){
                            return <TableRow>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_CODIGO}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_CODCLI}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_DATA}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_NOME}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_VLRBRUTO}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_VLRFRETE}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_VLRDESCONTO}</Typography></TableCell>
                            <TableCell ><Typography variant='caption'>{recibo.TB02118_VLRNOTA}</Typography></TableCell>
                        </TableRow>
                        })
                    }
                </TableBody>
            </Table>
            </div> 
        </div>
     
    </Principal>
}

export default withRouter(DetalhesContrato);


