import React, {Component} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab'
import {Table, TableHead, TableCell, TableBody, TableRow} from '@material-ui/core'
import Conexao from '../../../../providers/Api/PrinterconnectV2Api'
import  {
  AreaChart, 
  Area, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, 
  ResponsiveContainer
} from 'recharts';
import { Typography } from '@material-ui/core';

const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

const GraficoLinhasPreto = (props) =>{
  const [data, setData] = React.useState([])
  const [alertas, setAlerta] = React.useState([])
  const [coleta, setColeta] = React.useState([])
  const [exibeTabela, setExibeTabela] = React.useState(false)
    
  React.useEffect(()=>{
    if(props.serial != ''){
    
      getMensagensAlertaColeta();
    
     
    }else{

      alert('serial nao informado')

    }
      
  },[props.serial])


  const getMensagensAlertaColeta = async () =>{
    Conexao.post('coletasController/eventosErro?action=ative', {
      numeroserie : props.serial
    }).then(response =>{
      if(response.data.status == 'OK'){
        setAlerta(response.data.payload);
      }else{
        setAlerta([]);
      }
    }).catch(error =>{
      setAlerta([]);
    })
  }

    

   const renderSeverity = (nivel) =>{
    switch(nivel){
      case  0: return 'Geral'
      case  1: return 'Alertas';
      case  3: return 'hostResourcesMIBStorageTable';
      case  4: return 'hostResourcesMIBDeviceTable';
      case  5: return 'Geral';
      case  6: return 'Tampas';
      case  7: return 'Percurso papel';
      case  8: return 'Papel';
      case  10: return 'Níveis de suprimento';
      case  11: return 'Níveis de Suprimento';
      case  12: return 'Suprimentos Coloridos';
      case  13: return 'Trajeto do papel';
      case  14: return 'channel';
      case  15: return 'Interpretadores';
      case  16: return 'Display console';
      case  17: return 'consoleLights';
      case  501: return 'Portas abertas';
      case  801: return 'Gavetas ausentes';
      case  802: return 'Trocar tamanho do papel';
      case  803: return 'inputMediaWeightChange';
      case  804: return 'inputMediaTypeChange';
      case  805: return 'inputMediaColorChange';
      case  806: return 'inputMediaFormPartsChange';
      case  807: return 'Nível de papel baixo';
      case  808: return 'Sem papel';
      case  901: return 'Bandeja de saída ausente';
      case  902: return 'Bandeja de saída cheia';
      case  903: return 'outputMediaTrayFull';
      case  1001: return 'Níveis de Suprimento';
      case  1002: return 'Níveis de Suprimento';
      case  1003: return 'Níveis de Suprimento';
      case  1004: return 'Níveis de Suprimento';
      case  1005: return 'Níveis de Suprimento';
      case  1101: return 'Níveis de Suprimento';
      case  1102: return 'Níveis de tinta baixo';
      case  1103: return 'markerPrintRibbonEmpty';
      case  1104: return 'markerTonerAlmostEmpty';
      case  1105: return 'Sem tinta';
      case  1106: return 'markerPrintRibbonAlmostEmpty';
      case  1107: return 'Reservatório de resíduos vazio';
      case  1108: return 'Reservatório de resíduos vazio';
      case  1109: return 'markerWasteTonerReceptacleFull';
      case  1110: return 'markerWasteInkReceptacleFull';
      case  1111: return 'markerOpcLifeAlmostOver';
      case  1112: return 'markerOpcLifeOver';
      case  1113: return 'markerDeveloperAlmostEmpty';
      case  1114: return 'markerDeveloperEmpty';
  
    }

   }


   const renderCor = (nivel) =>{
    switch(nivel){
      case  1: return 'Alertas';
      case  3: return 'hostResourcesMIBStorageTable';
      case  4: return 'hostResourcesMIBDeviceTable';
      case  5: return 'Geral';
      case  6: return 'Tampas';
      case  7: return 'Percurso papel';
      case  8: return 'Papel e Atolamentos';
      case  11: return 'Níveis de Suprimento';
      case  12: return 'Suprimentos Coloridos';
      case  13: return 'Trajeto do papel';
      case  14: return 'channel';
      case  15: return 'Interpretadores';
      case  16: return 'Display console';
      case  17: return 'consoleLights';
      case  501: return 'Portas abertas';
      case  801: return 'Gavetas ausentes';
      case  802: return 'Trocar tamanho do papel';
      case  803: return 'inputMediaWeightChange';
      case  804: return 'inputMediaTypeChange';
      case  805: return 'inputMediaColorChange';
      case  806: return 'inputMediaFormPartsChange';
      case  807: return 'Nível de papel baixo';
      case  808: return 'Sem papel';
      case  901: return 'Bandeja de saída ausente';
      case  902: return 'Bandeja de saída cheia';
      case  903: return 'outputMediaTrayFull';
      case  1001: return 'Níveis de Suprimento';
      case  1002: return 'Níveis de Suprimento';
      case  1003: return 'Níveis de Suprimento';
      case  1004: return 'Níveis de Suprimento';
      case  1005: return 'Níveis de Suprimento';
      case  1101: return 'Níveis de Suprimento';
      case  1102: return 'Níveis de tinta baixo';
      case  1103: return 'markerPrintRibbonEmpty';
      case  1104: return 'markerTonerAlmostEmpty';
      case  1105: return 'Sem tinta';
      case  1106: return 'markerPrintRibbonAlmostEmpty';
      case  1107: return 'Reservatório de resíduos vazio';
      case  1108: return 'Reservatório de resíduos vazio';
      case  1109: return 'markerWasteTonerReceptacleFull';
      case  1110: return 'markerWasteInkReceptacleFull';
      case  1111: return 'Final da vida útil do cilindro';
      case  1112: return 'Final da vida útil do cilindro';
      case  1113: return 'Final da vida útil do revelador';
      case  1114: return 'Final da vida útil do revelador';
  
    }

   }

    return (
      <div style ={{width:'100%',marginTop:'30px', }}>          
          {alertas && alertas.length > 0 && <span style ={{marginTop:'20px', marginBottom:'10px'}}>*Mensagens e alertas (Ativos desde a última leitura).</span>}

          {
            alertas && alertas.map(function(alerta, i){
              return <Alert style ={{marginTop:"10px"}} severity='default'>
              <AlertTitle style ={{fontSize:'12px'}}><strong>{renderSeverity(alerta.prtalertgroupo)}</strong> - {alerta.dataalerta+' '+alerta.horaalerta.substr(0, 8)}</AlertTitle>
              
                <span style ={{fontSize:'11px'}}>{alerta.prtalertdescription}</span>
              
              
            </Alert>
            })
          }

      </div>
      
    );
  
}

export default GraficoLinhasPreto;

//  
/*
4283
4370


Z7E7BQAJ100002D
*/