import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import BotaoStatus from '../../components/BotaoOnOffAtualizcao'
import Swal from 'sweetalert2'

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNovoContato from './ModalNovoContato'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import ModalEdicaoContato from './ModalEdicaoContato'
import GeradorLog from '../../providers/GeradorLog';

const Chamados = function(props){
    const [page, setPage] = React.useState(0);
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [departamentos, setDepartamentos] = React.useState([]);
    const [contato, setContato]= useState([])


    const dadosEmpresa = props.location.state.dadosEmpresa;
    
    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
    },[])
    useEffect(()=>{
       // GeradorLog(dadosEmpresa);
    },[])

   


    const getData = () =>{
        setLoading(true)
        Conexao.post('clientesController/listarContatoSite?empresa='+props.match.params.empresa, {
            codigoCliente : dadosEmpresa.CODIGOCLIENTE, 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            pagina : page
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setDepartamentos(response.data.data)
                setRegistros(response.data.registros)
            }else{
                setDepartamentos([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error=>{
            setDepartamentos([])
            setRegistros(0)
            setLoading(false)
        })
    }

    const renderRotulo = (cor)=>{
        switch(cor){
            case 'CIANO': return '#4FC3F7';
            case 'CIAN': return '#4FC3F7';
            case 'MAGENTA': return '#F50057';
            case 'AMARELO': return '#FDD835';
            case 'PRETO': return '#000';
        }
    }

    const renderStatusIcon = (status,descricao)=>{

        switch(status){
            case 1:return <span className='labelDefault'>ANDAMENTO</span>
            case 2:return <span className='labelSucess'>{descricao}</span>
            case 3:return <span className='labelSucess'>{descricao}</span>
            case 23:return <span className='labelPreSucess'>{descricao}</span>
            case 7:return <span className='labelInfo'>{descricao}</span>
            case 22:return <span className='labelInfo'>{descricao}</span>
            case 24:return <span className='labelInfo'>{descricao}</span>
            case 25:return <span className='laberlWarning'>{descricao}</span>
            case 26:return <span className='laberlWarning'>{descricao}</span>
            default:return <span className='labelDefault'>{descricao}</span>; 
        }
       
    }


    const renderIcone = (valor,) =>{
        switch(valor){
            case 'PCONNECT':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>PRINTERCONNECT</div>
            case 'ALERTA':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>ALERTA</div>
            case 'MANUAL':
                return <div  style={{fontSize:"9px"}}><CallTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>MANUAL</div>
            case 'SITE':
                return <div  style={{fontSize:"9px"}}><LaptopChromebookTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>SITE</div>
            case 'INSTALAÇÃO':
                return <div  style={{fontSize:"9px"}}><DescriptionTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>INSTALAÇÃO</div>
            case 'REPOSICAO':
            return <div  style={{fontSize:"9px"}}><ThreeSixtyTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>REPOSIÇÃO</div>
            
            
        }
    }

    return <Principal {...props} botaoAtivo = 'contatos' loading={loading}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosEmpresa
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Contratos</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}                
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {3}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'descricao'>Descricao</option>
                            <option value = 'responsavel'>Nome do Responsavel</option>
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'descricao'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'25px'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' style={{width: '20%'}}>Descrição</TableCell>
                            <TableCell align='left' style={{width: '20%'}}>Responsável</TableCell>
                            <TableCell align='left' style={{width: '40%'}}>Email</TableCell>
                            <TableCell align='center' style={{width: '10%'}}>Toner</TableCell> 
                            <TableCell align='center' style={{width: '10%'}}>Chamado</TableCell> 
                            <TableCell align='center' style={{width: '10%'}}>Editar</TableCell> 
                            <TableCell align='center' style={{width: '5%'}}>Excluir</TableCell> 
                        </TableRow>                       
                    </TableHead>
                    <TableBody>
                        {
                            departamentos && departamentos.map(function(departamento, i){
                                return <TableRow key ={i}>
                                <TableCell align='left' >
                                    <Typography variant='caption'>{departamento.CONTATO}</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption' style ={{fontSize:'10px'}} color='primary'>{departamento.NOME}</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption'>{departamento.EMAIL}</Typography>
                                </TableCell>                        
                                <TableCell align='center'>
                                    <BotaoStatus ativo={departamento.TONER} url={'clientesController/updateNotificacaoContato?empresa='+props.match.params.empresa+'&cliente='+dadosEmpresa.CODIGOCLIENTE+'&contato='+departamento.SEQUENCIA+'&action=toner&opcao='+departamento.TONER} updateStatus={(status)=>{
                                        getData()
                                    }}/>
                                </TableCell>   
                                <TableCell align='center' >
                                    <BotaoStatus  ativo={departamento.CHAMADO}  url={'clientesController/updateNotificacaoContato?empresa='+props.match.params.empresa+'&cliente='+dadosEmpresa.CODIGOCLIENTE+'&contato='+departamento.SEQUENCIA+'&action=chamado&opcao='+departamento.CHAMADO} updateStatus={(status)=>{
                                        getData()
                                    }}/>
                                </TableCell> 
                                <TableCell align='center' >
                                    <ModalEdicaoContato contato={departamento} empresa = {props.match.params.empresa} Conexao={Conexao} cliente={dadosEmpresa.CODIGOCLIENTE}/>
                                </TableCell>         
                                <TableCell align='center' >
                                    <Button variant='outlined' color='secondary' onClick={()=>{
                                        Swal.fire({
                                            title :'Gestão de Setores',
                                            title : 'Deseja realmente remover este departamento?',
                                            icon :'question', 
                                            showConfirmButton : true, 
                                            confirmButtonText: 'Sim',
                                            showCancelButton:true, 
                                            cancelButtonText : 'Não'
                                        }).then(resp=>{
                                            if(resp.isConfirmed){
                                                Conexao.post('equipamentosController/removeDepartamentosCliente?empresa='+props.match.params.empresa, {
                                                    codigoCliente : dadosEmpresa.CODIGOCLIENTE, 
                                                    codigo : departamento.CODDEPTO
                                                }).then(response=>{
                                                    if(response.data.status =='OK'){
                                                        Swal.fire({
                                                            title : 'Departamentos', 
                                                            text : 'Departamento removido com sucesso!', 
                                                            icon : 'success', 
                                                            timer : 1000, 
                                                            showConfirmButton : false, 
                                                            showCancelButton:false
                                                        })
                                                        getData()
                                                    }else{
                                                        Swal.fire({
                                                            title : 'Departamentos', 
                                                            text : 'Não foi possível remover', 
                                                            icon : 'warning', 
                                                            timer : 1000, 
                                                            showConfirmButton : false, 
                                                            showCancelButton:false
                                                        })
                                                    }
                                                }).catch(error=>{
                                                    Swal.fire({
                                                        title : 'Departamentos', 
                                                        text : 'Não foi possível remover', 
                                                        icon : 'error', 
                                                        timer : 1000, 
                                                        showConfirmButton : false, 
                                                        showCancelButton:false
                                                    })
                                                })
                                            }
                                        })
                                    }}>
                                        <DeleteIcon />
                                    </Button>
                                </TableCell>                             
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>      
        </div>
        <div className='botaoAcoes'>
                <ModalNovoContato contato = {contato} empresa = {props.match.params.empresa} clearContato = {()=>{setContato([])}} Conexao={Conexao} cliente={dadosEmpresa.CODIGOCLIENTE} />
        </div>
    </Principal>
}

export default withRouter(Chamados)