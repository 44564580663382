import React from 'react';
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetalhesIcon from '@material-ui/icons/FormatListBulleted'
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Divider, Typography , Fab, Badge, InputAdornment, CircularProgress} from '@material-ui/core';
import DetalhesColeta from './Printerconnect/GridDisplayToner'
import Swal from 'sweetalert2';

import ModalPedidoSuprimento from './ModalPedidoSuprimento'
import ModalOrdemServico from './ModalAberturaChamado'
import ModalVideo from '../../components/base/ModalVideo';
import GeradorLog from '../../providers/GeradorLog';
import GeradorLogErro from '../../providers/GeradorLogErro';
const  ModalDetalhes = function(props) {
  
  const [open, setOpen] = React.useState(false);
  const {userType} = React.useState(props.location.state.dadosEmpresa);
  const [departamentoAtualizado, setDepartamentoAtualizado] = React.useState(false);
  const [setor, setSetor]= React.useState('');
  const [departamentos, setDepartamentos] = React.useState([]);
  const [coleta, setColeta] = React.useState([]);
  const [contadorMono, setContadorMono]= React.useState(0);
  const [contadorColor, setContadorColor]= React.useState(0);
  
  const refDepartamento = React.useRef();

  const handleClickOpen = () => { 
    if(props.Conexao){
      getLocaisInstalacaoContrato();
    }    
    
    setOpen(true)
    setDepartamentoAtualizado(false)
      GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[ABERTO]')
  };

  const handleClose = () => {
    setOpen(false);
  };
  const equipamento = props.equipamento

  const getLocaisInstalacaoContrato = () =>{
    props.Conexao.post('contratosController/getDepartamentosContratosWeb',{
     tipoFiltro:'numeroContrato', 
     fitro : '', 
     codigoEmpresa : equipamento.TB02176_CODEMP, 
     codigoCliente : equipamento.TB02111_CODCLI, 
     codigoSite : equipamento.TB02112_CODSITE
    }).then(response=>{
        if(response.status == 200 && response.data.status == 'OK'){
          setDepartamentos(response.data.payload.sort((a, b)=> a.TB02177_NOME.localeCompare(b.TB02177_NOME)))
        }else{
          setDepartamentos([])
        }
    }).catch(error=>{
      setDepartamentos([])
    })
  }
  

  

//getNotasFiscaisContrato
  return (
    <div>

        <Button onClick={handleClickOpen} className={open ? 'button-default' : 'button-default-outlined'}><DetalhesIcon/></Button>
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        fullScreen
       >
        <DialogTitle id="alert-dialog-title">
        <ModalVideo gerarLogSite={(op)=>props.gerarLogSite('[MODAL AJUDA]-[DETALHES EQUIPAMENTO]')}  >
            <iframe 
                width="900" 
                height="500" 
                src="https://www.youtube.com/embed/hD4vdbstQ7M" 
                title="Detalhes do Equipamento." 
                frameborder="0" 
                allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                    
            </iframe>
            </ModalVideo>  
            <Grid container spacing={2}>
              <Grid item xs={8}>
              Equipamento <strong>{equipamento.TB01010_NOME}</strong>
              </Grid>
              
              <Grid item xs ={2}>
                    <ModalPedidoSuprimento 
                      Conexao={props.Conexao} 
                      empresa={props.empresa} 
                      dadosEmpresa = {props.dadosEmpresa}
                      state ={props.state}
                      botaoGrande={true} 
                      gerarLogSite = {()=>props.gerarLogSite('[MODAL DETALHES]-[MODAL SUPRIMENTOS]')}
                      setLoading={(opt)=>props.setLoading(opt)} 
                      equipamento={props.equipamento} 
                      closeModalDetalhes = {()=>setOpen(false)}/>
                </Grid>
                <Grid item xs ={2}>
                    <ModalOrdemServico 
                      Conexao = {props.Conexao} 
                      empresa = {props.empresa} 
                      dadosEmpresa = {props.dadosEmpresa}
                      botaoGrande = {true} 
                      gerarLogSite = {()=>props.gerarLogSite('[MODAL DETALHES]-[MODAL CHAMADOS]')}
                      setLoading = {(opt)=>props.setLoading(opt)} 
                      equipamento = {props.equipamento} 
                      closeModalDetalhes = {()=>setOpen(false)}/>
                
                </Grid>
            </Grid>
            </DialogTitle>
        <DialogContent dividers style ={{paddingLeft:'4%', paddingRight:'4%'}}>         
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs ={1}>
                    <Typography variant='subtitle2'>PAT</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_PAT}</Typography>
                </Grid>
                <Grid item xs ={2}>
                    <Typography variant='subtitle2'>Número de Série</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_NUMSERIE}</Typography>
                </Grid>
                                
                <Grid item xs ={2}>
                    <Typography variant='subtitle2'>Colorido?</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_TIPOEQUIP != 'M' ? 'Colorido' : 'Monocromático'}</Typography>
                </Grid>

                <Grid item xs ={2}>
                    <Typography variant='subtitle2'>Site (local)</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02177_NOME }</Typography>
                </Grid>
                <Grid item xs ={3}>
                <Typography variant='subtitle2'>Departamento/Setor</Typography>
                  <TextField
                      inputRef={refDepartamento}
                      select 
                      disabled={userType == 'MASTERUSER'}
                      SelectProps={{native:true}}
                      fullWidth
                      InputLabelProps={{shrink:true}}
                      variant='outlined'
                      size='small'                       
                      onChange={(e)=>{       
                        GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[DADOS ALTERADOS]')                
                        setDepartamentoAtualizado(true)
                      }}  
                                         
                    >
                      <option>{equipamento.TB02177_NOME}</option>
                      <option value=''> </option>
                      {
                        departamentos && departamentos.map(function(departamento, i){
                          return <option value={departamento.TB02177_CODIGO+'#'+departamento.TB02177_NOME}>{departamento.TB02177_NOME}</option>
                        })
                      }
                  </TextField> 
                  <Typography variant='caption' style={{color: '#f00', fontSize:'9px'}}>*Caso não exista o departamento, solicite a sua inclusão junto à {props.location.state.logotipo ? props.location.state.logotipo.toUpperCase()+'.' : ' empresa responsável pelo seu contrato.'}</Typography> 
                </Grid>
                <Grid item xs={1}>
                      {departamentoAtualizado && <Button size='small' onClick={()=>{
                        if(departamentoAtualizado){
                          Swal.fire({
                            title : 'Corporate', 
                            text : 'Deseja atualizar a informação de departamento?', 
                            icon : 'question', 
                            showConfirmButton:true, 
                            confirmButtonText:'Sim', 
                            showCancelButton:true, 
                            cancelButtonText:'Não'
                          }).then(resposta=>{
                            if(resposta.isConfirmed){
                              GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[ACTION GRAVAR]') 
                              props.Conexao.post('equipamentosController/updateSetorEquipamento',{
                                codigoContrato :equipamento.TB02112_CODIGO , 
                                numeroSerie : equipamento.TB02112_NUMSERIE,
                                setor :refDepartamento.current ? refDepartamento.current.value : ''
                                
                               }).then(response=>{
                                  console.log(response.data)
                                   if(response.status == 200 && response.data.status == 'OK'){
                                    GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[DADOS ALTERADOS COM SUCESSO]') 
                                      Swal.fire({
                                        title : 'Corporate Web', 
                                        text : 'Departamento alterado com sucesso!', 
                                        icon : 'success', 
                                        showConfirmButton: false, 
                                        showCancelButton: false, 
                                        timer: 1000
                                      })
                                   }else{
                                    GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[FALHA AO ALTERAR]') 
                                      Swal.fire({
                                        title : 'Corporate Web', 
                                        text : 'Não foi possível alterar!', 
                                        icon : 'warning', 
                                        showConfirmButton: false, 
                                        showCancelButton: false, 
                                        timer: 1000,
                                        backdrop :"rgba(255, 0, 0, 0.2)"
                                      })
                                   }
                               }).catch(error=>{
                                GeradorLogErro(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[ERRO AO ALTERAR]', error)
                                  Swal.fire({
                                    title : 'Corporate Web', 
                                    text : 'Não foi possível alterar!', 
                                    icon : 'warning', 
                                    showConfirmButton: false, 
                                    showCancelButton: false, 
                                    timer: 1000,
                                    backdrop :"rgba(255, 0, 0, 0.2)"
                                  })
                               })
                              
                            }
                          })
                        }
                      }} className='button-success70' style={{height : '40px'}}> Atualizar</Button>}
                </Grid>
                <Grid item xs={1}>
                      {departamentoAtualizado && <Button size='small' onClick={()=>{
                        if(departamentoAtualizado){
                          GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[COMBO DEPARTAMENTOS]-[BOTÃO CANCELAR CLICADO]') 
                          setDepartamentoAtualizado(false)
                          
                        }
                      }} className='button-error70' style={{height : '40px'}}>Cancelar</Button>}
                </Grid>
            </Grid>
        
           

           
            <DetalhesColeta 
              setLoading = {(opt)=>props.setLoading(opt)} 
              numeroSerie = {props.equipamento.TB02112_NUMSERIE}  
              setColeta = {(coleta)=>setColeta(coleta)}
              coleta={coleta}
              dadosEmpresa = {props.dadosEmpresa} empresa={props.empresa}
             />
            
            
        </DialogContent>
        <DialogActions>
           <Grid container spacing = {1}>
             <Grid item xs ={10}></Grid>
             <Grid item xs ={2}>
              <Button variant='contained' size='large' fullWidth color='secondary' onClick={()=>{
                GeradorLog(props.dadosEmpresa, '[MODAL DETALHES]-['+equipamento.TB02112_NUMSERIE+']-[FECHADO]')
                  setOpen(false)
              }}>fechar</Button>
             </Grid>
           </Grid>
            
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withRouter(ModalDetalhes)