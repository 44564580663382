import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, Checkbox} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'

import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNovoUsuario from './ModalNovoUsuario';
import DeleteIcon from '@material-ui/icons/DeleteForever'
import ModalImpressorasVinculadas from './ModalImpressorasVinculadas';
import ModalVideo from '../../components/base/ModalVideo';
import ModalNovoContatoEmail from './ModalNovoContatoEmail';
import GeradorLog from '../../providers/GeradorLog';

const EmailsNotificacoes = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    
    const {printerconnect, codigoEmpresa }= props.location.state;
    
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [usuarios, setUsuarios] = React.useState([]);


    const [hideNovoButton, setHideNovoButton] = React.useState(false);
  

    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
    },[])


    useEffect(()=>{
        GeradorLog(dadosUsuario);
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)
   

    const getData = () =>{
        setLoading(true)
        Conexao.post('clientesController/getContatosNotificacao?codigoEmpresa='+codigoEmpresa, {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            codigoEmpresa : codigoEmpresa, 
           
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setRegistros(response.data.payload)
                setUsuarios(response.data.payload)
                
            }else{
                setRegistros([])
                setUsuarios([])
            }
            setLoading(false)
        }).catch(error=>{
            setRegistros([])
            setUsuarios([])
            setLoading(false)
        })
    }

    const renderRotulo = (cor)=>{
        switch(cor){
            case 'CIANO': return '#4FC3F7';
            case 'CIAN': return '#4FC3F7';
            case 'MAGENTA': return '#F50057';
            case 'AMARELO': return '#FDD835';
            case 'PRETO': return '#000';
        }
    }

    const renderStatusIcon = (status,descricao)=>{

        switch(status){
            case 1:return <span className='labelDefault'>ANDAMENTO</span>
            case 2:return <span className='labelSucess'>{descricao}</span>
            case 3:return <span className='labelSucess'>{descricao}</span>
            case 23:return <span className='labelPreSucess'>{descricao}</span>
            case 7:return <span className='labelInfo'>{descricao}</span>
            case 22:return <span className='labelInfo'>{descricao}</span>
            case 24:return <span className='labelInfo'>{descricao}</span>
            case 25:return <span className='laberlWarning'>{descricao}</span>
            case 26:return <span className='laberlWarning'>{descricao}</span>
            default:return <span className='labelDefault'>{descricao}</span>; 
        }
       
    }


    const renderIcone = (valor,) =>{
        switch(valor){
            case 'PCONNECT':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>PRINTERCONNECT</div>
            case 'ALERTA':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>ALERTA</div>
            case 'MANUAL':
                return <div  style={{fontSize:"9px"}}><CallTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>MANUAL</div>
            case 'SITE':
                return <div  style={{fontSize:"9px"}}><LaptopChromebookTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>SITE</div>
            case 'INSTALAÇÃO':
                return <div  style={{fontSize:"9px"}}><DescriptionTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>INSTALAÇÃO</div>
            case 'REPOSICAO':
            return <div  style={{fontSize:"9px"}}><ThreeSixtyTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>REPOSIÇÃO</div>
            
            
        }
    }

    return <Principal {...props} botaoAtivo = 'emails-notificacao' loading={loading}>
        <div className='container-children'> 
       
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Destinos de Notificação</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros.length}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}   
                    labelRowsPerPage="Linhas por página:"                
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {3}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        
                        inputRef={refTipoFiltro}>
                            <option value = 'nomeCompleto'>Nome Completo</option>                            
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                setUsuarios(registros.filter(entity => entity.nomeCompleto.toUpperCase() == e.target.value.toUpperCase()))
                            }
                        }}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'descricao'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                    <TableHead>
                        <TableRow>                           
                            <TableCell align='left' style={{width: '20%'}}>Nome Completo</TableCell>
                            <TableCell align='center' style={{width: '10%'}}>Contrato</TableCell> 
                            <TableCell align='left' style={{width: '20%'}}>Site</TableCell> 
                            <TableCell align='left' style={{width: '15%'}}>Departamento</TableCell>                           
                            <TableCell align='left' style={{width: '20%'}}>Email</TableCell>
                            <TableCell align='center' style={{width: '5%'}}>Toner</TableCell>
                            <TableCell align='center' style={{width: '5%'}}>Chamado</TableCell>
                            <TableCell align='center' style={{width: '5%'}}>Excluir</TableCell>  
                        </TableRow>                       
                    </TableHead>
                    <TableBody>
                        {
                            usuarios && usuarios.map(function(site, i){
                                return <TableRow>                           
                                <TableCell align='left'>{site.nomeCompleto}</TableCell>
                                <TableCell align='center'>{site.contrato ? site.contrato : '**TODOS**'}</TableCell>
                                <TableCell align='left'>{site.TB02176_NOME ? site.TB02176_NOME : '**TODOS**'}</TableCell>
                                <TableCell align='left'>{site.TB02177_NOME ? site.TB02177_NOME : '**TODOS**'}</TableCell>                           
                                <TableCell align='left'>{site.email}</TableCell>
                                <TableCell align='center'><Checkbox checked={site.notificarToner =='t'} color='primary'/></TableCell>
                                <TableCell align='center'><Checkbox checked={site.notificarChamado =='t'} color='primary' /></TableCell>
                                <TableCell align='center'>{site.codigoCliente ? <Button variant='outlined' color='secondary' onClick={()=>{
                                    Swal.fire({
                                        title : 'Corporate Web', 
                                        text  : 'Deseja remover este contato?', 
                                        icon : 'question', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Sim', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Não'
                                      }).then(resposta=>{
                                        if(resposta.isConfirmed){
                                          Conexao.post('clientesController/deleteContatoNotificacao', {
                                            codigo : site.codigo, 
                                            codigoCliente : site.codigoCliente
                                          }).then(response=>{
                                            if(response.status == 200 && response.data.status =='OK'){
                                                Swal.fire({
                                                    title : 'Corporate Web', 
                                                    text : 'Contato adicionado com sucesso!', 
                                                    icon : 'success', 
                                                    showCancelButton: false, 
                                                    showConfirmButton: false, 
                                                    timer : 1500
                                                  })
                                                  setUsuarios(usuarios.filter(item => item.codigo != site.codigo))
                                            }else{
                                                Swal.fire({
                                                    title : 'Corporate Web', 
                                                    text : 'Não foi possível remover contato!', 
                                                    icon : 'success', 
                                                    showCancelButton: false, 
                                                    showConfirmButton: false, 
                                                    timer : 1500
                                                  })
                                            }
                                          }).catch(error=>{
                                            Swal.fire({
                                                title : 'Corporate Web', 
                                                text : 'Não foi possível remover contato!', 
                                                icon : 'error', 
                                                showCancelButton: false, 
                                                showConfirmButton: false, 
                                                timer : 1500
                                              })
                                          })
                                          
                  
                  
                                          
                                        }
                                      })

                                    
                                }}><DeleteIcon/></Button> : <Typography variant='caption'>**PADRÃO**</Typography>}</TableCell>  
                            </TableRow> 
                            })
                        }
                    </TableBody>
                </Table>   
                <div className='botaoAcoes'>
                    <ModalNovoContatoEmail getData={()=>{getData()}} showModal={false} Conexao={Conexao} cliente = {dadosUsuario.TB01066_CODCLI} empresa={dadosUsuario.operacaoEmpresa.CMQ00001_CODEMPRESA}/>
                </div>
        </div>
       
    </Principal>
}

export default withRouter(EmailsNotificacoes)