import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpIcon from '@material-ui/icons/Videocam'
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'


export default function ModalVideo(props) {
    const [open, setOpen] = React.useState(false)

    const handleOpen = () =>{
        setOpen(true)
        if(props.gerarLogSite){
          props.gerarLogSite()
        }
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
     <div style={{ color:props.color? props.color : '#0D47A1', cursor:'pointer'}}>
        <Tooltip title='Click aqui para acessar o tutorial'> 
            <HelpIcon style={{fontSize: props.iconSize ? props.iconSize : '48px'}} onClick={handleOpen}/>
        </Tooltip>
    </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullWidth
        hideBackdrop
        disableBackdropClick
      >
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            <CloseIcon />
          </Button>
          
        </DialogActions>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {
            props.children
          }
                
          </DialogContentText>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
