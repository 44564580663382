import React, {Component} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab'
import {Table, TableHead, TableCell, TableBody, TableRow, Button} from '@material-ui/core'
import Conexao from '../../../../providers/Api/PrinterconnectV2Api'
import  {
  AreaChart, 
  Area, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, 
  ResponsiveContainer
} from 'recharts';
import { Typography } from '@material-ui/core';

const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

const GraficoLinhasPreto = (props) =>{
  const [data, setData] = React.useState([])
  const [alertas, setAlerta] = React.useState([])
  const [coleta, setColeta] = React.useState([])
  const [exibeTabela, setExibeTabela] = React.useState(false)
    
  React.useEffect(()=>{
    if(props.serial != ''){
      getData();
      getMensagensAlertaColeta();
      getDetalhesColeta();
     
    }else{
      alert('serial nao informado')
    }
      
  },[props.serial])


  const getData = async () =>{
    Conexao.get('coletasController/getDataNivelTonerGrafico?numeroserie='+props.serial+'&cor='+props.cor).then(response =>{
      if(response.data.status == 'OK'){
        setData(response.data.payload);
      }else{
        setData([]);
      }
    }).catch(error =>{
      setData([]);
    })
  }

  const getMensagensAlertaColeta = async () =>{
    Conexao.post('coletasController/getMensagensAlertaColetaToner', {
      numeroserie : props.serial
    }).then(response =>{
      if(response.data.status == 'OK'){
        setAlerta(response.data.payload);
      }else{
        setAlerta([]);
      }
    }).catch(error =>{
      setAlerta([]);
    })
  }
  const getDetalhesColeta = async () =>{
    Conexao.post('coletasController/getLeitura', {
      numeroserie : props.serial, 
      idempresa : props.empresa =='centermaq' ? '2' : 3
     
    }).then(response =>{
      if(response.data.status == 'OK'){
        setColeta(response.data.payload);
      }else{
        setColeta([]);
      }
    }).catch(error =>{
      setColeta([]);
    })
  }
    

   const renderSeverity = (nivel) =>{
    switch(nivel){
      case  1101: return 'markerTonerEmpty';
      case  1102: return 'markerInkEmpty';
      case  1103: return 'markerPrintRibbonEmpty';
      case  1104: return 'markerTonerAlmostEmpty';
      case  1105: return 'markerInkAlmostEmpty';
    }

   }

    return (
      <div style ={{width:'100%'}}>
        <ResponsiveContainer width="100%" height={120}>
              <AreaChart  data={data}
              onClick={(e)=>{
                console.log(e)
              }}
            
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              
              <XAxis dataKey="datalog" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="nivel" stroke={props.preenchimento} fillOpacity={0.1} fill={props.preenchimento} />
            
            </AreaChart>
        </ResponsiveContainer>
        <div style ={{paddingLeft:'15px', paddingRight:'15px',  }}>
          <Button className='button-primary' onClick={()=>{
              setExibeTabela(!exibeTabela)
            }}>{exibeTabela ? 'Ocultar log detalhado de toner ' : 'Exibir log detalhado de toner '}</Button>
          <br/>
            { exibeTabela && <Table className='table table-sm table-bordered'>
              <TableHead>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Data</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Hora</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Nível</TableCell>
                <TableCell align='left' style = {{fontSize:'9px', width:'30%'}}>Número Série</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Toner</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Mono</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Color</TableCell>
                <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>Total</TableCell>
              </TableHead>
              <TableBody>
                {
                  data && data.map(function(nivel, i){
                    return <TableRow>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.datalog}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.horalog}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.nivel}%</TableCell>
                    <TableCell align='left' style = {{fontSize:'9px', width:'30%'}}>{nivel.numeroserieunidade}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.contadortoner}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.contadormonototal}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.contadorcolortotal}</TableCell>
                    <TableCell align='center' style = {{fontSize:'9px', width:'10%'}}>{nivel.contadortotalgeral}</TableCell>
                  </TableRow>
                  })
                }
              </TableBody>
            </Table>}
        </div>
      

      </div>
      
    );
  
}

export default GraficoLinhasPreto;

//  
/*
4283
4370


Z7E7BQAJ100002D
*/