import React from 'react';
import {withRouter} from 'react-router-dom'
import {Navbar, Container, Nav, NavDropdown, } from 'react-bootstrap'
import LogoCentermaq from '../img/logoCentermaq1.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {Typography, Divider} from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ExtensionIcon from '@material-ui/icons/Extension';
import HealingIcon from '@material-ui/icons/Healing';
import ScannerIcon from '@material-ui/icons/Scanner';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SchoolIcon from '@material-ui/icons/School';




const NavPrincipal = (props) =>{


    return <React.Fragment>
        <Navbar collapseOnSelect expand="xl"  variant="light" className='fadeIn'>
            <Container fluid className='div-mav-container'>
            <Navbar.Brand href="#home">                
                    <img src ={LogoCentermaq} />                
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link  className='menu-link'>Soluções 
                        <div className='div-menu-float'>
                            <div className='link-item' style ={{display:"flex", paddingBottom:"10px"}}>
                                <div style={{paddingTop:"5px"}}><ExtensionIcon /></div>
                                <div style={{paddingLeft:"10px"}}>
                                    <span  style = {{fontSize:"11px"}}><strong>Outsourcing de Impressão</strong></span><br/>
                                    <span  style = {{fontSize:"9px",}}>Locação de Equipamento de Impressão/Digitalização</span>
                                </div>
                            </div>
                            <Divider/>

                            <div className='link-item' style ={{display:"flex" , paddingBottom:"10px"}}>
                                <div style={{paddingTop:"5px"}}><ScannerIcon /></div>
                                <div style={{paddingLeft:"10px"}}>
                                    <Typography variant='caption' style = {{fontSize:"11px"}}><strong>Gerenciamento Eletrônico de Documentos</strong></Typography><br/>
                                    <span variant='caption' style = {{fontSize:"9px"}}>Soluções em GED/ECM</span>
                                </div>
                            </div>
                            <Divider/>

                            
                            <div className='link-item' style ={{display:"flex" , paddingBottom:"10px", }}>
                                <div style={{paddingTop:"5px"}}><ViewQuiltIcon /></div>
                                <div style={{paddingLeft:"10px"}}>
                                    <Typography variant='caption' style = {{fontSize:"11px"}}><strong>Impressão 3D</strong></Typography><br/>
                                    <span variant='caption' style = {{fontSize:"9px"}}>Venda e Locação de Equipamentos</span>
                                </div>
                            </div>
                            <Divider/>


                            <div className='link-item' style ={{display:"flex" , paddingBottom:"10px"}} onClick={()=>{
                                props.history.push({pathname : 'impressoes-3d'})
                            }}>
                                <div style={{paddingTop:"5px"}}><RecentActorsIcon /></div>
                                <div style={{paddingLeft:"10px"}}>
                                    <Typography variant='caption' style = {{fontSize:"11px"}}><strong>Outsourcing de Impressão Térmica</strong></Typography><br/>
                                    <span variant='caption' style = {{fontSize:"9px"}}>Soluções Zebra</span>
                                </div>
                            </div>
                           
                            <Divider/>
                            
                        </div>
                    </Nav.Link>
                    <Nav.Link  className='menu-link'>Segmentos 
                        <div className='div-menu-float'>
                            <div className='link-item' style ={{display:"flex", paddingBottom:"10px"}}>
                                    <div style={{paddingTop:"5px"}}><ApartmentIcon /></div>
                                        <div style={{paddingLeft:"10px"}}>
                                        <span  style = {{fontSize:"11px"}}><strong>Industria</strong></span><br/>
                                        <span  style = {{fontSize:"9px",}}>Outsourcing de Impressão/Térmica/Coletores de Dados</span>
                                    </div>
                            </div>
                            <Divider/>

                            <div className='link-item' style ={{display:"flex", paddingBottom:"10px"}}>
                                    <div style={{paddingTop:"5px"}}><SchoolIcon /></div>
                                        <div style={{paddingLeft:"10px"}}>
                                        <span  style = {{fontSize:"11px"}}><strong>Educação</strong></span><br/>
                                        <span  style = {{fontSize:"9px",}}>Outsourcing de Impressão/Térmica/Coletores de Dados</span>
                                    </div>
                            </div>
                            <Divider/>

                            <div className='link-item' style ={{display:"flex", paddingBottom:"10px"}}>
                                    <div style={{paddingTop:"5px"}}><ShoppingCartIcon /></div>
                                        <div style={{paddingLeft:"10px"}}>
                                        <span  style = {{fontSize:"11px"}}><strong>Varejo</strong></span><br/>
                                        <span  style = {{fontSize:"9px",}}>Impressoras Térmicas/Coletores de Dados</span>
                                    </div>
                            </div>
                            <Divider/>

                            <div className='link-item' style ={{display:"flex", paddingBottom:"10px"}}>
                                    <div style={{paddingTop:"5px"}}><HealingIcon /></div>
                                        <div style={{paddingLeft:"10px"}}>
                                        <span  style = {{fontSize:"11px"}}><strong>Saúde</strong></span><br/>
                                        <span  style = {{fontSize:"9px",}}>Impressoras Térmicas/Crachá/Pulseiras</span>
                                    </div>
                            </div>
                            <Divider/>

                            
                        </div></Nav.Link>
                    <Nav.Link  className='menu-link'>Institucional</Nav.Link>
                    <Nav.Link  >Blog</Nav.Link>     
                    <Nav.Link >Area do cliente</Nav.Link>  
                </Nav>
               
            </Navbar.Collapse>
            </Container>
            </Navbar>
    </React.Fragment>
}

export default withRouter(NavPrincipal)