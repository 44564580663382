function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  export default function validateEmailList(emailList) {
     
    const invalidEmails = [];
    const emails = emailList.split(',');
  
    for (let email of emails) {
        console.log(email)
      const trimmedEmail = email.trim();
      if (!isValidEmail(trimmedEmail)) {
        invalidEmails.push(trimmedEmail);
      }
    }
    
    return invalidEmails.length === 0 ? true : false;
  }
  

  