import React from 'react'
import IMGHome from './imagens/header2.png'
import {Typography, Button} from '@material-ui/core'
import Navprincipal from './NavPrincipal'
//import Particles from 'react-particles-js';
import {LinearProgress} from '@material-ui/core'
export default function(props){
    const [verParticulas, setVerParticulas] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    let interval = null;
    
    React.useEffect(()=>{
        setTimeout(()=>{
            setVerParticulas(true)
        }, 1500)

       
    }, []);


    return <React.Fragment>
         { loading && <LinearProgress color='secondary' variant='indeterminate' style={{width:'100%'}}/>}
            <Navprincipal {...props} setLoading={(op)=>{setLoading(op)}} /><div id="div-banner-home">
            <img src= {IMGHome} className='img-banner-home'/> 

            <div className='banner-home-overlay'>
                { /*verParticulas && <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 150
                            },
                            "size": {
                                "value": 2
                            }, 
                            "color":"#f00", 
                            "stroke":"#f00"
                            
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            }
                        }
                    }} />*/}
                    <div id = 'label-banner'>
                        <Typography variant='h2' >Soluções em <strong>Outsourcing</strong> de Impressão e <strong>Gerencimento</strong> de Documentos.</Typography>
                        <br/>
                        <br/>
                         <Button variant='contained' color='secondary'>Saiba mais</Button>
                    </div>
                
            </div>
    </div>
    </React.Fragment>
}