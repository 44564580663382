import React from 'react'

import PrintIcon from '@material-ui/icons/Print';
import BuildIcon from '@material-ui/icons/Build';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Search from '@material-ui/icons/Search'
import TrendingUp from '@material-ui/icons/TrendingUp'
import Dashboard from '@material-ui/icons/Dashboard'



export default function(props){
    return <div id='cards-atuacao-main'>
        <div className='card-atuacao-item'>
            <PrintIcon />         
            <Typography variant='h5' color='primary'>Atualização tecnológica</Typography>       
            <p>Os mais modernos equipamentos de impressão laser, 3D, térmicas, coletores de dados....</p>
        </div>
        <div className='card-atuacao-item'>
            <BuildIcon />                
            <Typography variant='h5' color='primary'>Equipe Técnica</Typography>            
            <p>Equipe técnica altamente capacitada, fornecendo serviços de qualidade de forma ágil e eficiênte.</p>
        </div>
        <div className='card-atuacao-item'>
            <AttachMoneyIcon />
            <Typography variant='h5' color='primary'>Economia</Typography>                 
            <p>Com eliminação dos desperdicios, a economia com impressão pode chegar até 30%.</p>
        </div>
        <div className='card-atuacao-item'>
            <Search />
            <Typography variant='h5' color='primary'>Monitoramento</Typography>          
            <p>Soluções integradas para monitoramento de parque, e auditoria de impressão.</p>
        </div>
        <div className='card-atuacao-item'>
            <TrendingUp />
            <Typography variant='h5' color='primary'>Produtividade</Typography>          
            <p>Equipamentos robustos e soluções que aumentam sua produtividade.</p>
        </div>
        <div className='card-atuacao-item'>
            <Dashboard />
            <Typography variant='h5' color='primary'>Padronização</Typography>
        
          
            <p>Centralização de seu parque, equipamentos padronizados, fornecimento de suprimentos de forma ágil.</p>
        </div>
       
    </div>
}