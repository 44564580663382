import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import DetalhesIcon from './ModalDetalhes'
import Swal from 'sweetalert2'
import ModalPedidoSuprimentos from './ModalPedidoSuprimento'
import ModalAberturaChamado from './ModalAberturaChamado'
import DisplayNivelToner from '../../components/DisplayNivelToner/DisplayNivelToner'
import ModalVideo from '../../components/base/ModalVideo';
import GeradorLog from '../../providers/GeradorLog';
import GeradorLogErro from '../../providers/GeradorLogErro';
import NivelToner from './Printerconnect/NivelToner';

let firstOpen = true


const Equipamentos = function(props){
    const [page, setPage] = React.useState(0);
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const [coletas, setColetas]= React.useState(props.location.state.dataColetas);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)
    const [paginas, setPaginas]= useState(0)

    const [equipamentos, setEquipamentos] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [contatos, setContatos] = useState([])
    const dadosEmpresa = props.location.state.dadosEmpresa;

    const refTipoFiltro = useRef();
    const refFiltro = useRef();

    let chavePrinterconnect = dadosUsuario.TB01008_TOKEN ? dadosUsuario.TB01008_TOKEN.split('-') : '';

    React.useEffect(()=>{
        console.log(dadosUsuario)
        if(props.location.state.tipoFiltro){
            refTipoFiltro.current.value = props.location.state.tipoFiltro
            refFiltro.current.value = props.location.state.filtro
        }
       // gerarLogSite('');
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)
    const gerarLogSite = (tela ) =>{
        GeradorLog(dadosUsuario, tela);
       
    }

    React.useEffect(()=>{
        if(!firstOpen){
            getData();
            
        }        
    },[rowsPerPage, page])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        //alert(newPage)
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTimeout(()=>{
            getData()
        },500)
    };

    useEffect(()=>{
        getData('T')
        firstOpen=true
       
    },[])

    useEffect(()=>{
        
        setTimeout(()=>{
            getData('T')
        },500)
    },[page])


    const getData = async (optionDepartamento = 'F') =>{
      
        setLoading(true)
        GeradorLog(dadosUsuario, '[EQUIPAMENTOS]-[TIPO FILTRO]-['+(refTipoFiltro.current ? refTipoFiltro.current.value : '')+']-['+ (refFiltro.current ? refFiltro.current.value : '')+']-[GET DATA]: 0 localizado(s)');
        Conexao.post('equipamentosController/getEquipamentosByContratos?codigoEmpresa='+codigoEmpresa,{
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            codigoEmpresa : codigoEmpresa,
            equipamentos : dadosEmpresa.equipamento, 
            userName : dadosUsuario.TB01066_LOGIN, 
            userType :  dadosUsuario.userType,
            naoPermitidos : dadosUsuario.userEquipamentos == 'full' ? 'not' : '', 
            tipoFiltro : refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro : refFiltro.current ? refFiltro.current.value : '', 
            listaContratos : dadosUsuario.contratos.map(function(contrato){
                return contrato.numeroContrato
            }), 
            codigoEmpresa : codigoEmpresa,
            registros : rowsPerPage, 
            pagina : page + 1, 
            getDepartamentos : optionDepartamento

        }).then(response=>{
            if(response.data.status =='OK'){
                setEquipamentos(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
                setPaginas(response.data.payload.paginas)
                GeradorLog(dadosUsuario, '[EQUIPAMENTOS]-[GET DATA] '+response.data.payload.registros+' localizado(s)');
                
            }else{
                setEquipamentos([])
                setRegistros(0)
                setPaginas(0)
                Swal.fire({
                    title : 'Corporate Web', 
                    text : 'Não existem informações para exibir!', 
                    icon : 'question', 
                    showConfirmButton:true, 
                    showCancelButton : false
                })
                GeradorLog(dadosUsuario, '[EQUIPAMENTOS]-[GET DATA]  0 localizado(s)');
            }
            setLoading(false)
        }).catch(error=>{
            GeradorLogErro(dadosUsuario, '[EQUIPAMENTOS]-[GET DATA] ', error);
            setEquipamentos([])
            setRegistros(0)
            setPaginas(0)
            setLoading(false)
        })
    }


    return <Principal {...props} botaoAtivo = 'equipamentos' loading={loading}>
        <div className='container-children' >
        <ModalVideo  gerarLogSite = {()=>gerarLogSite('[MODAL AJUDA EQUIPAMENTOS]')}>
            <iframe 
                width="900" 
                height="500" 
                src="https://www.youtube.com/embed/03ZQaV1zuo0" 
                title="Equipamentos." 
                frameborder="0" 
                allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                    
            </iframe>
            
            
            </ModalVideo>
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosEmpresa
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Equipamentos</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}       
                    labelRowsPerPage="Linhas por página:"    
                      
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {2}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'placa'>Nº de patrimônio</option>
                            <option value = 'numeroSerie'>Nº de série</option>
                            <option value = 'contrato'>Nº do Contrato</option>                                                        
                            <option value = 'modelo'>Modelo</option>
                            <option value = 'site'>Descrição Site</option>
                            <option value = 'departamento'>Departamento</option>
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData();
                            }
                        }}
                        InputLabelProps={{shrink : true}}
                        />
                </Grid>
                <Grid item xs = {2}>
                    <Button fullWidth  className='button-default' onClick={()=>getData()}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error'><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
            
            <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' style={{width: '2%'}}>Pat</TableCell>
                        <TableCell align='center' style={{width: '8%'}}>Número Série</TableCell>                        
                        <TableCell align='left' style={{width: '15%'}}>Modelo</TableCell>
                        <TableCell align='left' style={{width: '15%'}}>Site</TableCell>
                        <TableCell align='left' style={{width: '12%'}}>Departamento</TableCell>
                        <TableCell align='center' style={{width: '5%'}}>Contrato</TableCell>
                        {/*<TableCell align='center' style={{width: '10%'}}>Níveis Suprimentos</TableCell>*/}
                        <TableCell align='center' style={{width: '4%'}}>Detalhes</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>Suprimento</TableCell>
                        <TableCell align='center' style={{width: '4%'}}>Chamado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        equipamentos && equipamentos.map(function(equipamento, i){
                            //const dadosColetados = coletas.filter(item => item.numeroserie == equipamento.TB02112_NUMSERIE);
                            
                            return <TableRow key={i}>
                            <TableCell align='center' >{equipamento.TB02112_PAT && <Typography variant='caption'><span className='display-placa'>{equipamento.TB02112_PAT}</span></Typography>}</TableCell>
                            <TableCell align='center' ><Typography variant='caption'>{equipamento.TB02112_NUMSERIE}</Typography></TableCell>
                            
                            <TableCell align='left' ><Typography variant='caption' className='texto-limitado'>{equipamento.TB01010_NOME}</Typography></TableCell>
                            <TableCell align='left' >
                                <Typography variant='caption'>{equipamento.TB02176_NOME}</Typography><br/>
                               
                            </TableCell>
                            <TableCell align='left' >
                               
                                <Typography variant='caption'>{equipamento.TB02177_NOME}</Typography>
                            </TableCell>
                            <TableCell align='center' ><Typography variant='caption'>{equipamento.TB02112_CODIGO }</Typography></TableCell>
                            {/*<TableCell align='center' style={{width: '10%'}}>
                                {(dadosColetados[0] && dadosColetados[0].dias <= 3 )? <div className='display-flex'>

                                    <NivelToner width={46} height={46} rotulo ='ciano' color='#42A5F5' percentual={dadosColetados[0] ? dadosColetados[0].nivelciano:''} hide={true}/>

                                    <NivelToner width={46} height={46} rotulo ='magenta' color='#E91E63' percentual={dadosColetados[0] ? dadosColetados[0].nivelmagenta:''} hide={true}/>

                                    <NivelToner width={46} height={46} rotulo ='amarelo' color='#FFCA28' percentual={dadosColetados[0] ? dadosColetados[0].nivelamarelo:''} hide={true}/>

                                    <NivelToner width={46} height={46} rotulo ='preto' color='#000' percentual={dadosColetados[0] ? dadosColetados[0].nivelpreto:''} hide={true}/>
                                </div>: <Typography variant = 'caption'>*Sem informações</Typography>}

                        </TableCell>*/}
                            <TableCell align='center' >
                           
                                <DetalhesIcon 
                                    departamentos={departamentos} 
                                    Conexao={Conexao} 
                                    empresa={chavePrinterconnect[1]} 
                                    setLoading={(opcao)=>setLoading(opcao)} 
                                    equipamento={equipamento} 
                                    state ={props.location.state}
                                    gerarLogSite = {(op)=>gerarLogSite(op)}
                                    dadosEmpresa={dadosEmpresa}/></TableCell>
                            <TableCell align='center' >
                                <ModalPedidoSuprimentos 
                                    Conexao={Conexao}
                                    empresa={props.match.params.empresa}
                                    setLoading={(opt)=>setLoading(opt)} 
                                    loading={loading}
                                    equipamento={equipamento} 
                                    dadosEmpresa={dadosEmpresa} 
                                    state ={props.location.state}
                                    gerarLogSite = {(op)=>gerarLogSite(op)}
                                    closeModalDetalhes = {()=>{
                                        return null;
                                    }}/></TableCell>
                            <TableCell align='center' >
                                <ModalAberturaChamado 
                                    Conexao={Conexao}
                                    empresa={props.match.params.empresa}
                                    setLoading={(opt)=>setLoading(opt)} 
                                    gerarLogSite = {(op)=>gerarLogSite(op)}
                                    loading={loading}
                                    equipamento={equipamento} 
                                    dadosEmpresa={dadosEmpresa} 
                                    state ={props.location.state}
                                    closeModalDetalhes = {()=>{
                                        return null;
                                    }}/>
                       </TableCell>
                        </TableRow>
                        })
                    }
                    {
                        equipamentos && equipamentos.length == 0 && <TableRow >
                        <TableCell align='left' colSpan={8} >* Não há registros.</TableCell>
                        
                    </TableRow>
                    }
                </TableBody>
            </Table>      
        </div>

    </Principal>
}

export default withRouter(Equipamentos)