import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import SaveIcon from '@material-ui/icons/Save'
import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNovoUsuario from './ModalNovoUsuario';
import DeleteIcon from '@material-ui/icons/DeleteForever'
import ModalImpressorasVinculadas from './ModalImpressorasVinculadas';

const TrocarSenha = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    const [loading, setLoading] = React.useState(false)
   console.log(dadosUsuario)
   

   
  

    const refSenha = useRef();
    const refNovaSenha = useRef();
    const refConfirmaSenha = useRef();

   


    

    

    

    return <Principal {...props} botaoAtivo = 'trocar-senha' loading={loading}>
        <div className='container-children'>         
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Trocar Senha</Typography>
                </Breadcrumbs>
                </div>
                
               
                
            </div>
           <br/>
            <Divider/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{textAlign:'right', paddingTop:'10px'}}>
                    <Typography variant='subtitle1' >Senha Atual</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant='outlined'
                        size='large'
                        inputRef={refSenha}
                        type='password'
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{textAlign:'right', paddingTop:'10px'}}>
                    <Typography variant='subtitle1' >Nova Senha</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant='outlined'
                        size='large'
                        inputRef={refNovaSenha}
                        type='password'
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3} style={{textAlign:'right', paddingTop:'10px'}}>
                    <Typography variant='subtitle1' >Confirmação</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant='outlined'
                        size='large'
                        type='password'
                        inputRef={refConfirmaSenha}
                        InputLabelProps={{shrink:true}}
                        fullWidth/>
                </Grid>
            </Grid>
            <br/>
            
            <br/>
            <div style={{display:'flex', justifyContent: 'center', alignContent:'center'}}>
                <Button variant='contained' color='primary' size='large' onClick={()=>{
                    if(refSenha.current && refSenha.current.value ==''){
                        Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Informe a sua senha atual!', 
                            icon : 'warning', 
                            
                            showConfirmButton : true, 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            confirmButtonText : 'OK'
                        })
                        return;
                    }

                    if(refNovaSenha.current && refNovaSenha.current.value ==''){
                        Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Informe a sua sua nova senha!', 
                            icon : 'warning', 
                            showConfirmButton : true, 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            confirmButtonText : 'OK'
                        })
                        return;
                    }else{
                        if(refNovaSenha.current && refNovaSenha.current.value.length < 6){
                            Swal.fire({
                                title : 'Corporate Web', 
                                text : 'Sua senha deve ter no mínimo 6 caracteres!', 
                                icon : 'warning', 
                                showConfirmButton : true, 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                confirmButtonText : 'OK'
                            })
                            refNovaSenha.current.value = '';
                            refConfirmaSenha.current.value =''
                            return;
                        }
                    }

                    if((refNovaSenha.current && refNovaSenha.current.value !='' ) && (refConfirmaSenha.current && refConfirmaSenha.current.value !='')){
                        if(refNovaSenha.current.value != refConfirmaSenha.current.value){
                            Swal.fire({
                                title : 'Corporate Web', 
                                text : 'A senha e a confirmação devem ser iguais!', 
                                icon : 'warning', 
                                showConfirmButton : true, 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                confirmButtonText : 'OK'
                            })
                            refConfirmaSenha.current.value = '';
                            return;
                        }
                        
                    }
                    Swal.fire({
                        title : 'Corporate Web', 
                        text : 'Deseja alterar a senha agora?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        showCancelButton : true, 
                        cancelButtonText : 'Não', 
                        confirmButtonText : 'Sim'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setLoading(true)
                            Conexao.post('clientesController/'+(dadosUsuario.userType == 'MASTERUSER' ? 'updateSenhaMaster' : 'updateSenhaSubUser'), {
                                codigoCliente : dadosUsuario.TB01066_CODCLI, 
                                userName : dadosUsuario.TB01066_LOGIN,
                                email : dadosUsuario.TB01066_EMAIL, 
                                login : dadosUsuario.TB01066_LOGIN, 
                                senha : refSenha.current ? refSenha.current.value : '', 
                                novaSenha : refNovaSenha.current ? refNovaSenha.current.value : '',
                            }).then(response=>{ 
                                console.log(response.data)                               
                                if(response.data.status =='OK'){
                                    Swal.fire({
                                        title : 'Corporate Web', 
                                        text : 'Senha alterada com sucesso !', 
                                        icon : 'success', 
                                        showConfirmButton : false, 
                                        showCancelButton : false, 
                                        timer : 1000
                                    })
                                   
                                   refSenha.current.value = '';
                                   refNovaSenha.current.value ='';
                                   refConfirmaSenha.current.value ='';
                                }else{
                                    Swal.fire({
                                        title : 'Corporate Web', 
                                        text : 'Não foi possível altear senha', 
                                        icon : 'warning', 
                                        showConfirmButton : false, 
                                        showCancelButton : false, 
                                        timer : 1000
                                    })
                                    
                                }
                                setLoading(false)
                            }).catch(error=>{
                             
                                
                                setLoading(false)
                            })
                        }
                    })
                }}><SaveIcon />Gravar</Button>
            </div>  
        </div>
       
    </Principal>
}

export default withRouter(TrocarSenha)