import React, { Component } from 'react';
import Slider from 'infinite-react-carousel';

class CustomSlider extends Component {
  render() {
    const settings =  {
        arrows: true,
        arrowsBlock: true,
        slidesToShow: 6
      };
    return (
      <div>
        <span>CustomSlider</span>
        <Slider { ...settings }>
          <div className='div-card-marca'>
            <h3>1</h3>
          </div>
          <div  className='div-card-marca'>
            <h3>2</h3>
          </div>
          <div  className='div-card-marca'>
            <h3>3</h3>
          </div>
          <div className='div-card-marca'>
            <h3>4</h3>
          </div>
          <div className='div-card-marca'>
            <h3>5</h3>
          </div>
          <div className='div-card-marca'>
            <h3>6</h3>
          </div>
          <div className='div-card-marca'>
            <h3>7</h3>
          </div>
          <div className='div-card-marca'>
            <h3>8</h3>
          </div>
          <div className='div-card-marca'>
            <h3>9</h3>
          </div>
          <div className='div-card-marca'>
            <h3>10</h3>
          </div>
          <div className='div-card-marca'>
            <h3>11</h3>
          </div>
          <div className='div-card-marca'>
            <h3>12</h3>
          </div>
          <div className='div-card-marca'>
            <h3>13</h3>
          </div>
        </Slider>
      </div>
    );
  }
}

export default CustomSlider;