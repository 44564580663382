import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/AccountTree';
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Typography , Fab, Badge, InputAdornment} from '@material-ui/core';
import IconeNFe from '@material-ui/icons/Assignment'
import Swal from 'sweetalert2';
import IconeCarrinhoCheio from '@material-ui/icons/ShoppingCart'
import AddCart from '@material-ui/icons/ArrowDownward'
import RevemoIcon from '@material-ui/icons/Close'
import {Tabs, Tab} from 'react-bootstrap'
import IconeLocalizar from '@material-ui/icons/Search'
import IconeLike from '@material-ui/icons/ThumbUpAlt'
import IconeNotLike from '@material-ui/icons/ThumbDownAlt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Delete from '@material-ui/icons/DeleteForever'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconeFinanceiro from '@material-ui/icons/MonetizationOn'
import IconeAprovar from '@material-ui/icons/Done'
import IconeCancelar from '@material-ui/icons/RemoveShoppingCart'
import IconeEstoque from '@material-ui/icons/EventAvailable'
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Brush'
import GeradorLog from '../../providers/GeradorLog';


export default function ModalNovoUsuario(props) {
  const [open, setOpen] = React.useState(false);


  const [login, setLogin] = React.useState('');
  const [nomeCompleto, setNomeCompleto] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = React.useState('');

 

  const handleClose = () => {
    setOpen(false);
    GeradorLog(props.dadosUsuario, '[USUARIOS][MODAL NOVO USUARIO]->Fechado');
  };
  React.useEffect(()=>{
    if(!open){
      setLogin('')
      setNomeCompleto('')
     
      setEmail('')
      setSenha('')
    }
  },[open])


//getNotasFiscaisContrato
  return (
    <div >

      <Fab onClick={()=>{
        setOpen(true)
        GeradorLog(props.dadosUsuario, '[USUARIOS][MODAL NOVO USUARIO]->Aberto');
        }} size='large' color='primary' ><AddIcon /></Fab>
        
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'sm'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'
      >
        <DialogTitle id="alert-dialog-title">Cadastro de Usuários</DialogTitle>
        <DialogContent dividers>   
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Login de Acesso'
                        fullWidth
                        variant='outlined'
                        value={login}
                        onChange={(e)=>setLogin(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Nome Completo'
                        fullWidth
                        variant='outlined'
                        value={nomeCompleto}
                        onChange={(e)=>setNomeCompleto(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Email'
                        fullWidth
                        variant='outlined'
                        value={email}
                        type ='email'
                        inputProps={{
                          type : 'email'
                        }}
                        onChange={(e)=>setEmail(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Senha'
                        fullWidth
                        variant='outlined'
                        type='password'
                        value={senha}
                        onChange={(e)=>setSenha(e.target.value)}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>     
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs ={12}>
                    <TextField
                        label='Confirmação'
                        fullWidth
                        variant='outlined'
                        type='password'
                        value={confirmacaoSenha}
                        onChange={(e)=>setConfirmacaoSenha(e.target.value)}
                        onBlur={(e)=>{
                          if(e.target.value !== senha){
                            Swal.fire({
                              title : 'Corporate Web', 
                              text : 'A senha e a confirmação não são iguais! verifique!', 
                              icon : 'warning', 
                              showConfirmButton : false, 
                              showCancelButton : false,
                              timer : 500, 
                            })
                          }
                        }}                      
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>        
        </DialogContent>
        <DialogActions>
            <Grid container spacing ={1}>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                    <Button  color='secondary' fullWidth className='button-error' onClick={()=>{
                        setOpen(false)
                    }}>Cancelar</Button>
                </Grid>
                <Grid item xs={2}>
                <Button className='button-default' fullWidth color='primary' onClick={()=>{
                  GeradorLog(props.dadosUsuario, '[USUARIOS][MODAL NOVO USUARIO]->onClick->insertUser');
                    Swal.fire({
                      title : 'Corporate Web', 
                      title  : 'Deseja cadastrar este Usuário?', 
                      icon : 'question', 
                      showConfirmButton : true, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : true, 
                      cancelButtonText : 'Não'
                    }).then(resposta=>{
                      if(resposta.isConfirmed){
                        if(nomeCompleto == '' || senha == '' || email == '' ||  login == ''){
                          Swal.fire({
                            title : 'Corporate Web', 
                            title  : 'Preecha todos os dados antes de continuar', 
                            icon : 'warning', 
                            showConfirmButton : false, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 1000,
                            backdrop :"rgba(255, 0, 0, 0.2)"
                          })
                          return;
                        }


                        if( senha != confirmacaoSenha){
                          Swal.fire({
                            title : 'Corporate Web', 
                            title  : 'A senha e a confirmação devem ser iguais', 
                            icon : 'warning', 
                            showConfirmButton : false, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 1000,
                            backdrop :"rgba(255, 0, 0, 0.2)"
                          })
                          return;
                        }


                        if(senha.length < 6){
                          Swal.fire({
                            title : 'Corporate Web', 
                            title  : 'A senha deve possuir no mínimo 6 caracteres!', 
                            icon : 'warning', 
                            showConfirmButton : false, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 1000,
                            backdrop :"rgba(255, 0, 0, 0.2)"
                          })
                          return;
                        }
                        props.Conexao.post('clientesController/insertSubUsuarioSite'+'?empresa='+props.empresa,{
                          codigoCliente : props.cliente, 
                          userName : login, 
                          senha: senha, 
                          nomeCompleto : nomeCompleto, 
                          email : email
                        }).then(response=>{

                          if( response.status == 200  && response.data.status == 'OK'){
                            props.getData();
                            handleClose();
                          }else{
                            Swal.fire({
                              title : 'Corporate Web', 
                              title  : 'não foi possível incluir/alterar', 
                              icon : 'warning', 
                              showConfirmButton : false, 
                              confirmButtonText : 'Sim', 
                              showCancelButton : false, 
                              cancelButtonText : 'Não', 
                              timer : 1000,
                              backdrop :"rgba(255, 0, 0, 0.2)"
                            })
                          }
                          
                        }).catch(error=>{
                          console.log(error)
                          Swal.fire({
                            title : 'Corporate Web', 
                            title  : 'não foi possível incluir/alterar', 
                            icon : 'error', 
                            showConfirmButton : false, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : false, 
                            cancelButtonText : 'Não', 
                            timer : 1000,
                            backdrop :"rgba(255, 0, 0, 0.2)"
                          })
                          handleClose();
                        })
                      }
                    })
                }}>Gravar</Button>
                </Grid>
            </Grid>
            
            
        </DialogActions>
      </Dialog>
    </div>
  );
}
