import React , {useEffect, useState, useRef} from 'react';
import Principal from '../navbar/Navbar'
import {withRouter} from 'react-router-dom'
import { Typography, Divider, TextField, Grid, InputAdornment, Button, Table, TableHead, TableBody, TableRow, TableCell,Tooltip} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import Conexao from '../../providers/Api/Api'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import BuildIcon from '@material-ui/icons/Build'
import CartIcon from '@material-ui/icons/AddShoppingCart'

import Swal from 'sweetalert2'
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import LaptopChromebookTwoToneIcon from '@material-ui/icons/LaptopChromebookTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ThreeSixtyTwoToneIcon from '@material-ui/icons/ThreeSixtyTwoTone';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ModalNovoUsuario from './ModalNovoUsuario';
import DeleteIcon from '@material-ui/icons/DeleteForever'
import ModalImpressorasVinculadas from './ModalImpressorasVinculadas';
import ModalVideo from '../../components/base/ModalVideo';
import GeradorLog from '../../providers/GeradorLog';


const Usuarios = function(props){
    const [dadosUsuario , setDadosUsuario] = React.useState(props.location.state.dadosEmpresa);
    const {printerconnect, codigoEmpresa }= props.location.state;
    
    const [page, setPage] = React.useState(0);

    const [loading, setLoading]= React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [ registros, setRegistros] = useState(0)

    const [usuarios, setUsuarios] = React.useState([]);

    const [hideNovoButton, setHideNovoButton] = React.useState(false);
  

    const refTipoFiltro = useRef();
    const refFiltro = useRef();
    const refStatusPedido = useRef();

    React.useEffect(()=>{
        getData();
    },[rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
       // getData();
    },[])
    
    useEffect(()=>{
        //GeradorLog(dadosUsuario);
    },[])

    //gerarLogSite($codigoCliente, $dominio, $loginAcesso, $nomeTela)



    const getData = () =>{
        setLoading(true)
        Conexao.post('clientesController/getSubusuarios?codigoEmpresa='+codigoEmpresa, {
            codigoCliente : dadosUsuario.TB01066_CODCLI, 
            tipoFiltro :  refTipoFiltro.current ? refTipoFiltro.current.value : '', 
            filtro :  refFiltro.current ? refFiltro.current.value : '', 
            status : refStatusPedido.current ? refStatusPedido.current.value : 'G',
            registros : rowsPerPage, 
            pagina : page
        }).then(response=>{
            
            if(response.data.status =='OK'){
                
                setUsuarios(response.data.payload)
                
            }else{
                setUsuarios([])
               
            }
            setLoading(false)
        }).catch(error=>{
            setUsuarios([])
          
            setLoading(false)
        })
    }

    const renderRotulo = (cor)=>{
        switch(cor){
            case 'CIANO': return '#4FC3F7';
            case 'CIAN': return '#4FC3F7';
            case 'MAGENTA': return '#F50057';
            case 'AMARELO': return '#FDD835';
            case 'PRETO': return '#000';
        }
    }

    const renderStatusIcon = (status,descricao)=>{

        switch(status){
            case 1:return <span className='labelDefault'>ANDAMENTO</span>
            case 2:return <span className='labelSucess'>{descricao}</span>
            case 3:return <span className='labelSucess'>{descricao}</span>
            case 23:return <span className='labelPreSucess'>{descricao}</span>
            case 7:return <span className='labelInfo'>{descricao}</span>
            case 22:return <span className='labelInfo'>{descricao}</span>
            case 24:return <span className='labelInfo'>{descricao}</span>
            case 25:return <span className='laberlWarning'>{descricao}</span>
            case 26:return <span className='laberlWarning'>{descricao}</span>
            default:return <span className='labelDefault'>{descricao}</span>; 
        }
       
    }


    const renderIcone = (valor,) =>{
        switch(valor){
            case 'PCONNECT':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>PRINTERCONNECT</div>
            case 'ALERTA':
                return <div  style={{fontSize:"9px"}}><NotificationsActiveTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>ALERTA</div>
            case 'MANUAL':
                return <div  style={{fontSize:"9px"}}><CallTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>MANUAL</div>
            case 'SITE':
                return <div  style={{fontSize:"9px"}}><LaptopChromebookTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>SITE</div>
            case 'INSTALAÇÃO':
                return <div  style={{fontSize:"9px"}}><DescriptionTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>INSTALAÇÃO</div>
            case 'REPOSICAO':
            return <div  style={{fontSize:"9px"}}><ThreeSixtyTwoToneIcon color="primary" style={{fontSize:"22px"}}/><br/>REPOSIÇÃO</div>
            
            
        }
    }

    return <Principal {...props} botaoAtivo = 'usuarios' loading={loading}>
        <div className='container-children'> 
        <ModalVideo 
                    urlVideo = ''>
                        <iframe 
                            width="900" 
                            height="500" 
                            src="https://www.youtube.com/embed/XOWmxsFIUtk" 
                            title="Criação de usuários." 
                            frameborder="0" 
                            allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >
                                
                        </iframe>
                </ModalVideo>        
            <div className='flex-spacing'>
                <div >
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={{pathname : "/home/"+props.match.params.empresa, state:{
                        dadosEmpresa : dadosUsuario
                    }}} >
                        Home
                    </Link>
                    <Typography color="textPrimary">Usuários</Typography>
                </Breadcrumbs>
                </div>
                
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}   
                    labelRowsPerPage="Linhas por página:"                
                    />
                
            </div>
           
            <Divider/>
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {3}>
                    <TextField 
                        label = 'Pesquisar por?'
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        inputRef={refTipoFiltro}>
                            <option value = 'nomeCompleto'>Nome Completo</option>
                            <option value = 'login'>Login</option>
                            
                    </TextField>
                </Grid>
                <Grid item xs = {7}>
                    <TextField 
                        label = 'Filtro'
                        fullWidth                  
                        variant='outlined'
                        inputRef={refFiltro}
                        placeholder='Digite um filtro para efetuar a pesquisa'
                        InputLabelProps={{shrink : true}}
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                getData()
                            }
                        }}
                        />
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth  className='button-default' onClick={()=>{
                            getData();
                    }}><SearchIcon/>Buscar</Button>
                </Grid>
                <Grid item xs = {1}>
                    <Button fullWidth className='button-error' onClick={()=>{
                        if(refTipoFiltro.current){
                            refTipoFiltro.current.value = 'descricao'
                        }
                        if(refFiltro.current){
                            refFiltro.current.value = ''
                        }
                        getData()
                    }}><ClearIcon/>Limpar</Button>
                </Grid>
            </Grid>
                
                <Table className='table table-bordered table-striped table-hover table-sm table-small' style ={{marginTop:'15px'}}>
                    <TableHead>
                        <TableRow>

                           
                            <TableCell align='left' style={{width: '20%'}}>Nome Completo</TableCell>
                            <TableCell align='left' style={{width: '20%'}}>Login Acesso</TableCell>

                            {/*<TableCell align='center' style={{width: '10%'}}>Contrato</TableCell> 
                            <TableCell align='center' style={{width: '10%'}}>Site</TableCell> 
                <TableCell align='center' style={{width: '10%'}}>Setor</TableCell> */}
                            <TableCell align='center' style={{width: '10%'}}>Equipamentos</TableCell>

                            <TableCell align='center' style={{width: '10%'}}>Excluir</TableCell>  
                        </TableRow>                       
                    </TableHead>
                    <TableBody>
                        {
                            usuarios && usuarios.map(function(site, i){
                                return <TableRow key ={i}>

                                <TableCell align='left' >
                                    <Typography variant='caption'>{site.CMQ01066_NOME}</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='caption' style ={{fontSize:'10px'}} color='primary'>{site.CMQ01066_USERNAME}</Typography>
                                </TableCell>
                         
                                {/*<TableCell align='center'>
                                    {site.TB02177_CODIGO ? 
                                    <Typography variant='subtitle1' style={{fontSize:'10px'}}>{site.TB02111_CODIGO}</Typography>
                                    :
                                    <Button variant='outlined' color='secondary'>Todos</Button>
                                    }
                                </TableCell>   
                                <TableCell align='center' >
                                    {site.TB02176_CODIGO ? 
                                    <Typography variant='subtitle1' style={{fontSize:'10px'}}>{site.TB02176_CODIGO}</Typography>
                                    :
                                    <Button variant='outlined' color='secondary'>Todos</Button>
                                    }
                                    
                                </TableCell> 
                                <TableCell align='center' >
                                    {site.TB02177_CODIGO ? 
                                    <Typography variant='subtitle1' style={{fontSize:'10px'}}>{site.TB02177_CODIGO}</Typography>
                                    :
                                    <Button variant='outlined' color='secondary'>Todos</Button>
                                    }
                                
                                </TableCell>*/}
                                <TableCell align='center' >
                                    <ModalImpressorasVinculadas dadosUsuario={dadosUsuario} codigoCliente = {dadosUsuario.TB01066_CODCLI} userName={site.CMQ01066_USERNAME}/>
                                </TableCell> 
                                                          
                                <TableCell align='center' >
                                    <Button variant='outlined' color='secondary' onClick={()=>{
                                        Swal.fire({
                                            title : 'Corporate Web', 
                                            text : 'Deseja realmente remover este usuário?', 
                                            icon : 'question', 
                                            showCancelButton :true, 
                                            cancelButtonText : 'Não', 
                                            showConfirmButton : true, 
                                            confirmButtonText : 'Sim'
                                        }).then(resposta=>{
                                            if(resposta.isConfirmed){
                                                Conexao.post('clientesController/deleteSubUsuario'+'?empresa='+props.empresa,{
                                                    codigoCliente : site.TB02111_CODIGO, 
                                                    userName : site.CMQ01066_USERNAME, 
                                                  }).then(response=>{
                                                    if(response.status == 200 && response.data.status =='OK'){
                                                        getData();
                                                    }else{
                                                        Swal.fire({
                                                            title : 'Departamentos', 
                                                            title  : 'não foi possível excluir', 
                                                            icon : 'warning', 
                                                            showConfirmButton : false, 
                                                            confirmButtonText : 'Sim', 
                                                            showCancelButton : false, 
                                                            cancelButtonText : 'Não', 
                                                            timer : 1000,
                                                            backdrop :"rgba(255, 0, 0, 0.2)"
                                                          })
                                                    }
                                                  }).catch(error=>{
                                                    Swal.fire({
                                                        title : 'Departamentos', 
                                                        title  : 'não foi possível excluir', 
                                                        icon : 'error', 
                                                        showConfirmButton : false, 
                                                        confirmButtonText : 'Sim', 
                                                        showCancelButton : false, 
                                                        cancelButtonText : 'Não', 
                                                        timer : 1000,
                                                        backdrop :"rgba(255, 0, 0, 0.2)"
                                                      })
                                                  })
                                            }
                                        })
                                    }}><DeleteIcon /></Button>
                                </TableCell>   
                            </TableRow>
                            })
                        }
                    </TableBody>
                </Table>   
                <div className='botaoAcoes'>
                    <ModalNovoUsuario getData={()=>{getData()}} showModal={false} Conexao={Conexao} cliente = {dadosUsuario.TB01066_CODCLI} dadosUsuario={dadosUsuario}/>
                </div>
        </div>
       
    </Principal>
}

export default withRouter(Usuarios)